import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
        loggedIn: false,
        data: null
    }
  },
  getters: {
    user(state){
        return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
        state.user.loggedIn = value;
    },
    SET_USER_AUTH(state, data) {
        state.user.auth[data.key] = data.value;
    },
    SET_USER(state, data) {
        state.user.data = data;
    },
    SET_USER_DATA(state, data) {
        state.user.data[data.key] = data.value;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
        commit("SET_LOGGED_IN", user !== null);
        console.log("[fetchUser] user:",user);
        if (user) {
            commit("SET_USER", {
                id: user.id,
                displayName: user.displayName,
                name: user.name || null,
                email: user.email,
                defaultSurveyOptions: user.defaultSurveyOptions,
                onboardingSteps: user.onboardingSteps,
                billing: user.billing || null,
                integrations: user.integrations,
                entryCount: user.entryCount || 0,
                config: user.config
            })
        } else {
            commit("SET_USER", null);
        }
    },
  },
  modules: {
  }
})
