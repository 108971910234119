<template>
    <div class="content-wrapper">
        <div class="header" style="margin-bottom: 20px;">
            <div class="primary-heading margin-bottom-20">Support</div>
            <div class="text-sm">Need help setting up your attribution survey? Search our help docs below or reach out to us at <a href="mailto:support@getattribute.com">support@getattribute.com</a>.</div>
        </div>
        <div class="body">
            <!-- Search -->
            <div class="search-wrapper margin-bottom-40 width-600">
                <div class="input-group margin-bottom-0">
                    <label>Search our support documentation</label>
                    <input type="search" class="search" v-model="searchPattern" placeholder="Try using key words, e.g. Klaviyo" />
                </div>
            </div>
            <div id="articles" class="flex flex-row-wrap">
                <div 
                  @click="$router.push({ name: 'SupportArticle', params: { handle: article.handle }})" 
                  class="article-preview card xxs margin-right-20 margin-bottom-20" 
                  v-for="article in filteredArticles" 
                  v-bind:key="article.handle" 
                  v-bind:class="`article-${article.type}`">
                    <div class="article-title text-weight-bold text-sm">{{ article.title }}</div>
                    <div class="article-excerpt margin-top-5 text-sm">{{ article.excerpt }}</div>
                    <div class="article-type pill">{{ article.type }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Fuse from "fuse.js"

var articles = require("@/assets/data-supportArticles.json");

// console.log("articles:",articles)
export default {
    name: "Support",
    data: function() {
        return {
            searchPattern: "",
            articles: articles,
            filteredArticles: []
        }
    },
    created: function() {
        this.filteredArticles = this.articles;
    },
    methods: {
        searchArticles: function(pattern) {
          const options = {
            keys: [
              "title",
              "excerpt"
            ]
          };
          const fuse = new Fuse(this.articles, options);

          return fuse.search(pattern);
        },
    },
    watch: {
        searchPattern: {
            handler: function(value) {
                value == "" ? this.filteredArticles = this.articles : this.filteredArticles = this.searchArticles(value).map(x => x.item);
            }
        }
    }
};
</script>