<template>
  <div class="content-wrapper">

    <div class="header width-900">
        <div class="primary-heading margin-bottom-20">Manage your request email</div>
        <div class="text-sm">Send a request email to your customers to ask how they found you if they have not filled out your survey.</div>
    </div>
    <div class="body" :class="{ hidden: !isLoaded }">

        <div v-if="sender && sender.emailSettings" class="two-columns">
          <div id="configuration" class="column padding-right-40">

              <!-- Is active -->
              <div class="card-row flex flex-v-center margin-bottom-15">
                <div class="flex-1 flex flex-v-center">
                    <h3 class="text-weight-md margin-bottom-0 margin-right-10">Configuration</h3>
                    <div class="pill" :class="[{ 'active': email.active }, { 'inactive': !email.active }]">
                      <span v-if="email.active">Active</span>
                      <span v-else>Inactive</span>
                    </div>
                </div>
                <div class="input-group switch flex flex-v-center margin-0">
                  <label class="switch" v-bind:class="{ inactive: !activePlan }">
                    <input type="checkbox" v-model="email.active" v-if="activePlan">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <!-- Warning: Choose a plan -->
              <div v-if="!activePlan && isLoaded" class="info-box warning margin-bottom-20 clickable">
                <span class="icon icon-info-icon"></span>
                <div>
                  <div class="text-md text-weight-med heading">
                      <span>Your email is currently inactive.</span>
                  </div>
                  <div class="body">Please <router-link :to="{ name: 'Plan' }">select a plan</router-link> to activate your email.</div>
                </div>
              </div>

              <!-- Warning: Deactivate survey -->
              <div v-else-if="!email.active && isLoaded" class="info-box warning margin-bottom-20">
                <span class="icon icon-info-icon"></span>
                <div>
                  <div class="text-md text-weight-med heading">
                      <span>Your email </span><span v-if="!isSaved">will be deactivated!</span><span v-else>is currently inactive.</span>
                  </div>
                  <div class="body">The email will <b>not be sent</b> out to customers after they complete the survey.</div>
                </div>
              </div>

              <!-- Start Config -->
              <div class="margin-bottom-20">
                  <!-- Is active -->
                  <!-- Schedule -->
                  <!-- <div class="text-sm text-weight-bold">Schedule</div>
                  <div class="card-row">
                      <div class="input-group inline">
                        <span class="margin-right-5">Send email to customer</span> 
                        <input type="number" v-model="email.triggerDays" style="width: 60px; text-align: center;"/> 
                        <span class="margin-left-5">days after completion</span>
                      </div>
                  </div> -->

                  <!-- Discount Code -->
                  <!-- <div>
                      <div class="input-group checkbox">
                        <input type="checkbox" v-model="email.hasDiscount"/>
                        <div>Include a discount code?</div>
                      </div>
                  </div>
                  <div class="card-row" v-if="email.hasDiscount">
                    <div class="input-group">
                      <label>Discount code</label>
                      <input type="text" v-model="email.discount.code" />
                    </div>
                    <div class="flex">
                      <div class="input-group" style="margin-bottom: 0;">
                        <label>Discount value</label>
                        <input type="text" v-model="email.discount.value" />
                      </div>
                      <div class="input-group flex-1 margin-left-10 select full-width" style="margin-bottom: 0;">
                        <label>Type</label>
                        <select v-model="email.discount.type" class="full-width">
                          <option v-for="type in discountTypes" :key="type">{{ type }}</option>
                        </select>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="">

                  <!-- Email Content -->
                  <div class="card-row flex">
                    <!-- Email: Schedule -->
                    <div class="input-group flex-1">
                      <label>Schedule</label>
                      <div class="flex flex-v-center">
                        <span>Send the request</span>
                        <input type="number" style="width: 60px; margin: 0 15px; text-align: center; padding-right: 0;" v-model="email.trigger.value"/>
                        <select v-model="email.trigger.units" style="margin: 0 15px 0 0;">
                          <option v-for="option in email.trigger.unitOptions" :key="option">{{ option }}</option>
                        </select>
                        <span>after purchase.</span>
                      </div>

                    </div>
                  </div>

                  <div class="card-row">

                    <!-- Email: Subject -->
                    <div class="input-group">
                      <label>Subject</label>
                      <input type="text" v-model="email.content.subject">
                    </div>
                    <!-- Email: Preview Text -->
                    <div class="input-group">
                      <label>Preview Text</label>
                      <input type="text" v-model="email.content.previewText">
                    </div>
                    <!-- Email: Background -->
                    <div class="flex">
                      <div class="input-group flex-1">
                        <label>Max Width</label>
                        <input type="number" min="480" max="1200" v-model="email.content.maxWidth"/>
                      </div>
                      <div class="input-group color text-align-right">
                        <label>Background</label>
                        <input type="color" v-model="email.content.background">
                      </div>
                    </div>
                    <!-- Email: Body -->
                    <div class="input-group editor" v-if="loadedUserEmails && sender.emailSettings" :style="`color: ${sender.emailSettings.textColor}`">
                      <label>Body</label>
                      <editor :modelValue="{ content: email.content.body, background: email.content.background, isSaved: true }" @modelValue="updateEmailContent" :modelClass="'attributeEmail'"></editor>
                    </div>
                  </div>

                  
                  <div class="divider"></div>

                  <div class="card-row">
                      <div class="input-group code">
                          <label>Custom CSS</label>
                          <prism-editor v-model="email.customCss" class="min-height-200" :highlight="highlighter" line-numbers language="css"></prism-editor>
                      </div>
                  </div>
              </div>
          </div>

          <div id="preview" class="column padding-right-40 sticky" :class="{ unsaved: !isSaved }">
              <h3 class="text-weight-md margin-bottom-15 margin-right-10">Preview</h3>
              <div class="card background-alternate">

                  <!-- Preview Header -->
                  <div class="head margin-bottom-20">
                    <div><b>From:</b> <span>{{ sender.name }}</span> <span>&#x3C;{{ sender.from }}&#x3E;</span></div>
                    <div><b>Subject:</b> <span>{{ email.content.subject }}</span> </div>
                  </div>

                  <!-- Preview Body -->
                  <table class="attributeEmail preview" v-bind:style="`max-width: ${sender.emailSettings.maxWidth}px; background: ${sender.emailSettings.background};`">
                    <thead>
                      <tr v-if="sender.emailSettings.logo.visible">
                        <td class="logo-header" :style="`text-align: ${sender.emailSettings.logo.position}; background: ${sender.emailSettings.header.background};`">
                          <img :src="sender.emailSettings.logo.url" class="logo" :style="`width: ${sender.emailSettings.logo.width}px`"/>
                        </td>
                      </tr>
                      <tr v-if="sender.emailSettings.header.content !== '<p></p>'"><th v-html="sender.emailSettings.header.content"></th></tr>
                    </thead>
                    <tbody>
                      <tr><td>
                        <table border="0" cellspacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td v-if="sender.emailSettings" v-html="email.content.body" v-bind:style="`color: ${sender.emailSettings.textColor}`">
                              </td>
                            </tr>
                            <tr>
                              <td v-html="emailSurveyTemplate"></td>
                            </tr>
                          </tbody>
                        </table>
                      </td></tr>
                    </tbody>
                    <tfoot>
                      <tr><td v-bind:style="`background: ${sender.emailSettings.footer.background}`" v-html="sender.emailSettings.footer.content"></td></tr>
                    </tfoot>
                  </table>

              </div>
              <div class="actions">
                <button class="button primary margin-top-20" v-on:click="showModal('sendTest')" v-bind:class="{ disabled: !sender.verified }">
                  <span v-if="!sendingTest">Send test email</span>
                  <span v-else>Sending...</span>
                </button>
                <div v-if="!sender.verified" class="margin-top-20">Please verify your Sender Email before testing or activating.</div>
              </div>
              <!-- <div class="text-sm text-weight-bold margin-bottom-20 margin-top-40">Integrations</div> -->
              <!-- <div class="text-sm">Review your <router-link :to="{ name: 'Integrations'}" class="text-weight-bold">integrations here</router-link>.</div> -->
          </div>
        </div>

        <div v-else class="info-box warning">
          <div><span>Please create a sender in</span> <span class="text-weight-med clickable" @click="$router.push({ 'name': 'Settings' })">Email Settings</span><span> to set up your request email.</span>
          </div>
        </div>

    </div>

    <!-- MODAL: Verify Sender Modal -->
    <Modal v-if="modals.verifySender.visible" @close="closeModal">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Verify your sender email</div>
        </template>

        <template v-slot:body>
          <div class="wrapper width-300">
            <div>Verify the email that you'll be sending your Attribute Survey follow-up emails from.</div>
            <div class="info-general margin-top-15" v-html="email.content.from"></div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
               <button v-on:click="createSender()" v-bind:class="[{ 'outline inactive': email.verifiedSender === true }, { 'button primary': !email.verifiedSender }]">
                 <span v-if="!email.verifiedSender">Verify</span>
                 <span v-else-if="isVerifying">Verifying...</span>
                 <span v-else-if="email.verifiedSender === 'Pending'">Verification Sent!</span>
                 <span v-else>Verified</span>
               </button>
               <div class="message margin-top-15" v-html="verificationMessage"></div>
            </div>
        </template>
    </Modal>

    <!-- MODAL: Send Test Modal -->
    <Modal v-if="modals.sendTest.visible" @close="closeModal">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Send a test email</div>
        </template>

        <template v-slot:body>
          <div class="wrapper width-400">
            <div class="margin-bottom-15">Which emails would you like to send the test to?</div>
            <div class="input-group inline-list margin-none">
              <div class="email-input">
                <div class="pills flex flex-row-wrap">
                  <div v-for="(email, index) in testEmails" v-bind:key="email" class="pill removable margin-bottom-10 margin-right-10 padding-10 flex flex-v-center">
                      <span class="flex-1">{{ email }}</span>
                      <div class="icon icon-x-icon margin-left-10" @click='removeTestEmail(index)'></div>
                  </div>
                </div>
                <input
                  id="testEmails"
                  type="text"
                  class="full-width"
                  name="testEmails"
                  value
                  required
                  autofocus
                  placeholder="Add email addresses + Enter"
                  @keydown.enter="addTestEmail"
                  @keydown.188="addTestEmail"
                  @keydown.delete="removeLastTestEmail"
                />
              </div>
            </div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
               <button class="button primary margin-none" v-on:click="sendEmail()" v-bind:class="{ 'disabled outline': !sender.verified || testEmails.length < 1 }">
                 <span v-if="!sendingTest">Send test</span>
                 <span v-else>Sending...</span>
               </button>
            </div>
        </template>
    </Modal>

    <!-- Save Button -->
    <div class="saveButton actions"  v-bind:class="{ unsaved: !isSaved }">
      <button class="button primary full-width" v-on:click="saveUserData()" v-if="!isSaved">Save</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .sticky { top: 100px; height: 125vh; }
  .sticky.unsaved { top: 150px; }
  .email-verification {
    position: relative;

    .button {
      padding: 15px 40px;
      transition: none;
      margin-bottom: 20px;
    }

    .verified { 
      position: absolute;
      right: 15px;
      bottom: 10px;
      background: none;
      border: none;
      padding: 5px 0;

      .verification-status {
        color: #6F6666;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }
  .card {
    overflow-x: scroll;
  }
</style>

<!-- @TODO  -->
<!-- @TODO  -->

<script>
import { PrismEditor } from 'vue-prism-editor';
import Prism from "prismjs";
import Editor from '@/components/editor/TextEditor.vue';
import { mapGetters } from "vuex";
import firebase from "@firebase/app";
import { functions, db } from "@/firebase";
import Modal from '@/components/Modal.vue';
import NProgress from "nprogress";

import "vue-prism-editor/dist/prismeditor.min.css"; 
import "prismjs/themes/prism.css";
import externalCss from "!!raw-loader!@/styles/external-email.css"; // load the css for the email

const sendEmail = functions.httpsCallable('sendEmail');

export default {
  name: "Request",
  components: {
    PrismEditor,
    Editor,
    Modal
  },
  data: function() {
    return {
      modals: {
        verifySender: { visible: false },
        sendTest: { visible: false }
      },
      isSaved: true,
      isVerifying: false,
      isLoaded: false,
      loadedUserEmails: false,
      sendingTest: false,
      emailSurveyTemplate: null,
      verificationMessages: {
        success: "Success: Please check your email.",
        error: "Error: Email could not be verified.",
        duplicate: "Success: Email has already been verified."
      },
      verificationMessage: "",
      discountTypes: [
        "Percentage",
        "Fixed Amount"
      ],
      testEmails: [],
      sender: {},
      email: {
        id: "request-1",
        active: false,
        trigger: {
          unitOptions: [ "days", "hours", "minutes" ],
          units: "days",
          value: 7
        },
        hasDiscount: true,
        verifiedSender: null,
        verifiedSenderId: null,
        // discount: {
        //   code: "WELCOME10",
        //   value: 10,
        //   type: "Percentage"
        // },
        content: {
          maxWidth: 600,
          background: "#FFF",
          logoUrl: "",
          senderName: "",
          to: "",
          from: "",
          subject: "Thanks for your purchase! We'd love to hear how you found out about us.",
          previewText: "Thanks for taking the time let us know how you found out about {{ shop_name }}.",
          body: `<div>
            <h1 style="text-align: center">Thanks {{ first_name}}!</h1><p style="text-align: center">Thank you for your purchase! How did you find out about {{ shop_name }}?</p>
            </div>
          `
        },
        customCss: ".attributeEmail { max-width: 600px; }"
      }
    }
  },
  computed: {
      ...mapGetters({
        // map `this.user` to `this.$store.getters.user`
        user: "user"
      }),
      emailBackground: function() {
        return this.email.content.background? this.email.content.background : this.sender.emailSettings.body.background;
      }
  },
  created: async function() {
    
    NProgress.start();
    // Get user data and init customCss
    await this.getUserData();
    await this.initCustomCss();
    await this.getEmailSurveyTemplate();

    // Check if we have an active plan
    if (!this.activePlan) {
      this.email.active = false; 
      await this.saveUserData(); 
    }

    console.log("[created] sender background:",this.sender.emailSettings);

    this.isSaved = true;
    this.isLoaded = true;
    NProgress.done();

  },
  methods: {
    updateEmailContent: function(value) {
      this.email.content.body = value.content;
      this.email.isSaved = value.isSaved;
    },
    highlighter(code) {
      // js highlight example
      return Prism.highlight(code, Prism.languages.css, "CSS");
    },
    getEmailSurveyTemplate: async function() {
      
      if (this.user.data) {
        
        var user = await firebase.getUser("email", this.user.data.email);
        var options = "";
        var surveyOptions = user[0].surveyOptions;
        // var survey = user[0].survey;

        for (var i in surveyOptions) {
            var option = surveyOptions[i];
            if (i > 1 && i % 2 == 0) { options += "<tr></tr>" }
            options += `<td><a href="https://us-central1-attribute-59c4a.cloudfunctions.net/postSurvey?userId=${this.user.data.id}&orderId={{ order_id }}&option=${option.name}" class="survey-option" data-write-in="${ option.isWriteIn ? option.isWriteIn : false }" data-placeholder="${ option.writeInPrompt ? option.writeInPrompt : 'Write your answer here...' }">${ option.name }</a></td>\n`;
        }

        // Send the user to a page which auto-enters the value into the database and updates when they update it
        this.emailSurveyTemplate = `
            <table id="survey" class="survey-options">
                <tbody>
                  <tr>${options}</tr>
                </tbody>
            </table>
        `

        console.log("emailSurveyTemplate:",this.emailSurveyTemplate)

      } else {
        this.emailSurveyTemplate = null;
      }
    },
    getUserData: async function() {

        // Get user data from Firebase, otherwise, return default
        var user = await firebase.getUser("email", this.user.data.email);
        var emails = user[0].emails;

        if (emails && emails.length > 0) {
          console.log("[getUserData] emails:",emails);
          emails.forEach(email => {
            if (email.id === "request-1") {
              console.log("[getUserData] request email:",email);
              this.email = emails ? emails[0] : this.email;
            }
          })
        }

        if (user[0].sender) { this.sender = user[0].sender }

        
        this.loadedUserEmails = true;

    },
    saveUserData: async function() {

        // Compile data to be saved
        var vm = this;
        var user = db.collection("users").doc(vm.user.data.id);
        var formattedCss = this.formatCss();
        var logoContent = this.sender.emailSettings.logo.visible ? `<tr><th style="text-align: ${this.sender.emailSettings.logo.position}"><img style="width: ${this.sender.emailSettings.logo.width}px; max-width: 100%; display: inline-block;" src="${this.sender.emailSettings.logo.url}"/></th></tr>` : "";
        // var discountCode = vm.email.hasDiscount ? vm.email.discount.code : "";

        // Set up the email subject & previewText
        vm.email.content.subject = `${this.mreplace([
                  // [/{{ discount_code }}/gm, discountCode],
                  [/{{ shop_name }}/gm, vm.user.data.displayName],
                  // [/{{ first_name }}/gm, "Test User"],
                ], vm.email.content.subject)}`;

        vm.email.content.previewText = `${this.mreplace([
                  // [/{{ discount_code }}/gm, discountCode],
                  [/{{ shop_name }}/gm, vm.user.data.displayName],
                  // [/{{ first_name }}/gm, "Test User"],
                ], vm.email.content.previewText)}`;

        vm.email.content.survey = this.emailSurveyTemplate;

        // Set up the email body
        vm.email.content.bodyHtml = `
          <html>
            <head>
              <style>${formattedCss}</style>
            </head>
            <body>
              <table style="margin: 0 auto; max-width: ${this.email.content.maxWidth}px; background: ${this.email.content.background}; padding-bottom: 20px;"><tbody><tr><td>
              <table class="attributeEmail">
                <thead>
                  ${logoContent}
                  <tr><th>${this.sender.emailSettings.header.content}</th></tr>
                </thead>
                <tbody><tr><td>
                <table border="0" cellspacing="0" width="100%">
                  <tbody>
                    <tr>
                      <td style="display: none">${this.email.content.previewText}</td>
                      <td style="color: ${this.sender.emailSettings.textColor}">
                      ${this.mreplace([
                        // [/{{ discount_code }}/gm, discountCode],
                        [/{{ shop_name }}/gm, vm.user.data.displayName],
                        // [/{{ first_name }}/gm, "Test User"],
                      ], vm.email.content.body)}
                      </td>
                    </tr>
                    <tr>
                      <td>${vm.email.content.survey}</td>
                    </tr>
                  </tbody>
                </table>
                </td></tr></tbody>
                <tfoot>
                  <tr><td>${this.sender.emailSettings.footer.content}</td></tr>
                </tfoot>
              </table>
              </td></tr></tbody></table>
            </body>
          </html>`;

        vm.email.isSaved = true;

        var data = { emails: [ vm.email ] };

        console.log("[EMAIL] content:",vm.email.content)

        return user.update(data)
        .then(function() {
          // console.log("Successfully updated user:",data);
          vm.isSaved = true;
        })
        .catch(function(error) {
          console.error("Error updating user:",error);
        })
    },
    formatCss: function() {

      var baseCss = externalCss;
      // Add max width
      baseCss += `\ntable.attributeEmail { \n  max-width: ${this.email.content.maxWidth}px; \n border-radius: 8px !important; \n }`;

      var formattedCss = baseCss + ".attributeEmail " + this.mreplace([ 
        [/}/gm, "} .attributeEmail"],
        [/,/gm, ", .attributeEmail"]
      ], this.email.customCss)
      // console.log("[EMAIL] formattedCss:",formattedCss);

      return formattedCss;
    },
    initCustomCss: function() {
        var formattedCss = this.formatCss();
        var styleNode = document.createElement("style");
        styleNode.id = "customCss";
        styleNode.innerHTML = formattedCss;
        
        if (this.sender.emailSettings) {
          document.getElementById("preview").append(styleNode);  
        }
        
    },
    updateCustomCss: function() {
        var formattedCss = this.formatCss();
        var styleNode = document.getElementById("customCss");
        if (styleNode) {
            styleNode.innerHTML = formattedCss;
        }
        
    },
    sendEmail: async function() {
      var vm = this;
      vm.sendingTest = true;

      for (var i in vm.testEmails) {

        var destinationEmail = vm.testEmails[i];
        // console.log("[Configure] from:",this.email.content.from);

        await sendEmail({
          to: destinationEmail,
          senderName: this.sender.name,
          from: this.sender.from,
          subject: this.email.content.subject,
          bodyHtml: this.email.content.bodyHtml,
          customerName: "Test Customer"
        }).then(function(response) {
          // console.log("Sent Email:",response);
          if (response) {
            vm.sendingTest = false;
          }
        }).catch(function(err) {
          console.log("Error:",err);
        })

      }
      
    },
    showModal: function(modal) {
      this.modals[modal].visible = true;
    },
    closeModal: function() {
      this.modals.verifySender.visible = false;
      this.modals.sendTest.visible = false;
    },
    /* Emails */
    addTestEmail: function(event) {
        event.preventDefault();
        var val = event.target.value.trim()
        if (val.length > 0) {
          this.testEmails.push(val);
          event.target.value = '';
        }
    },
    removeTestEmail: function(index) {
        this.testEmails.splice(index, 1)
    },
    removeLastTestEmail: function(event) {
        if (event.target.value.length === 0) {
            this.removeTag(this.testEmails.length - 1)
        }
    },


  },
  watch: {
    "isSaved": {
      handler(value) {
        this.$emit("isSaved",value);
      }
    },
    "email.customCss": {
      handler() {
        if (this.isLoaded) { 
          this.updateCustomCss();
          this.isSaved = false; 
        }
      },
      deep: true
    },
    "email.content": {
      handler() {
        if (this.isLoaded) { this.isSaved = false; }
      },
      deep: true
    },
    "email": {
      handler() {
        if (this.isLoaded) { this.isSaved = false; }
      },
      deep: true
    }
  }
};
</script>