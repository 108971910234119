import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "firebase/app"
import { functions } from "@/firebase"
import store from '../store'
// import NProgress from 'nprogress'

// Components
// import EmailFollowup from '@/components/emails/Followup.vue'
import EmailRequest from '@/components/emails/Request.vue'
import EmailSettings from '@/components/emails/Settings.vue'
import EmailQueue from '@/components/emails/Queue.vue'
import Loading from '@/components/Loading.vue'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import ReportsOverview from '@/components/reports/Overview.vue'
import Notification from '@/components/reports/Notification.vue'
import Integration from '@/components/Integration.vue'
import SupportArticle from '@/components/SupportArticle.vue'
import BulkEditor from '@/components/editor/BulkEditor.vue'

// Navigation Components
import Sidebar from "@/components/navigation/Sidebar.vue"
import Header from "@/components/navigation/Header.vue"
import SiteNav from "@/components/navigation/SiteNav.vue"

// Views
import Home from '@/views/Home.vue'
import Welcome from '@/views/Welcome.vue'
import Database from '@/views/Database.vue'
import Survey from '@/views/Survey.vue'
import Submit from '@/views/Submit.vue'
import Emails from '@/views/Emails.vue'
import Account from '@/views/Account.vue'
import Installation from '@/views/Installation.vue'
import Install from '@/views/Install.vue'
import Integrations from '@/views/Integrations.vue'
import Notifications from '@/views/Notifications.vue'
import Plan from '@/views/Plan.vue'
import Privacy from '@/views/Privacy.vue'
import Reports from '@/views/Reports.vue'
import Support from '@/views/Support.vue'

Vue.use(VueRouter)

var routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home
    }
  },
  {
    path: '/submit',
    name: 'Submit',
    components: {
      default: Submit
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    components: {
      default: Welcome,
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/database',
    name: 'Database',
    components: {
      default: Database,
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/survey',
    name: 'Survey',
    components: { default: Survey },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/emails',
    name: 'Emails',
    components: { default: Emails },
    meta: { requiresAuth: true },
    redirect: "/emails/request",
    children: [
      {
        path: "request",
        name: "Email",
        components: { default: EmailRequest },
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: "followup",
      //   name: "Email",
      //   components: { default: EmailFollowup },
      //   meta: { 
      //     requiresAuth: true
      //   }
      // },
      {
        path: "queue",
        name: "Queue",
        components: { default: EmailQueue },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "settings",
        name: "Settings",
        component: EmailSettings,
        meta: { requiresAuth: true }
      }
    ]
  },
  // { path: '/emails/queue', name: 'Email Queue', component: EmailQueue, meta: { requiresAuth: true } },
  { path: '/account', name: 'Account', components: { default: Account }, meta: { requiresAuth: true } },
  { path: '/loading', name: 'Loading', components: { default: Loading }, props: true },
  { path: '/installation', name: 'Installation', components: { default: Installation }, meta: { requiresAuth: true } },
  // { path: '/install', name: 'Install', components: { default: Install } },
  {
    path: '/install/:handle',
    name: 'Install',
    components: {
      default: Install
    },
    props: true
  },
  {
    path: '/integrations',
    name: 'Integrations',
    components: {
      default: Integrations
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/integrations/:handle',
    name: 'Integration',
    components: {
      default: Integration
    },
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bulk',
    name: 'BulkEditor',
    components: {
      default: BulkEditor
    },
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  { path: '/plan', name: 'Plan', components: { default: Plan }, meta: { requiresAuth: true } },
  { path: '/privacy', name: 'Privacy', components: { default: Privacy } },
  {
    path: '/reports',
    name: 'Reports',
    components: {
      default: Reports
    },
    redirect: "/reports/overview",
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "overview",
        name: "Overview",
        components: {
          default: ReportsOverview
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "notifications",
        name: "Notification",
        components: {
          default: Notification
        },
        redirect: "/reports/notifications/digest",
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "notifications/:id",
        name: "Notification",
        components: {
          default: Notification
        },
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  { path: '/support', name: 'Support', components: { default: Support }, meta: { requiresAuth: true } },
  { path: '/support/:handle', name: 'SupportArticle', components: { default: SupportArticle }, props: true, meta: { requiresAuth: true } },
  { path: '/register', name: 'Register', components: { default: Register } },
  { path: '/login', name: 'Login', components: { default: Login } }
]


routes = routes.map(route => {
  if (route.name === 'Submit') {
    // Do nothing
    // route.components.header = {};
  } else if (route.name === 'Login' || route.name === 'Register' || route.name === 'Install') {
    route.components.header = Header;
  } else if (route.name !== 'Home') {
    route.components.header = Header;
    route.components.sidebar = Sidebar;
  } else {
    route.components.header = SiteNav;
  }
  return route;
})

// console.log("routes:",routes)

const router = new VueRouter({
  routes,
  mode: 'history'
})

async function checkShopifyPlan() {

  const currentUser = await firebase.getCurrentUser()
  const shopifyBilling = functions.httpsCallable("shopifyBilling");

  if (currentUser) {

    var user = (await firebase.getUser("email", currentUser.email))[0];
    var shopifyIntegration = user.integrations.filter(x => x.name === 'Shopify')[0];

    console.log("[router][checkShopifyPlan] user:", user);

    if (shopifyIntegration.accessToken) {
      if (user.billing) {

        var selectedPlan = user.billing.selectedPlan;

        // Check plan usage and update
        var activeSubscription = await shopifyBilling({
          method: "getPlanUsage",
          userId: user.id,
          plan: user.billing.selectedPlan,
          appSubscription: user.billing.appSubscription
        }).then((response) => {
          return response;
        }).catch((err) => {
          console.log("[getPlanUsage] error:", err);
          return err;
        })

        console.log("[checkShopifyPlan] user.billing.appSubscription:", user.billing.appSubscription);
        console.log("[checkShopifyPlan] activeSubscription:", activeSubscription);
        if (activeSubscription.data.error) {
          console.log("[checkShopifyPlan] activeSubscription:", activeSubscription.data.error);

        }

        var shopifyPlanUsage = activeSubscription && activeSubscription.data.node && user.billing.appSubscription.id ? activeSubscription.data.node.lineItems[1].plan.pricingDetails.balanceUsed.amount : null;
        var selectedPlanUsage = user.billing.selectedPlan && selectedPlan.maxEntries !== "Unlimited" ? (selectedPlan.price / selectedPlan.maxEntries * user.entryCount).toFixed(2) : null;

        // console.log("userEntryCount:",user.entryCount);
        // console.log("shopifyPlanUsage:",shopifyPlanUsage);
        // console.log("selectedPlanUsage:",selectedPlanUsage);

        if (shopifyPlanUsage && selectedPlanUsage) {

          if (Number(selectedPlanUsage) > Number(shopifyPlanUsage)) {
            // console.log("[ROUTER] PLAN: Selected Plan usage is less than Usage records, create a new one.")
            await shopifyBilling({
              method: "createUsageRecord",
              userId: user.id,
              plan: user.billing.selectedPlan,
              usage: selectedPlanUsage,
              lineItemId: `gid://shopify/AppSubscriptionLineItem/${user.billing.appSubscription.chargeId}?v=1&index=1`
            }).then((response) => {
              console.log("[createUsageRecord] response:", response)
            }).catch((err) => {
              console.log("[createUsageRecord] error:", err)
            })


          } else {
            console.log("[checkShopifyPlan] PLAN: Shopify Plan max usage is greater than Selected Plan Usage records.")
          }
        } else {
          console.log("[checkShopifyPlan] PLAN: No active plan selected.")
        }

      } else {
        console.log("[checkShopifyPlan] user does not have a vaild plan.")
      }
    } else {
      console.log("[checkShopifyPlan] shopifyIntegration does not have an access token.")
    }
    // NProgress.done();
  }

}

router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = await firebase.getCurrentUser();

  // Set Document title
  document.title = document.title.split(" | ")[0] + ` | ${to.name}`;

  if (requiresAuth && !currentUser) {
    next({ name: 'Login' });
  } else {
    if (currentUser) {

      // if (to.name) { NProgress.start(); }

      var users = await firebase.getUser("email", currentUser.email);
      if (users) {

        var user = users[0];

        await store.dispatch("fetchUser", user);

        // console.log("[ROUTER] User is present, mark as signed in.")
        window.signedIn = true;

      }
    }
    next();
  }
});

router.afterEach(async () => {

  await checkShopifyPlan();


})

export default router