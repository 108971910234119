<template>
    <div id="login" class="content-wrapper full-height flex flex-align-center">
        <div class="">
            <div class="header">
                <div class="primary-heading">Sign in</div>
            </div>
            <div class="body">
                <div class="width-400">
                    <div class="card-content">
                        <div v-if="error" class="alert alert-danger margin-bottom-20">Error: {{error}}</div>
                        <form v-if="!showResetPassword" action="#" @submit.prevent="submit" class="form">
                            <div class="card-row">
                                <div v-bind:class="[ 'input-group', { hasData: form.email }, 'full-width margin-bottom-10' ]">
                                    <label for="email" class="">Email</label>
                                    <input
                                      id="email"
                                      type="email"
                                      class=""
                                      name="email"
                                      value
                                      required
                                      autofocus
                                      placeholder="Email"
                                      v-model="form.email"
                                    />
                                </div>
                            </div>
                            <div class="card-row">
                                <div v-bind:class="[ 'input-group', { hasData: form.password }, 'full-width' ]">
                                    <label for="password" class="">Password</label>
                                    <input
                                      id="password"
                                      type="password"
                                      class=""
                                      name="password"
                                      value
                                      required
                                      autofocus
                                      placeholder="Password"
                                      v-model="form.password"
                                    />
                                </div>
                            </div>
                            <div class="card-row">
                                <div class="form-actions full-width">
                                    <div class="margin-bottom-15 clickable" v-on:click="showResetPassword = true;">Forgot password?</div>
                                    <button type="submit" class="button primary full-width">Login</button>
                                </div>
                            </div>
                        </form>
                        <div id="resetPassword" v-else>
                            <div class="card-row">
                                <div v-if="!passwordReset" class="margin-bottom-15">Please enter the email address associated with your account.</div>
                                <div class="input-group">
                                    <label for="email" class="">Email</label>
                                    <input
                                      id="email"
                                      type="email"
                                      class=""
                                      name="email"
                                      value
                                      required
                                      autofocus
                                      placeholder="Email"
                                      v-model="form.email"
                                    />
                                </div>
                            </div>
                            <div class="card-row">
                                <div class="actions">
                                    <button class="button primary full-width" v-on:click="resetPassword()" v-bind:class="{ inactive: !validateEmail(form.email) || passwordReset }">
                                        <span v-if="passwordResetLoading && !passwordReset">Loading...</span>
                                        <span v-else-if="passwordReset">Success!</span>
                                        <span v-else>Reset password</span>
                                    </button>
                                    <div v-if="passwordReset" class="margin-top-20">Please check your email for instructions on how to reset your password.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="margin-top-20">
                    <a v-if="showResetPassword" v-on:click="showResetPassword = false;" class="margin-bottom-20 text-sm">
                       Back to login
                    </a>
                    <div v-else class="text-sm">Don't have an account? <a v-on:click="$router.push({ name: 'Register' })">Sign up here</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    #app #Login.app-content {
        height: 100%;
        margin-top: 0;

        .content-wrapper {
            .header {
                margin-bottom: 30px;
            }
        }
    }
</style>

<script>
    import { auth } from "../firebase";
    import { firebase } from "@firebase/app";

    export default {
        data: function () {
            return {
                form: {
                    email: "",
                    password: ""
                },
                error: null,
                showResetPassword: false,
                passwordReset: false,
                passwordResetLoading: false
            }
        },
        created: function() {
            console.log("email:",this.form.email);
        },
        beforeRouteLeave(to, from, next) {
            // called when the route that renders this component is about to
            // be navigated away from.
            // has access to `this` component instance.
            console.log("[LOGIN] emit signedIn true");
            this.$emit("signedIn",true)
            next();
        },
        methods: {
            submit: function() {
                auth.signInWithEmailAndPassword(this.form.email, this.form.password)
                .then(() => {
                  
                  this.$router.push({ name: "Welcome" });

                })
                .catch(err => {
                  this.error = err.message;
                })
            },
            resetPassword: function() {
                var vm = this;
                vm.passwordResetLoading = true;
                firebase.auth()
                .sendPasswordResetEmail(this.form.email)
                  .then(() => {
                    // Password reset email sent!
                    // ..
                    console.log(`Sent password reset email to: ${vm.form.email}`)
                    vm.passwordResetLoading = true;
                    vm.passwordReset = true;
                  })
                  .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ..
                    console.log(`Error resetting password: ${errorCode} - ${errorMessage}`)
                });
            }
        },
        watch: {
            "form.email": {
                handler: function() {
                    // console.log("email:",this.form.email);
                }
            }
        }
    };
</script>