<template>
    <table class="module stackedBar">
        <thead>
            <tr>
                <th class="module-title color-headings">{{ module.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="metrics stackedBar">
                    <table>
                        <tbody>
                            <tr class="metric" v-for="(metric, index) in metrics" :key="`metric-${index}`">
                                <td class="text-sm description">
                                    {{ metric.description }}
                                </td>
                                <td class="value-bar">
                                    <div class="bar"
                                        v-for="(channel, cIndex) in sortList(metric.data.channels.slice(0, limit))"
                                        :key="`metric-${cIndex}`"
                                        :style="{ width: getWidth(metric, channel) + '%', backgroundColor: getColor(channel.name) }">
                                        <div class="value-text">
                                            <!-- <span>{{ formatNumber(channel.entries, '0,0[.]00')}}</span> -->
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="legend">
                        <div class="label" v-for="(item, index) in legend" :key="`label-${index}`">
                            <div class="description">
                                <span :style="`background-color: ${getColor(item)}`"></span>
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>

export default {
    name: "stackedBar",
    props: {
        module: Object,
        userData: Object,
        userMetrics: Object,
        limit: Number
    },
    data: function () {
        return {
            metrics: [],
            colors: require("@/assets/color-palette.json")
        }
    },
    computed: {
        channels: function () {
            var c = this.metrics.map(x => {
                var channels = this.sortList(x.data.channels.filter(z => z.entries > 0));
                return channels.map(y => y.name);
            }).flat(1).filter(this.onlyUnique);

            var result = c.map((x, index) => {
                return {
                    channel: x,
                    color: this.colors[index]
                }
            })

            // console.log("channels:", result);
            return result;
        },
        legend: function () {

            var descriptions = this.metrics.map(x => {
                var channels = this.sortList(x.data.channels.filter(z => z.entries > 0));
                return channels.map(y => y.name);
            }).flat(1)

            // console.log(descriptions)

            return descriptions.filter(this.onlyUnique);
        }
    },
    created: async function () {
        await this.getMetricData();
    },
    methods: {
        getWidth: function (metric, channel) {

            var channelTotal = 0;
            var metricIndex = this.metrics.findIndex(x => x.description === metric.description);

            this.metrics[metricIndex].data.channels.slice(0, this.limit).forEach((item) => { channelTotal += item.entries });

            // console.log("Percent total:",(channel.entries/channelTotal)*100);

            return (channel.entries / channelTotal) * 100;

        },
        sortList: function (list, dimension) {
            var x = dimension || "entries";
            if (dimension) { 
                console.log("sortList",list) 
                console.log("dimension",dimension) 
            }
            return list.sort((a, b) => {
                if (a.data && b.data) {
                    return b.data[x] - a.data[x];
                } else {
                    return b[x] - a[x]
                }
            });
        },
        getColor: function (channel) {
            // console.log("channel:", channel)
            return this.channels.filter(x => x.channel === channel)[0].color;
        },
        getMetricData: function () {

            // Get modules and metrics
            var metrics = [];
            var moduleY = this.module.y.id.substring(0, this.module.y.id.length - 1);
            var moduleX = this.module.x.id;

            // Special module parsing
            if (this.module.y.id === "birthdays") { moduleY = "age"}
            else if (this.module.y.id === "countries") { moduleY = "country" }

            // Get metrics
            var entryMetrics = JSON.parse(JSON.stringify(this.userMetrics.entryMetrics[this.module.y.id])); // Still use the id for the grouped entries
            
            // Loop through the metrics and map based on their description and X value
            metrics = entryMetrics.map(item => {

                var newItem = JSON.parse(JSON.stringify(item));

                // If we're using the age module Y, group metrics by age groups
                if (moduleY === "age") {
                    if (item[moduleY] < 5) { newItem["description"] = "Not given"; }
                    if (item[moduleY] >= 5 && item[moduleY] < 18)   { newItem["description"] = "Under 18"; }
                    if (item[moduleY] >= 18 && item[moduleY] < 25)  { newItem["description"] = "18 - 24"; }
                    if (item[moduleY] >= 25 && item[moduleY] < 35)  { newItem["description"] = "25 - 35"; }
                    if (item[moduleY] >= 36 && item[moduleY] < 50)  { newItem["description"] = "36 - 50"; }
                    if (item[moduleY] >= 51 && item[moduleY] < 65)  { newItem["description"] = "51 - 65"; }
                    if (item[moduleY] >= 66 && item[moduleY] < 120) { newItem["description"] = "66+"; }
                } else {
                    newItem["description"] = item[moduleY];
                }
                
                newItem.data["type"] = this.module.x.type;
                newItem.data["value"] = String(item.data[moduleX]);
                return newItem;
            });

            
            if (moduleY === "age") {
                var groupedMetrics = [];
                for (var metric of metrics) {
                    var groupIndex = groupedMetrics.findIndex(x => x.description === metric.description);
                    
                    if (groupIndex > -1) {
                        metrics[groupIndex].data.revenue += metric.data.revenue;
                        metrics[groupIndex].data.entries += metric.data.entries;
                    } else {
                        delete metric.age;
                        delete metric.year;
                        groupedMetrics.push(metric);
                    }
                }
                metrics = this.sortList(groupedMetrics.map(x => {x.data.value = String(x.data[moduleX]); return x; } ), moduleX);
            }

            this.metrics = metrics.slice(0, this.limit)
            
        }
    },
    watch: {
        'module.x': {
            handler: function () {
                this.getMetricData();
            }
        }
    }
}
</script>