<template>
    <div class="max-width-100p">
        <div class="body flex flex-v-center flex-h-center">

            <!-- Section: Hero -->
            <section class="hero full-width text-align-center flex">
                <div class="section-content flex-1">
                    <h1 class="text-xl color-headings width-600 margin-left-auto margin-right-auto margin-bottom-20">The easiest way to find out where your customers come from.</h1>
                    <h3 class="width-600 margin-left-auto margin-right-auto text-weight-reg">The post-checkout survey that integrates right into your Shopify checkout - so you never miss an opportunity to find out how your customers found you.</h3>
                    <button class="button primary rounded margin-top-10 inline-flex flex-v-center" @click="$router.push({ name: 'Register' })">
                        <span class="margin-right-5">Get Started</span>
                        <span class="icon icon-arrow-right-icon"></span>
                    </button>
                </div>
            </section>

            <!-- Section: Screenshot -->
            <section class="screenshot section-100 flex flex-v-center flex-h-center" style="height: 1600px;">
                <!-- Dotted Circles -->
                <div id="dotted-circles"></div>
                <!-- Screenshot -->
                <div class="image">
                    <img class="full-width" src="../assets/homepage/screenshot-reports.png"/>
                </div>
            </section>

            <!-- Section: Ping Pong -->
            <section id="learn" class="ping-pong left section-100">
                <div class="wrapper">
                    <div class="image">
                        <img class="full-width" src="../assets/homepage/elements-reports-design.svg"/>
                    </div>
                    <div class="content">
                        <h2 class="heading">Attribution is hard.</h2>
                        <div class="heading-text text-md">Continued improvements in customer privacy protections mean that third-party pixels and trackers are no longer the best way to evaluate the effectiveness of your marketing spend.</div>
                        <div class="heading-text text-md">The best solution to finding out where your customers come from today is, simply, <b>to ask them</b>. That's where Attribute comes in.</div>
                    </div>
                </div>
            </section>

            <!-- Section: Padded Gradient -->
            <section class="padded-gradient">
                <div class="wrapper flex flex-h-center ">
                    <div class="content margin-top-80 text-align-center">
                        <div class="width-600 margin-top-40 margin-left-auto margin-right-auto">
                            <h2 class="heading">Listening to your customers is easy.</h2>
                            <div class="heading-text text-md">
                                Attribute integrates a customer survey directly into your Shopify Checkout, showing up on your order thank you page and allowing you to customize the questions you ask your customers. 
                            </div>
                        </div>
                        <div class="image margin-top-80">
                            <img class="full-width" src="../assets/homepage/screenshot-survey.png"/>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section: Key Figures -->
            <section class="key-figures section-75">
                <div class="figures wrapper">
                    <div class="figure">
                        <div class="value heading-text">25%+</div>
                        <div class="description heading-text">Estimated % of customers who fill out the Attribute survey.</div>
                    </div>
                    <div class="figure">
                        <div class="value heading-text">10x</div>
                        <div class="description heading-text">Estimated return from Attribute's follow-up email campaigns within the first six months.</div>
                    </div>
                    <div class="figure">
                        <div class="value heading-text">10 minutes</div>
                        <div class="description heading-text">The amount of time it takes to set up your Attribute survey.</div>
                    </div>
                </div>
            </section>

            <!-- Section: How It Works -->
            <section id="how-it-works" class="padding-40">
                <div class="wrapper background-blue-5 border-radius-24 flex flex-row-wrap flex-h-center" style="margin: 0 40px; padding: 7.5% 5%;">
                    <div class="content flex-2 padding-60 flex flex-col-nowrap">
                        <h2 class="heading-text text-xl">Simple and easy to use.</h2>
                        <p class="heading-text text-lg">Design your survey, connect to your store, analyze your data, and email your customers</p>
                    </div>
                    <div class="content grid two-column flex-3 padding-40" style="max-width: 500px;">
                        <div v-for="(item, index) in howItWorks" :key="`how-it-works-${index}`" class="grid-item padding-right-30">
                            <div :class="[ `icon ${item.icon}` ]"></div>
                            <div class="title heading-text text-lg text-weight-std" v-html="item.title"></div>
                            <div class="description heading-text text-md" v-html="item.description"></div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section: Features -->
            <section id="features" class="features ping-pong right margin-top-80">
                <div class="header margin-bottom-40">
                    <h2 class="heading text-xl text-align-center">Features that deliver.</h2>
                </div>
                <div class="wrapper flex flex-h-center" style="overflow: hidden; padding: 40px 0;">
                    <div class="image bordered" style="flex: 4; margin-right: -20%;">
                        <img v-for="(feature, index) in features" :key="`feature-image-${index}`" class="full-width" :src="feature.image" :class="{ active: feature.active }"/>
                    </div>
                    <div class="content">
                        <div class="feature" v-for="(feature, index) in features" :key="`feature-${index}`" :class="{ active: feature.active }" @click="changeFeature(feature)">
                            <h3 v-html="feature.name"></h3>
                            <p v-html="feature.description"></p>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section: Plans -->
            <section id="plans" class="padded-gradient">
                <div class="wrapper text-align-center">
                    <div class="header margin-bottom-60 margin-top-40">
                        <h2 class="heading">Plans</h2>
                        <div class="heading-text text-lg max-width-500">Pick the plan with the features you need to get your attribution sorted, once and for all.</div>
                    </div>
                    <div class="content plans text-align-left">
                        <div v-for="plan in plans" :key="plan.id" class="plan flex flex-1 flex-col-nowrap padding-20 text-align-left" :class="{ recommended: plan.recommended }">
                            <div class="header">
                                <div v-html="plan.name" class="heading-text text-weight-mid text-lg"></div>
                                <div v-html="plan.description" class="heading-text text-md margin-bottom-40 margin-top-5"></div>
                            </div>
                            <div class="flex-1">
                            </div>
                            <div class="price">
                                <div v-html="`$${plan.price} / month`" class="heading-text text-xl margin-top-40 margin-bottom-40"></div>
                            </div>
                            <div class="features">
                                <div v-for="feature in plan.features" class="feature heading-text flex text-md margin-bottom-5 text-align-left" :key="feature.name" :class="{ hidden: !feature.available }">
                                    <span class="icon icon-check-circle-2-icon margin-right-5" style="margin-top: 4px;"></span>
                                    <span v-html="feature.name"></span>
                                </div>
                            </div>
                            <div class="actions">
                                <button class="button rounded full-width flex flex-v-center flex-h-space-between margin-top-40" :class="[ plan.recommended ? `primary inverse` : `primary` ]" @click="$router.push({ path: '/install/shopify' })">
                                    <span>Get started</span>
                                    <span class="icon icon-arrow-right-icon"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</template>

<style lang="scss">
#app.siteNavigation {
    .app-content {
        margin-top: 0;
        overflow-x: hidden;
        // font-family: 'Founders Grotesk' !important;

        .body {
            flex-flow: column nowrap;
            background-image: url("../assets/attribute-gradient-blue.svg");
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
        }

        #dotted-circles {
          background-image: url("../assets/homepage/dotted-circles.png");
          background-position: top center;
          background-repeat: no-repeat;
          background-size: cover;
          height:  100%;
          width:  100%;
          position:  absolute;
          z-index: 0;
          top:  0;
          left:  0;
        }

        section {
            width: 100%;
            z-index: 1;
            position: relative;

            > .wrapper {
                max-width: 1440px;
                margin: 0 auto;
                height: 100%;
            }
            .image {
                &.bordered {
                    border: 1px solid #332626;
                    filter: drop-shadow(0px 16px 40px rgba(190, 190, 190, 0.2));
                    border-radius: 24px;
                    padding: 10px 10px 4px;
                }
            }

            &#plans {
                .wrapper {
                    background-image: url("../assets/homepage/gradient-4.svg");
                }
            }

            &.hero {
                height: auto;
                min-height: 500px;
                padding-top: 25vh;
            }
            &.screenshot {
                overflow: visible;

                .image {
                    min-width: 320px;
                    max-width: 1200px;
                    width: 100%;
                    z-index: 2;
                    border: 1px solid #332626;
                    filter: drop-shadow(0px 16px 40px rgba(190, 190, 190, 0.2));
                    border-radius: 24px;
                    padding: 10px 10px 4px;
                    margin: 0 20px;

                    img {
                        border-radius: 16px;
                    }
                }
            }
            &.padded-gradient {
                padding: 40px;
                width: calc(100% - 80px);
                height: 50vh;
                min-height: 900px;

                .wrapper {
                    background-image: url("../assets/homepage/gradient-2.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 24px;

                    .heading {
                        font-size: 36px;
                        line-height: 44px;
                        margin-bottom: 30px;
                    }

                    .image {
                        border: 1px solid #332626;
                        filter: drop-shadow(0px 16px 40px rgba(190, 190, 190, 0.2));
                        border-radius: 24px;
                        padding: 10px 10px 4px;
                        margin: 80px 10%;

                        img {
                            border-radius: 16px;
                        }
                    }
                }
            }
            &.ping-pong {
                .wrapper {
                    max-width: initial;
                    display: flex;
                    align-items: center;
                    padding: 20px 0;

                    .image {
                        flex: 2;

                        img {
                            border-radius: 8px;
                        }
                    }

                    .content {
                        flex: 2;
                        padding: 80px;

                        .heading {
                            font-size: 36px;
                            margin-bottom: 30px;
                        }

                        div {
                            margin-bottom: 15px;
                        }
                    }

                }

                &.right {
                    .wrapper {
                        .image { order: 2; }
                        .content { order: 1; }
                    }
                }
            }

            &.key-figures {
                .figures {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;

                    .figure {
                        padding: 20px;
                        max-width: 240px;
                        text-align: center;
                        margin: 20px 30px;

                        .value {
                            font-size: 48px;
                            line-height: 1.2em;
                            font-weight: 600;
                            white-space: nowrap;
                            margin: 0 0 20px;
                        }
                        .description {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
            }
            &.features {
                .wrapper {
                    .image {
                        height: 80%;
                        padding: 10px;
                        
                        img {
                            display: none;

                            &.active {
                                display: block;
                            }
                        }
                        
                    }
                    .content {
                        .feature {
                            padding-left: 20px;
                            margin-bottom: 30px;

                            h3 {
                                margin-bottom: 0;
                            }
                            p {
                                margin: 5px 0 0;
                            }
                        }
                        .feature:not(.active) {
                            > * {
                                color: #ADA8A8;
                                cursor: pointer;
                            }
                        }
                        .feature.active {
                            border-left: 2px solid #FC4A3F;
                        }
                    }
                }
            }

            &#how-it-works {
                .grid {
                    .grid-item {
                        margin-bottom: 40px;
                    }
                    .icon {
                        padding: 15px;
                        border: 1px solid #332626;
                        border-radius: 100%;
                        display: inline-block;
                        color: #FC4A3F;
                        margin: 10px 0 20px;
                        font-size: 30px;
                    }
                    .title {
                    }
                    .description {
                    }
                }
            }
        }

        #plans {
            margin-bottom: 40px;
            height: auto;

            .wrapper {
                padding: 80px 40px 120px;
                height: auto;

                .plans {
                    display: inline-block;
                    text-align: center;
                    
                    @media (min-width: 1400px) { margin: 60px auto 0; }

                    .plan {
                        padding: 30px;
                        margin: 20px;
                        border-radius: 8px;
                        border: none;
                        min-width: 300px;
                        max-width: 300px;
                        height: 550px;
                        display: inline-flex !important;
                        flex-flow: column nowrap;

                        &.recommended {
                            background-color: #FC4A3F;
                            color: white;
                            
                            @media (min-width: 1400px) { transform: translateY(-60px); }

                            div {
                                color: white;
                            }
                        }
                    }
                }
            }
            
        }
    }
    nav {
        top: 0;
        justify-content: flex-end;
        flex-flow: row nowrap;
        background: transparent;

        .links {
            .link {
                margin-left: 20px;
                border: none;
                padding: 10px 20px;
            }
        }
    }
}
</style>

<script>

export default {
    name: "Home",
    components: {
    },
    data: function() {
        return {
            howItWorks: [
                {
                    icon: "icon-link-2-icon",
                    title: "1. Connect",
                    description: "Connect the Attribute App to your Shopify store"
                },
                {
                    icon: "icon-edit-3-icon",
                    title: "2. Design",
                    description: "Design and customize your survey"
                },
                {
                    icon: "icon-bar-chart-2-icon",
                    title: "3. Analyze",
                    description: "View your reports and analyze your data"
                },
                {
                    icon: "icon-mail-icon",
                    title: "4. Email",
                    description: "Email your customers with an incentive to keep purchasing"
                },
            ],
            features: [
                {
                    name: "Customizable post-checkout customer survey",
                    description: "Easy to configure and launch survey that integrates directly into your Shopify checkout",
                    image: require("../assets/homepage/screenshot-survey.png"),
                    active: true
                },
                {
                    name: "Automated post-survey email campaign",
                    description: "Email your customers with a thank you (and discount) immediately after they complete your survey",
                    image: require("../assets/homepage/screenshot-reports.png"),
                    active: false
                },
                {
                    name: "Real-time customer and revenue reporting",
                    description: "See where every customer comes from as soon as they fill out your survey",
                    image: require("../assets/homepage/screenshot-reports.png"),
                    active: false
                },
                {
                    name: "Exportable customer database",
                    description: "Edit or export your customer database to the analytics tool of your choice",
                    image: require("../assets/homepage/screenshot-database.svg"),
                    active: false
                }
            ],
            plans: require("../assets/data-plans.json")
        }
    },
    methods: {
        changeFeature: function(feature) {
            this.features.forEach((item) => { item.active = false; });
            feature.active = true;
        }
    }
};
</script>