<template>
  <div class="editor">
    <div class="toolbar" v-if="editor">
      <div class="font-family dropdown">
        <button @click="toggleDropdown('fontFamily')" class="dropdown-button material-icons padding-top-0">
          text_fields
        </button>
        <div v-if="dropdowns.fontFamily.visible" class="dropdown-content">
          <button @click="editor.commands.setFontFamily('apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif'); toggleDropdown('fontFamilyVisible')" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;' }) }">
            SF Mono
          </button>
          <button @click="editor.commands.setFontFamily('Arial'); toggleDropdown('fontFamily')" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'Arial' }) }">
            Arial
          </button>
          <button @click="editor.commands.setFontFamily('Times New Roman'); toggleDropdown('fontFamily')" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: 'Times New Roman' }) }">
            Times New Roman
          </button>
        </div>
      </div>
      <div class="text-styles dropdown">
        <button @click="toggleDropdown('textFormat')" class="dropdown-button material-icons">
          text_format
        </button>
        <div v-if="dropdowns.textFormat.visible" class="dropdown-content">
          <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run(); toggleDropdown('textFormat')" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
            <span class="h1">Heading 1</span>
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run(); toggleDropdown('textFormat')" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
            <span class="h2">Heading 2</span>
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run(); toggleDropdown('textFormat')" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
            <span class="h3">Heading 3</span>
          </button>
          <button @click="editor.chain().focus().setParagraph().run(); toggleDropdown('textFormat')" :class="{ 'is-active': editor.isActive('paragraph') }">
            <span class="paragraph">Paragraph</span>
          </button>
        </div>
      </div>
      <button @click="editor.chain().focus().toggleBold().run()" :class="[{ 'is-active': editor.isActive('bold') }, 'material-icons']">
        format_bold
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :class="[{ 'is-active': editor.isActive('italic') }, 'material-icons']">
        format_italic
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="[{ 'is-active': editor.isActive('strike') }, 'material-icons']">
        format_strikethrough
      </button>
      <button @click="editor.chain().focus().setTextAlign('left').run()" :class="[{ 'is-active': editor.isActive({ textAlign: 'left' }) }, 'material-icons']">
        format_align_left
      </button>
      <button @click="editor.chain().focus().setTextAlign('center').run()" :class="[{ 'is-active': editor.isActive({ textAlign: 'center' }) }, 'material-icons']">
        format_align_center
      </button>
      <button @click="editor.chain().focus().setTextAlign('right').run()" :class="[{ 'is-active': editor.isActive({ textAlign: 'right' }) }, 'material-icons']">
        format_align_right
      </button>
      <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="[{ 'is-active': editor.isActive({ textAlign: 'justify' }) }, 'material-icons']">
        format_align_justify
      </button>
      <input
        type="color"
        @input="editor.chain().focus().setColor($event.target.value).run()"
        :value="editor.getAttributes('textStyle').color"
      >
      <button @click="showModal('custom-image')" class="material-icons">
        image
      </button>
      <div id="mergeTags" class="dropdown">
        <button id="viewMergeTags" class="flex flex-v-center padding-top-0 dropdown-button" @click="toggleDropdown('mergeTags')">
          <img src="@/assets/icon-curly-brackets.svg" class="width-15" style="pointer-events: none"/>
        </button>
        <div v-if="dropdowns.mergeTags.visible" class="dropdown-content">
          <div class="heading">Merge tags</div>
          <button @click="editor.commands.insertContent('{{ shop_name }}')" v-html="'{{ shop_name }}'"></button>
          <button @click="editor.commands.insertContent('{{ customer_name }}')" v-html="'{{ customer_name }}'"></button>
          <button @click="editor.commands.insertContent('{{ discount_code }}')" v-html="'{{ discount_code }}'"></button>
        </div>
      </div>
      <button @click="getLink('button'); showModal('custom-button');" class="material-icons" :class="{ 'is-active': editor.isActive('custom-link', { type: 'button' }) }">smart_button</button>
      <button @click="getLink('link'); showModal('custom-link');" class="material-icons" :class="{ 'is-active': editor.isActive('custom-link', { type: 'link' }) }">link</button>
      <div class="filler" style="flex: 1;"></div>
      <button class="material-icons" @click="toggleCodeView()">code</button>
    </div>

    <!-- Bubble Menu -->
    <bubble-menu
         class="bubble-menu"
         :tippy-options="{ animation: false }"
         :editor="editor"
         v-if="editor"
         v-show="editor.isActive('custom-image') || editor.isActive('custom-link')">

        <!-- Image: Size -->
        <div v-if="editor.isActive('custom-image')">
          <button
           @click="editor.chain().focus().setImage({ size: 'small' }).run()"
           :class="{ 'is-active': editor.isActive('custom-image', { size: 'small' })}">
           sm
          </button>
          <button
              @click="editor.chain().focus().setImage({ size: 'medium' }).run()"
              :class="{ 'is-active': editor.isActive('custom-image', { size: 'medium' })}">
              md
          </button>
          <button
              @click="editor.chain().focus().setImage({ size: 'large' }).run()"
              :class="{ 'is-active': editor.isActive('custom-image', { size: 'large' })}">
              lg
          </button>
         
          <span class="separator" style="color: #aaa">|</span>
        </div>

        <!-- Alignment -->
        <div v-if="editor.isActive('custom-image')">
          <button
            @click="alignImage('left')"
            :class="[{ 'is-active': editor.isActive('custom-image', { align: 'left' })}, 'material-icons']">
              format_align_left
          </button>
          <button
            @click="alignImage('center')"
            :class="[{ 'is-active': editor.isActive('custom-image', { align: 'center' })}, 'material-icons']">
              format_align_center
          </button>
          <button
            @click="alignImage('right')"
            :class="[{ 'is-active': editor.isActive('custom-image', { align: 'right' })}, 'material-icons']">
              format_align_right
          </button>
          <span class="separator" style="color: #aaa">|</span>
        </div>

        <!-- Change -->
        <div>
          <button v-if="editor.isActive('custom-image')" @click="changeModal('custom-image')">
            <span>Change image</span>
          </button>
          <button v-if="editor.isActive('custom-link', { type: 'button' })" @click="getLink('button'); showModal('custom-button');">
            <span>Change button</span>
          </button>
          <button v-if="editor.isActive('custom-link', { type: 'link' })" @click="getLink('link'); showModal('custom-link');">
            <span>Change link</span>
          </button>
        </div>

     </bubble-menu>

    <!-- Editor Content -->
    <div v-bind:class="modelClass">
      <editor-content :editor="editor" class="content" :style="`background-color: ${modelValue.background}`" v-bind:class="{ hidden: editorCodeVisible }"/>
      <textarea v-bind:class="[{ hidden: !editorCodeVisible }, 'height-300' ]" id="codeView" v-model="editorCodeContent" style="margin-bottom: -5px; border: none; border-radius: 0px 4px 4px 0px; outline: 0;"></textarea>
    </div>

    <!-- MODAL: Add Image -->
    <Modal id="modal-custom-image" v-if="modals['custom-image'].visible" @close="closeModal('custom-image')">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Select an image</div>
        </template>

        <template v-slot:body>
          <div class="wrapper">
            <div class="image-preview" v-if="imagePreviewUrl">
              <div class="width-300 height-300 background-image margin-bottom-20 border-radius-4" :style="`background-image: url('${imagePreviewUrl}')`">
              </div>
            </div>
            <div class="input-group file">
              <label 
                class="file-input-label full-width full-height background-image border-radius-4 border-1">
                  <input type="file"
                    class="file-input full-width full-height"
                    @change="onImageSelected"
                    fieldKey="featuredImage"
                    accept="image/*"
                    path="media"
                  />
                  <div>
                    <span v-if="!imagePreviewUrl">Upload Image</span>
                    <span v-else>Change Image</span>
                  </div>
              </label>
            </div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
                <button type="button" class="button small full-width" v-on:click="confirmImage()" v-if="imagePreviewUrl">
                    <span v-if="!loadingImage">Confirm</span>
                    <span v-else>Loading...</span>
                </button>
            </div>
        </template>
    </Modal>

    <!-- MODAL: Add Link -->
    <Modal id="modal-custom-link" v-if="modals['custom-link'].visible" @close="closeModal('custom-link')">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Add a button</div>
        </template>

        <template v-slot:body>
          <div class="wrapper">
            <div class="input-group text">
              <label>Link text</label>
              <input type="text"
                v-model="linkPreview.text" />
            </div>
            <div class="input-group text">
              <label>Link URL</label>
              <input type="url" 
                v-model="linkPreview.href" />
            </div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
                <button type="button" class="button primary full-width" v-on:click="confirmLink()">
                    Confirm
                </button>
            </div>
        </template>
    </Modal>

    <!-- MODAL: Add Button -->
    <Modal id="modal-custom-button" v-if="modals['custom-button'].visible" @close="closeModal('custom-button')">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Add a button</div>
        </template>

        <template v-slot:body>
          <div class="wrapper">
            <div class="input-group text">
              <label>Button text</label>
              <input type="text"
                v-model="buttonPreview.text" />
            </div>
            <div class="input-group text">
              <label>Button URL</label>
              <input type="url" 
                v-model="buttonPreview.href" />
            </div>
            <div class="flex flex-v-center">
              <div class="input-group color margin-right-15">
                <label>Text Color</label>
                <input type="color" 
                  v-model="buttonPreview.textcolor" />
              </div>
              <div class="input-group color">
                <label>Background</label>
                <input type="color" 
                  v-model="buttonPreview.background" />
              </div>
            </div>
            <div class="input-group select margin-bottom-0">
              <label>Width</label>
              <select class="full-width min-width-240" v-model="buttonPreview.width">
                <option default disabled>Pick one</option>
                <option value="auto">Auto</option>
                <option value="100%">Full Width</option>
              </select>
            </div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
                <button type="button" class="button primary full-width" v-on:click="confirmButton()">
                    Confirm
                </button>
            </div>
        </template>
    </Modal>

  </div>
</template>

<style scoped lang="scss">
  .editor {
    .attributeEmail {
    .content {
        padding: 20px !important;
      }
    }
  }
</style>

<script>
import { mapGetters } from "vuex"
import { firebase } from "@firebase/app"
import Modal from "@/components/Modal.vue"
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
// import Image from '@tiptap/extension-image'
import { Color } from '@tiptap/extension-color'
// import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import FontFamily from '@tiptap/extension-font-family'

// Custom Extensions
import CustomImage from '@/extensions/customImage'
import CustomLink from '@/extensions/customLink'
// import Dropcursor from '@tiptap/extension-dropcursor'

export default {
  components: {
    EditorContent,
    Modal,
    BubbleMenu
  },
  props: {
    modelValue: Object,
    modelClass: String
  },
  data() {
    return {
      editor: null,
      editorCodeContent: "",
      editorCodeVisible: false,
      dropdowns: {
        textFormat: { visible: false },
        fontFamily: { visible: false },
        mergeTags: { visible: false }
      },
      loadingImage: false,
      imagePreviewUrl: "",
      modals: {
        "custom-image": { visible: false },
        "custom-link": { visible: false },
        "custom-button": { visible: false }
      },
      buttonPreview: {
        type: 'button',
        text: null,
        textcolor: null,
        href: null,
        background: null,
        width: null
      },
      linkPreview: {
        type: 'link',
        text: null,
        href: null
      }
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  created() {
    var vm = this;
    
    this.editorCodeContent = this.modelValue.content;
    this.modelValue.isSaved = true;

    // console.log("this.modelValue",this.modelValue)

    this.editor = new Editor({
      content: this.modelValue.content,
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Text,
        TextStyle,
        Color,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        FontFamily.configure({
          types: ['textStyle'],
        }),
        // Custom Extensions
        CustomImage.configure({
          HTMLAttributes: {
            class: 'custom-image'
          }
        }),
        CustomLink.configure({
          HTMLAttributes: {
            class: 'custom-link'
          }
        })
      ],
      injectCSS: false,
      onUpdate: () => {
        // vm.editorCodeContent = vm.editor.getHTML();
        vm.updateModelValue(vm.editor.getHTML());
      },
    })

    document.querySelector('body').addEventListener('click', function(event) {
      var classList = event.target.classList;
      if (!classList.contains('dropdown-button')) {
        for (var prop in vm.dropdowns) {
          vm.dropdowns[prop].visible = false;
        }
      }
    })

  },
  beforeDestroy() {
    if (this.editor) { this.editor.destroy() }
  },
  methods: {
    toggleDropdown: function(target) {
        var dropdown = this.dropdowns[target];
        dropdown.visible = !dropdown.visible;
    },
    toggleCodeView: function() {
      this.editorCodeVisible = !this.editorCodeVisible;
    },
    onImageSelected: async function() {

      var path = event.target.getAttribute('path');
      // var fieldKey = event.target.getAttribute('fieldKey');
      var uploadedImageUrl;

      // console.log("[EDITOR] upload file to path:",path);
      // console.log("[EDITOR] upload file to user ID:",this.user.data.id);

      if (path) {

        var uploadFile = event.target.files[0];
        var uploadPath;

        // console.log("[EDITOR] uploadFile:",uploadFile)

        uploadPath = `${this.user.data.id}/${path}/${uploadFile.name}`;
        uploadedImageUrl = await this.saveImageToFirebase(uploadPath, uploadFile);

        this.imagePreviewUrl = uploadedImageUrl;

      }

    },
    onButtonSelected: function() {

      // console.log("[onButtonSelected] event.target.value:",event.target.value)
    },
    saveImageToFirebase: async function(uploadPath, uploadFile) {

      var existingImage = await firebase.getFileMetadata(this.user, uploadPath);
      var file = uploadFile;

      if (!existingImage.customMetadata) {
        file.metadata = { 
          customMetadata: {
            productId: this.$route.params.id, 
            contentType: file.type
          }
        }
      } else {
        file.metadata = {
          customMetadata: existingImage.customMetadata
        }
      }

      var uploadedImage    = await firebase.uploadFile(this.user, uploadPath, file);
      var uploadedImageURL = await uploadedImage.ref.getDownloadURL().then((downloadURL) => { return downloadURL; });
      
      return uploadedImageURL;

    },
    updateModelValue: function(content) {
      this.$emit('modelValue', { index: this.modelValue.index, content: content, isSaved: false });
    },
    confirmImage: function() {
      // console.log("confirm Image:",this.imagePreviewUrl);
      this.editor.chain().focus().setImage({ src: this.imagePreviewUrl }).run();
      this.closeModal();
    },
    changeModal: function(modalId) {

      var htmlAttributes = this.editor.getAttributes(modalId);

      // console.log("[changeModal] htmlAttributes:",htmlAttributes);

      // Prep the data for the modal
      if (modalId === "custom-image") { this.imagePreviewUrl = htmlAttributes.src; }
      // if (modalId === "custom-link")  { this.linkPreview = { href: htmlAttributes.href, text: htmlAttributes.text }; }
      // if (modalId === "custom-button")  { this.buttonPreview = { href: htmlAttributes.href, text: htmlAttributes.text } }

      this.showModal(modalId);

    },
    getLink: function(linkType) {
      if (this.editor.isActive('custom-link')) {
        // console.log("[getLink]:",this.editor.getAttributes('custom-link'));
        var link = this.editor.getAttributes('custom-link');
        if (link) {
          if (linkType === "button") { this.buttonPreview = link; }
          if (linkType === "link")   { this.linkPreview = link; }
        }
      }
    },
    confirmLink: function() {
      // console.log("linkPreview:",this.linkPreview);
      this.editor.commands.setLink(this.linkPreview);
      this.closeModal('custom-link');
    },
    confirmButton: function() {
      // console.log("buttonPreview:",this.buttonPreview);
      this.editor.commands.setLink(this.buttonPreview);
      this.closeModal('custom-button');
    },
    showModal: function(modalId) {
      this.modals[modalId].visible = true;
    },
    closeModal: function(modalId) {
      // console.log("close modal:",modalId);
      this.modals[modalId].visible = false;
    },
    alignImage: function(alignment) {
      // console.log("updated alignment:",alignment)
      this.editor.commands.setImage({ align: alignment });          
    }
  },
  watch: {
    "editorCodeContent": {
      handler() {
        // this.editor.commands.setContent(this.editorCodeContent);
        this.updateModelValue(this.editorCodeContent);
        this.editor.commands.setContent(this.editorCodeContent);
        // this.editor.commands.setContent(this.editorCodeContent);
      }
    }
  }
};
</script>