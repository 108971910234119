<template>
  <nav id="site-navigation">

    <div v-if="!signedIn" class="links flex flex-v-center">
      <div class="logo"><img src="@/assets/logo-attribute.svg"/></div>
      <a class="link" href="#learn">Learn</a>
      <a class="link" href="#how-it-works">How it works</a>
      <a class="link" href="#features">Features</a>
      <router-link :to="{ path: 'install/shopify' }" class="link button primary">
        <span>Get started</span>
        <span class="icon icon-arrow-right-icon"></span>
      </router-link>
    </div>

    <div v-else class="links flex flex-h-self-end flex-v-center">
      <div class="logo"><img src="@/assets/logo-attribute.svg"/></div>
      <a class="link" href="#learn">Learn</a>
      <a class="link" href="#how-it-works">How it works</a>
      <a class="link" href="#features">Features</a>
      <router-link :to="{ name: 'Welcome' }" class="link button primary">
        <span>Your Account</span>
        <span class="icon icon-arrow-right-icon"></span>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SiteNavigation",
  props: {
    signedIn: Boolean
  },
  data: function() {
    return {
      navItems: [
        { name: "Register", label: "Get Started" },
        { name: "Login", label: "Log In" }
      ]
    }
  },
  created: function() {
  }
};
</script>