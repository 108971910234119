<template>
    <div class="modal-backdrop">
        <div class="modal">
            <header class="modal-header">
              <slot name="header">
                This is the default title!
              </slot>
              <button type="button" class="btn-close" @click="closeModal">
                <img src="@/assets/icon-cross.svg" class="full-width"/>
              </button>
            </header>
            <section class="modal-body">
              <slot name="body">
                This is the default body!
              </slot>
            </section>
            <footer class="modal-footer">
              <slot name="footer">
                This is the default footer!
              </slot>
              <!-- <button type="button" class="button" @click="close">Close Modal</button> -->
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
      close: Boolean
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
};
</script>