<template>
    <div class="content-wrapper width-900">
        <!-- Page Title -->
        <div class="title" v-bind:class="{ unsaved: !isSaved }">
            Your Account
        </div>
        <div v-if="userData" class="body two-columns">
            <div class="column padding-right-40">
                <div class="heading text-sm text-weight-bold">Profile</div>
                <div class="fields margin-top-20">
                    <div class="input-group margin-bottom-10">
                        <label for="name">Name</label>
                        <input type="text" name="name" v-model="userData.name"/>
                    </div>
                    <div class="input-group margin-bottom-10">
                        <label for="company">Company</label>
                        <input type="text" name="company"  v-model="userData.displayName"/>
                    </div>
                    <div class="input-group">
                        <label for="email">Email</label>
                        <input type="email" name="email"  v-model="userData.email"/>
                    </div>
                </div>
                <div class="actions">
                    <div class="row flex flex-align-center">
                        <button class="button primary outline margin-right-20" v-bind:class="{ inactive: passwordReset }" v-on:click="resetPassword()">
                            <span v-if="passwordResetLoading && !passwordReset">Loading...</span>
                            <span v-else-if="passwordReset">Success!</span>
                            <span v-else>Reset password</span>
                        </button>
                        <button class="button primary" v-on:click="signOutUser()">Sign out</button>
                    </div>
                    <div v-if="passwordReset" class="margin-top-20">Please check your email for instructions on how to reset your password.</div>
                    <!-- <div class="text-link danger display-block margin-top-20">Delete your account</div> -->
                </div>
            </div>
            <div class="column padding-right-40">
                <div class="heading text-sm text-weight-bold">Billing Information</div>
                <div class="fields margin-top-20">

                    <!-- Selected Plan -->
                    <div v-if="userData.billing && userData.billing.selectedPlan" class="input-group flex flex-row-wrap">
                        <label for="plan" class="display-block flex-0 full-width">Plan</label>
                        <input type="text" v-bind:value="`${userData.billing.selectedPlan.name} ($${userData.billing.selectedPlan.price} for ${userData.billing.selectedPlan.maxEntries} entries per month)`" class="flex-1 margin-right-10 selected background-alternate text-weight-med inactive"/>
                        <button class="button small primary" v-on:click="$router.push({ name: 'Plan' })">Change Plan</button>
                    </div>
                    <div v-else>
                        <div class="margin-bottom-20">No plan selected.</div>
                        <button class="button small primary" v-on:click="$router.push({ name: 'Plan' })">Choose a plan</button>
                    </div>

                    <!-- Plan Usage -->
                    <div v-if="userData.billing && userData.billing.selectedPlan" class="input-group">
                        <label for="usage">Plan Usage</label>
                        <div class="progress-bar">
                            <span class="progress" v-bind:class="{ full: planUsage() >= 100 }" v-bind:style="`width: ${progress()}`"></span>
                        </div>
                        <div class="progress-caption">
                            <span class="usage heading-text">{{ user.data.entryCount }}</span>
                            <span class="separator heading-text">/</span>
                            <span class="maxUsage heading-text">{{ user.data.billing.selectedPlan.maxEntries }}</span>
                            <span class="margin-left-5">entries used this month</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Save Button -->
        <div class="saveButton" v-bind:class="{ unsaved: !isSaved }">
            <button v-if="!isSaved" class="button padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 margin-left-20" v-on:click="saveUserData()">
              <span v-if="isSaving">Saving...</span>
              <span v-else>Save</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { firebase } from "@firebase/app";
import { auth, db, functions } from "@/firebase";

const shopifyBilling = functions.httpsCallable("shopifyBilling");

export default {
    name: "Account",
    data: function() {
        return {
            userData: {},
            isSaved: true,
            passwordReset: false,
            passwordResetLoading: false,
            isSaving: false
        }
    },
    computed: {
        ...mapGetters({
          // map `this.user` to `this.$store.getters.user`
          user: "user"
        }),
    },
    created: async function() {
        this.userData = (await firebase.getUser("email", this.user.data.email))[0];
        this.isSaved = true;

        if (this.environment === "development") { console.log("[ACCOUNT] User Data:",this.userData) }

        // If we have a plan, get the plan usage
        if (this.userData && this.user.data.billing && this.user.data.billing.selectedPlan) {
            var usage = await this.getPlanUsage();
            if (this.environment === "development") { console.log("[ACCOUNT] App Usage:",usage) }
        }
    },
    methods: {
        getUserData: async function() {

            // Get user data from Firebase, otherwise, return default
            return await firebase.getUser("email", this.user.data.email);

        },
        saveUserData: async function() {

            this.isSaving = true;

            var vm = this;
            var user = db.collection("users").doc(vm.user.data.id);
            var data = {
                name: this.userData.name || "",
                displayName: this.userData.displayName,
                email: this.userData.email
            }

            return user.update(data)
            .then(function() {
              // vm.shopifyStoreName = vm.activeUser.auth.shopify.id; 
              if (vm.environment === "development") { console.log("[ACCOUNT] Successfully updated user:",data) }
              vm.isSaved = true;
              vm.isSaving = false;
            })
            .catch(function(error) {
              console.error("[ACCOUNT] Error updating user:",error);
            })
        },
        signOutUser: function() {
            var vm = this;
            // const auth = getAuth();
            auth.signOut().then(() => {
              // Sign-out successful.
              if (vm.environment === "development") { console.log("[ACCOUNT] Signout Successful") }

              console.log("[ACCOUNT] emit signIn:",false)
              vm.$emit("signedIn",false);
              vm.$router.replace("/");
            }).catch((error) => {
              // An error happened.
              if (vm.environment === "development") { console.log("[ACCOUNT] Error signing out user:",error); }
            });
        },
        resetPassword: function() {
            var vm = this;
            vm.passwordResetLoading = true;
            firebase.auth().sendPasswordResetEmail(this.user.data.email)
              .then(() => {
                // Password reset email sent!
                // ..
                console.log(`[resetPassword] sent password reset email to: ${vm.user.data.email}`)
                vm.passwordResetLoading = true;
                vm.passwordReset = true;
              })
              .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ..
                if (vm.environment === "development") { console.log(`[ACCOUNT] Error resetting password: ${errorCode} - ${errorMessage}`) }
            });
        },
        planUsage: function() {
            return (this.user.data.entryCount/this.user.data.billing.selectedPlan.maxEntries)*100;
        },
        progress: function() {
            
            if (this.planUsage() >= 100) {
                return "100%"
            } else {
                return this.planUsage()+"%";
            }
        },
        getPlanUsage: async function() {

            var activeSubscription = await shopifyBilling({
                method: "getPlanUsage",
                userId: this.user.data.id,
                plan: this.user.data.billing.selectedPlan,
                appSubscription: this.user.data.billing.appSubscription
            })

            return this.user.data.billing.appSubscription ? activeSubscription : null;
        }

    },
    watch: {
        "isSaved": {
          handler(value) {
            this.$emit("isSaved",value);
          }
        },
        userData: {
            handler: function(oldValue, newValue) {
                this.isSaved = !newValue.id ? true : false;
            },
            deep: true
        }
    }
};
</script>