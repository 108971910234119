<template>
    <div id="register" class="content-wrapper flex flex-align-center">
        <div>
            <div class="header">
                <div class="primary-heading">Create an account</div>
            </div>
            <div class="body">
                <div class="width-400">
                    <div class="card-content">
                        <div v-if="error" class="alert alert-danger">{{error}}</div>
                        <form action="#" @submit.prevent="submit" class="form">
                            <div class="card-row">
                                <div v-bind:class="[ 'input-group', { hasData: form.email }, 'full-width margin-bottom-10']">
                                    <label for="email" class="">Email</label>
                                    <input
                                      id="email"
                                      type="email"
                                      class=""
                                      name="email"
                                      value
                                      required
                                      autofocus
                                      placeholder="Email"
                                      v-model="form.email"
                                    />
                                </div>
                            </div>
                            <div class="card-row">
                                <div v-bind:class="[ 'input-group', { hasData: form.password }, 'full-width margin-bottom-10']">
                                    <label for="password" class="">Password</label>
                                    <input
                                      id="password"
                                      type="password"
                                      class=""
                                      name="password"
                                      value
                                      required
                                      autofocus
                                      placeholder="Password"
                                      v-model="form.password"
                                    />
                                </div>
                            </div>
                            <div class="card-row">
                                <div v-bind:class="[ 'input-group', { hasData: form.name }, 'full-width']">
                                    <label for="name" class="">Company</label>
                                    <input
                                      id="name"
                                      type="name"
                                      class=""
                                      name="name"
                                      value
                                      required
                                      autofocus
                                      placeholder="Company name"
                                      v-model="form.name"
                                    />
                                </div>
                            </div>
                            <div class="card-row">
                                <div class="form-actions full-width">
                                    <button type="submit" class="button primary full-width">Register</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="margin-top-20">
                    <div class="text-sm">Already have an account? <a v-on:click="$router.push({ name: 'Login' })">Log in here</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    #app #Register.app-content {
        height: 100%;
        margin-top: 0;

        .content-wrapper {
            .header {
                margin-bottom: 30px;
            }
        }
    }
</style>

<script>
    import { auth, db } from "../firebase";

    export default {
        data: function () {
            return {
                form: {
                    name: "",
                    email: "",
                    password: ""
                },
                error: null
            }
        },
        methods: {
            submit: function() {
                var vm = this;

                auth.createUserWithEmailAndPassword(this.form.email, this.form.password)
                    .then(data => {
                        data.user
                            .updateProfile({
                              displayName: this.form.name
                            })
                            .then((response) => {
                                console.log("New User Registered:",response);

                                db.collection("users").add({
                                  email: this.form.email,
                                  displayName: this.form.name,
                                  defaultSurveyOptions: [
                                    { name: "Instagram", order: 0 },
                                    { name: "Google", order: 1 },
                                    { name: "Facebook", order: 2 },
                                    { name: "YouTube", order: 3 },
                                    { name: "TikTok", order: 4 },
                                    { name: "Friends/Family", order: 5 }
                                  ],
                                  integrations: [],
                                  onboardingSteps: require("@/assets/data-onboardingSteps.json")
                                })
                                .then(function(docRef) {
                                    console.log("Document written with ID: ", docRef.id);

                                    vm.$router.push({ name: 'Welcome' });

                                })
                                .catch(function(error) {
                                    console.error("Error adding document: ", error);
                                });

                            });
                    })
                    .catch(err => {
                        this.error = err.message;
                    });
            }
        }
    };
</script>