<template>
    <div class="content-wrapper">
        <!-- Page Title -->
        <div class="title">
            Reports
        </div>
        <div class="header">
            <div class="primary-heading margin-bottom-20">Analyze your data</div>
            <div class="text-sm">View the results of your attribution survey. Looking to export your data? View your <router-link :to="{ name: 'Database' }">Customer Database.</router-link></div>
        </div>
        <div class="body">
            <div class="controls flex">
                <div class="input-group margin-right-15 date">
                    <label>Period Start</label>
                    <input type="date" id="periodStart" v-model="filters.periodStart"/>
                </div>
                <div class="input-group margin-right-15 date">
                    <label>Period End</label>
                    <input type="date" id="periodEnd" v-model="filters.periodEnd"/>
                </div>
                <div class="input-group multi-select width-300">
                    <label>Attribution Channels</label>
                    <!-- <v-select multiple :options="surveyOptions" label="name" v-model="selectedChannels"></v-select> -->
                    <multiselect v-model="filters.selectedChannels" :options="surveyOptions" track-by="name" label="name" :multiple="true" :close-on-select="false" :clear-on-select="false" :searchable="false">
                        <template slot="selection" slot-scope="{ values }"><span class="multiselect__single" v-if="values.length">{{ values.length }} / {{ surveyOptions.length }} selected</span></template>
                    </multiselect>
                </div>
                <div class="spacer"></div>
                <div @click="resetFilters()" class="button refresh flex flex-v-center unformatted">
                    <div class="icon icon-refresh-cw-icon margin-right-10"></div>
                    <div>Reset filters</div>
                </div>
            </div>

            <div id="reports" class="body margin-top-10">

                <!-- Reports -->
                <div class="column background-white border-1 border-radius-4">
                    
                    <div class="report-nav flex tabs">
                        <div v-for="report in reports" v-bind:key="`nav-${report.id}`" class="tab width-240" :class="{ active: report.id === activeReport }" @click="activeReport = report.id">
                            {{ report.label }}
                        </div>
                        <div class="spacer"></div>
                        <div class="secondary-tabs">
                            <div @click="reportView = 'table'" class="icon icon-data-table-icon margin-right-10" :class="{ active: reportView === 'table' }"></div>
                            <div @click="reportView = 'chart'" class="icon icon-bar-chart-2-icon margin-right-10" :class="{ active: reportView === 'chart' }"></div>
                            <div @click="reportView = 'pie'" class="icon icon-pie-chart-icon" :class="{ active: reportView === 'pie' }"></div>
                        </div>
                    </div>


                    <div class="report" v-for="report in reports" v-bind:key="report.id">

                        <!-- Tables -->
                        <div :id="`report-${report.id}`" v-if="activeReport === report.id && reportView === 'table'" class="report-table padding-40">
                            <div class="table">
                                <div class="table-header">
                                    <div class="table-heading margin-bottom-10" v-for="header in report.headers" v-bind:key="header">{{ header }}</div>
                                </div>
                                <div class="table-row margin-bottom-10" v-for="item in report.chartData" v-bind:key="item.label">
                                    <div class="table-item">{{ item.label }}</div>
                                    <div v-if="report.id === 'revenue'" class="table-item">{{ numberWithCommas(item.totalValue.toFixed(2)) }}</div>
                                    <div v-if="report.id === 'customers'" class="table-item">{{ item.totalCount.toFixed(0) }}</div>
                                    <div v-if="report.id === 'revenue'" class="table-item">{{ ((item.totalValue / report.total)*100).toFixed(2)+"%" }}</div>
                                    <div v-if="report.id === 'customers'" class="table-item">{{ ((item.totalCount / report.total)*100).toFixed(2)+"%" }}</div>
                                </div>
                                <div class="total table-row margin-bottom-10">
                                    <div class="table-item">Total</div>
                                    <div v-if="report.id === 'customers'" class="table-item">{{ numberWithCommas(report.total.toFixed(0)) }}</div>
                                    <div v-if="report.id === 'revenue'" class="table-item">{{ numberWithCommas(report.total.toFixed(2)) }}</div>
                                    <div class="table-item">100%</div>
                                </div>
                            </div>
                        </div>

                        <!-- Charts: Bar -->
                        <div v-if="activeReport === report.id && reportView === 'chart'" class="report-chart margin-top-20 padding-40">
                            <div class="chart bar">
                                <CustomBarChart :report="report" v-if="report.chartData"></CustomBarChart>
                            </div>
                        </div>

                        <!-- Charts: Pie -->
                        <div v-if="activeReport === report.id && reportView === 'pie'" class="report-chart margin-top-20 padding-40">
                            <div class="chart pie">
                                <CustomPieChart :report="report" v-if="report.chartData"></CustomPieChart>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase";
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import CustomBarChart from '@/components/charts/BarChart'
import CustomPieChart from '@/components/charts/PieChart'
import NProgress from 'nprogress'
import ColorScheme from 'color-scheme'

var dateToday = (new Date(Date.now()));

export default {
    name: "Reports",
    components: {
        Multiselect,
        CustomBarChart,
        CustomPieChart
    },
    data: function() {
        return {
            isLoaded: false,
            filters: {
                periodStart: moment(dateToday).subtract(30,"days").format("YYYY-MM-DD"),
                periodEnd: moment(dateToday).add(1,"days").format("YYYY-MM-DD"),
                selectedChannels: [],
            },
            defaultFilters: {},
            periodStart: moment(dateToday).subtract(30,"days").format("YYYY-MM-DD"),
            periodEnd: moment(dateToday).add(1,"days").format("YYYY-MM-DD"),
            selectedChannels: [],
            surveyChannels: [],
            surveyOptions: [],
            surveyEntries: [],
            surveyData: [],
            filteredSurveyData: [],
            totalCount: 0,
            totalValue: 0,
            activeReport: "customers",
            reportView: "chart",
            reportTypes: [ "table", "chart" ],
            reports: [
                {
                    id: "customers",
                    label: "Customers",
                    headers: [ "Channel", "# of Customers", "% of Total" ]
                },
                {
                    id: "revenue",
                    label: "Revenue",
                    headers: [ "Channel", "Revenue ($)", "% of Total" ],
                }
            ],
            activeView: {
                topic: "customer",
                type: ""
            },
            chartOptions: {}
        }
    },
    computed: {
        ...mapGetters({
          // map `this.user` to `this.$store.getters.user`
          user: "user"
        }),
    },
    created: async function() {
        NProgress.start();
        await this.getUserData();
        await this.initSurveyData();

        this.defaultFilters = JSON.parse(JSON.stringify(this.filters));
        this.isLoaded = true;
        NProgress.done();
        // console.log(this.surveyEntries);
    },
    methods: {
        getUserData: async function() {

            var vm = this;

            // Get user data from Firebase, otherwise, return default
            var user = (await db.collection("users").doc(this.user.data.id).get()).data();
            var surveyOptions = user.surveyOptions;
            var surveyFields = user.surveyFields;

            var surveyEntries = (await db.collection("users").doc(this.user.data.id).collection("surveyEntries").get());

            surveyEntries.forEach(doc => {
                var data = doc.data();
                data.id = doc.id;
                vm.surveyEntries.push(data);
            })

            // console.log("user:",user);
            // console.log("surveyEntries:",vm.surveyEntries);

            this.surveyOptions = surveyOptions ? surveyOptions : this.user.data.defaultSurveyOptions;
            this.surveyFields = surveyFields ? surveyFields : this.surveyFields;
            this.filters.selectedChannels = this.surveyOptions;

        },
        initSurveyData: function() {

            var surveyData = [];
            var vm = this;
            var surveyEntries = vm.surveyEntries.filter(x => {
                if (x.order.created_at >= vm.filters.periodStart && x.order.created_at <= vm.filters.periodEnd) {
                    return x;
                }
            })

            // Create Survey Data
            for (var i in surveyEntries) {

                var newChannel = {};
                var entry = surveyEntries[i];
                var created_at = moment(entry.order.created_at).format("YYYY-MM-DD");
                var channelIndex = surveyData.findIndex(x => x.name === entry.referralOption);
                
                // console.log("entry:",entry)
                // console.log("channel:",surveyData[channelIndex]);

                // Check if entry is in current selection
                if (this.filters.selectedChannels.findIndex(x => x.name === entry.referralOption) > -1) {
                    if (channelIndex > -1) {

                        var dateIndex = surveyData[channelIndex].data.findIndex(x => x.date === created_at);

                        if (dateIndex > -1) {
                            surveyData[channelIndex].data[dateIndex].value += Number(entry.order.total_price);
                            surveyData[channelIndex].data[dateIndex].count += 1;
                        } else {
                            surveyData[channelIndex].data.push({
                                date: created_at,
                                value: Number(entry.order.total_price),
                                count: 1
                            })
                        }
                        
                    } else {
                        newChannel = {
                            name: entry.referralOption,
                            data: [{
                                date: created_at,
                                value: Number(entry.order.total_price),
                                count: 1
                            }]
                        }
                        surveyData.push(newChannel);
                    }
                } else {
                    
                    if (channelIndex > -1) {
                        surveyData.splice(channelIndex,1);
                    }

                }

            }

            // console.log("new surveyData",surveyData);

            // Total Line
            this.totalCount = 0;
            this.totalValue = 0;

            for (var k in surveyData) {
                this.totalCount += surveyData[k].count;
                this.totalValue += Number(surveyData[k].value);
            }

            this.surveyData = surveyData;
            this.filteredSurveyData = surveyData;
            this.updateCharts();

        },
        resetFilters: function() {
            this.filters = JSON.parse(JSON.stringify(this.defaultFilters));
        },
        updateCharts: function() {

            var scheme = new ColorScheme;
            scheme.from_hue(0)         // Start the scheme 
                  .scheme('analogic')     // Use the 'triade' scheme, that is, colors
                  .distance(1)
                  .variation('default');   // Use the 'soft' color variation

            var backgroundColors = (scheme.colors()).map(color => "#"+color);

            // console.log("backgroundColors:",backgroundColors)

            // Create Charts
            var reports = this.reports.map((report) => {
                if (report.id === "customers") { 
                    report.chartData = this.filteredSurveyData.map((record, index) => {
                        var totalCount = 0;
                        var obj = {
                            type: "bar",
                            label: record.name,
                            data: record.data.map(data => { 
                                totalCount += data.count;
                                return {
                                    x: data.date,
                                    y: data.count
                                } 
                            }),
                            backgroundColor: backgroundColors[index],
                            borderColor: backgroundColors[index]
                        }
                        obj.totalCount = totalCount;
                        return obj;
                    })

                    // Create the totals for the report
                    report.total = 0;
                    report.chartData.forEach((item) => {
                        report.total += item.totalCount;
                    })
                }
                if (report.id === "revenue") { 
                    report.chartData = this.filteredSurveyData.map((record, index) => {
                        var totalValue = 0;
                        var obj = {
                            type: "bar",
                            label: record.name,
                            data: record.data.map(data => { 
                                totalValue += data.value;
                                return {
                                    x: data.date,
                                    y: data.value
                                } 
                            }),
                            backgroundColor: backgroundColors[index],
                            borderColor: backgroundColors[index]
                        }
                        obj.totalValue = totalValue;
                        return obj;
                    })

                    // Create the totals for the report
                    report.total = 0;
                    report.chartData.forEach((item) => {
                        report.total += item.totalValue;
                    })
                }
                report.startDate = this.filters.periodStart;
                report.endDate = this.filters.periodEnd;
                return report;
            })

            this.reports = JSON.parse(JSON.stringify(reports));

            console.log("[Reports] reports:",this.reports);

        }
    },
    watch: {
        'filters': {
            handler() {
                this.initSurveyData();
            },
            deep: true
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>