<template>
    <div class="content-wrapper">
        <!-- Header -->
        <div class="header flex flex-align-center">
            <div class="icon icon-arrow-left-icon margin-right-15 text-lg clickable" @click="$router.back()"></div>
            <div class="primary-heading">Bulk Editor</div>
        </div>
        <div class="body full-height">
            <div class="full-width overflow-hidden padding-0 full-height">
                <!-- Table -->
                <div class="table-wrapper flex bulk-editor">

                    <div v-if="!isLoading" class="table" :class="{ editing: isEditing }">

                        <div class="table-header">
                            <div class="table-item" v-on:click="sortEntries('created_at')"><span>Order Date</span></div>
                            <div class="table-item" v-on:click="sortEntries('order_name')"><span>Order Number</span></div>
                            <div class="table-item" v-on:click="sortEntries('order_email')"><span>Email</span></div>
                            <div class="table-item" v-on:click="sortEntries('customer_name')"><span>Name</span></div>
                            <div class="table-item" v-on:click="sortEntries('referralOption')"><span>Referred By</span></div>
                            <div class="table-item"><span>Referred By: Detail</span></div>

                            <!-- Additional Fields -->
                            <div class="table-item" v-for="label in additionalFieldLabels" v-bind:key="`${label}-label`" v-html="label"></div>

                            <!-- Price -->
                            <div class="table-item" v-on:click="sortEntries('total_price')"><span>Order Total</span></div>
                        </div>
                        <div class="table-row" v-for="entry in entries" v-bind:key="entry.id" v-bind:class="[{ selected: entry.isSelected }]">
                            <!-- <input type="date" class="table-item" v-model="entry.created_at"/> -->
                            <div class="table-item" v-html="formatDate(entry.created_at,'YYYY-MM-DD')"></div>
                            <a class="table-item link" v-html="entry.order_name" target="_blank" :href="entry.order.order_status_url"></a>
                            <div class="table-item editable" style="min-width: 240px;"><input type="email" v-model="entry.order_email"/></div>
                            <div class="table-item editable"><input type="text" v-model="entry.customer_name"/></div>
                            <div class="table-item editable"><input type="text" v-model="entry.referralOption"/></div>
                            <div class="table-item editable"><input type="text" v-model="entry.writeInValue"/></div>

                            <!-- Additional Fields -->
                            <div class="table-item editable" v-for="label in additionalFieldLabels" v-bind:key="`${label}-label`">
                                <input v-for="field in entry.additionalFields" v-bind:key="`${field.id}-value`" :type="field.type" v-model="field.value"/>
                            </div>
                            

                            <!-- Price -->
                            <div class="table-item" v-html="Number(entry.total_price).toFixed(2)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Save Button -->
        <div class="saveButton" v-if="!isSaved" v-bind:class="{ unsaved: !isSaved }">
            <button v-if="!isSaved" class="button padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 margin-left-20" v-on:click="saveDatabase()">
              <span v-if="isSaving">Saving...</span>
              <span v-else>Save</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
/*#app #content {
    max-width: 100%;
}*/
.content-wrapper {
    max-width: initial !important;
}
.table {
    // height: 62vh;
}
.table-header {
    position: sticky;
    top: 0;
    z-index: 2;
}
@media (min-width: 1800px) {
    .table {
        display: table;
    }
}
</style>

<script>
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import firebase from "@firebase/app";

export default {
    name: "BulkEditor",
    data: function() {
        return {
            userData: {},
            entries: [],
            additionalFieldLabels: [],
            isSaved: true,
            isSaving: false,
            isEditing: false,
            isLoading: true,
        }
    },
    computed: {
        ...mapGetters({
          // map `this.user` to `this.$store.getters.user`
          user: "user"
        })
    },
    created: async function() {
        
        
        this.entryListIds = decodeURIComponent(this.$route.query.ids).split(",");
        console.log("[BulkEditor] entryListIds:",this.entryListIds);
        await this.getEntries();
        await this.getUserData();
        
        this.isSaved = true;
        this.isLoading = false;

    },
    methods: {
        getUserData: async function() {
            // Get user data from Firebase, otherwise, return default
            this.userData = (await firebase.getUser("email", this.user.data.email))[0];
            this.emailData = this.userData.emails ? this.userData.emails[0] : undefined;
            this.isSaved = true;
        },
        getEntries: async function() {

            var vm = this;

            console.log("[getEntries] user:",this.user.data)
            
            var entryListIds = this.entryListIds.map(x => Number(x));
            var entriesRef = await db.collection("users").doc(this.user.data.id).collection("surveyEntries");
            var filteredRecords = await entriesRef.where('order.id', 'in', entryListIds).get()
                .then(querySnapshot => {
                    
                    if (querySnapshot.empty) {
                        console.log("[getEntries] no entries found.")
                        return [];
                    } else {
                        console.log("querySnapshot:",querySnapshot.docs);
                        return querySnapshot.docs;
                    }
                    
                })
                .catch(err => {
                    console.log('[getEntries] Error getting document', err);
                });

            filteredRecords.forEach(doc => {

                var data = doc.data();

                data.id = doc.id;
                data.created_at = data.order.created_at;
                data.order_name = data.order_name ? data.order_name : (data.order ? data.order.name : null);
                data.order_email = data.order_email ? data.order_email : (data.order ? data.order.email : null);
                data.customer_name = data.customer_name ? data.customer_name : (data.order ? `${data.order.customer.first_name} ${data.order.customer.last_name}` : "");
                data.total_price = data.total_price ? data.total_price : (data.order ? data.order.total_price : null);
                data.isSelected = false;

                /* Setup additional fields */
                data.additionalFields = [];

                Object.entries(data).forEach(([key, value]) => {
                    if (key.indexOf("additionalField-") > -1) {
                        var fieldLabel = key.split("-")[1];
                        var field = {
                            id: fieldLabel,
                            value: value
                        };

                        if (fieldLabel === "birthday") { field.type = "date"; }

                        data.additionalFields.push(field);

                        // Push the field label to the table labels
                        if (vm.additionalFieldLabels.indexOf(fieldLabel) === -1) {
                            vm.additionalFieldLabels.push(fieldLabel);
                        }
                    }
                })

                vm.entries.push(data);

            })
            console.log("[getEntries] vm.entries:",vm.entries)
            this.isSaved = true;

        },
        batchUpdateEntries: async function(count) {

            var startingCount = count;
            var batch = db.batch();
            var entryRef, entry;
            var vm = this;

            for (var i = startingCount; i < this.entries.length; i++) {
                count++
                entry = this.entries[i];
                entryRef = db.collection("users").doc(this.user.data.id).collection("surveyEntries").doc(entry.id);
                batch.set(entryRef,entry);

                if (count > (6+startingCount)) {
                    break;
                }
            }

            await batch.commit().then(() => {
                vm.isSaving = false;
                // If we have more than 500 entries, save it all again!
                if (count > (6+startingCount)) { this.batchUpdateEntries(count); }
            });

            console.log("[batchUpdateEntries] complete");
            
        },
        saveDatabase: async function() {

            this.isSaving = true;
            await this.batchUpdateEntries(0);
            this.isSaved = true;
            this.isEditing = false;

        },
    },
    watch: {
      entries: {
        handler() {
            console.log("edited entries")
            if (!this.isLoading) { this.isSaved = false; }
        },
        deep: true
      },
      isSaved: {
        handler(value) {
          this.$emit("isSaved",value);
        }
      }
    }
};
</script>