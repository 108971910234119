<template>
    <div class="content-wrapper">
        <!-- Page Title -->
        <div class="title">
            Notifications
        </div>
        <!-- Header -->
        <div class="header">
            <div class="primary-heading margin-bottom-20">Notifications</div>
            <div class="text-sm">Get updates on your Attribute survey on a schedule that works for you.</div>
        </div>
        <!-- Body -->
        <div v-if="config" class="body padding-bottom-40">
            <!-- If we have a sender, configure notification -->
            <div v-if="userData" class="two-columns">

                <!-- Configuration -->
                <div id="configuration" class="column padding-right-40">

                    <!-- General Configuration -->
                    <!-- Is active -->
                    <div class="card-row flex flex-v-center margin-bottom-15">
                        <div class="flex-1 flex flex-v-center">
                            <h3 class="text-weight-md margin-bottom-0 margin-right-10">Configuration</h3>
                            <div class="pill" :class="[{ 'active': config.active }, { 'inactive': !config.active }]">
                            <span v-if="config.active">Active</span>
                            <span v-else>Inactive</span>
                            </div>
                        </div>
                        <div class="input-group switch flex flex-v-center margin-0-important">
                        <label class="switch" v-bind:class="{ inactive: !activePlan }">
                            <input type="checkbox" v-model="config.active" v-if="activePlan && config.recipients.length > 0">
                            <span class="slider round"></span>
                        </label>
                        </div>
                    </div>

                    <!-- Warning: Choose a plan -->
                    <div v-if="!activePlan && isLoaded" class="info-box warning margin-bottom-20 clickable">
                        <span class="icon icon-info-icon"></span>
                        <div>
                        <div class="text-md text-weight-med heading">
                            <span>Your email is currently inactive.</span>
                        </div>
                        <div class="body">Please <router-link :to="{ name: 'Plan' }">select a plan</router-link> to activate your email.</div>
                        </div>
                    </div>

                    <!-- Config: General -->
                    <h4 class="text-weight-md margin-bottom-15 margin-right-10">General</h4>
                    <div class="card">

                        <!-- Config: Title -->
                        <div class="card-row flex flex-v-center">
                            <div class="input-group full-width">
                                <label>Subject</label>
                                <input type="text" v-model="config.title" />
                            </div>
                        </div>

                        <!-- Config: Period Covered -->
                        <div v-if="config.period" class="card-row">
                            <div class="input-group">
                                <label>Period Covered</label>
                                <div class="flex flex-v-center">
                                    <div class="whitespace-nowrap">Cover the last:</div>
                                    <select class="margin-left-15 margin-right-15 flex-1"
                                        v-model="config.period.length">
                                        <option v-for="option in intervals[config.period.interval].options">{{ option }}
                                        </option>
                                    </select>
                                    <select v-model="config.period.interval" class="flex-1" style="margin: 0 0 0 0;">
                                        <option v-for="option in notificationIntervals" :key="option" :value="option">
                                            <span v-if="config.period.length > 1">{{ option }}s</span>
                                            <span v-else>{{ option }}</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Config: Recipients -->
                        <div class="input-group inline-list margin-none">
                            <label>Recipients</label>
                            <div class="email-input">
                                <div v-if="config.recipients.length > 0" class="pills flex flex-row-wrap margin-top-10 margin-bottom-5">
                                    <div v-for="(email, index) in config.recipients" v-bind:key="email"
                                        class="pill removable margin-bottom-10 margin-right-10 padding-10 flex flex-v-center">
                                        <span class="flex-1">{{ email }}</span>
                                        <div class="icon icon-x-icon margin-left-10" @click='removeEmail(index, config.recipients)'></div>
                                    </div>
                                </div>
                                <div v-else class="margin-top-10 margin-bottom-10 text-xs">Please add at least one recipient.</div>
                                <input id="testEmails" type="text" class="full-width" name="testEmails" value required
                                autofocus placeholder="Add email addresses + Enter" @keydown.enter="(event) => { addEmail(event, config.recipients) }"
                                @keydown.188="(event) => { addEmail(event, config.recipients) }" @keydown.delete="(event) => { removeLastEmail(event, config.recipients) }" />
                            </div>
                        </div>

                    </div>

                    <h4 class="text-weight-md margin-bottom-15 margin-right-10">Schedule</h4>
                    <div class="card">
                        <!-- Config: Frequency -->
                        <div class="card-row flex flex-v-center">
                            <div class="input-group full-width">
                                <label>Frequency</label>
                                <div class="flex flex-v-center full-width">
                                    <span class="whitespace-nowrap">Send every:</span>
                                    <select class="margin-left-15 margin-right-15 flex-1"
                                        v-model="config.schedule.frequency">
                                        <option v-for="option in intervals[config.period.interval].options">{{ option }}
                                        </option>
                                    </select>
                                    <select v-model="config.schedule.interval" class="flex-1">
                                        <option v-for="option in notificationIntervals" :key="option" :value="option">
                                            <span v-if="config.schedule.frequency > 1">{{ option }}s</span>
                                            <span v-else>{{ option }}</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Config: Send On -->
                        <div v-if="config.schedule.interval !== 'day'" class="card-row">
                            <div class="input-group full-width">
                                <label>Send on</label>
                                <select v-model="config.schedule.target" class="full-width">
                                    <option v-for="option in intervals[config.schedule.interval].targets" :key="option"
                                        :value="option">
                                        <span>{{ option }}</span>
                                        <span v-if="config.schedule.interval === 'month'">
                                            <span>{{ getTargetString(option) }} of the month</span>
                                        </span>
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- Config: Time & Timezone -->
                        <div class="card-row grid two-columns">
                            <div class="input-group flex-1">
                                <label>Time</label>
                                <div class="flex flex-v-center">
                                    <input class="margin-right-15" type="time" v-model="config.time"
                                        style="flex: 1 0 35%;" />
                                </div>

                            </div>
                            <div class="input-group flex-1">
                                <label>Zone</label>
                                <select v-model="config.timezone" class="max-width-100p">
                                    <option v-for="option in notificationTimezones" :key="option" :value="option">
                                        <span>{{ option }}</span>
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- Report Modules -->
                    <h4 class="text-weight-md margin-top-20 margin-bottom-15 margin-right-10">Report Modules</h4>
                    <div class="input-group draggable">
                        <draggable v-model="config.modules" draggable=".option" @change="setListOrder(config.modules)"
                            class="draggable-wrapper">
                            <!-- Config: Report Modules -->
                            <div v-for="(module, index) in config.modules"
                                class="option card-row flex flex-1 flex-align-center" :key="`config-item-${index}`">

                                <div v-if="!module.editing" class="dragger icon icon-menu-icon color-grey-20"></div>

                                <div
                                    :class="['padding-top-10 padding-bottom-10 flex-1', { 'padding-left-20': module.editing }]">
                                    <div v-if="module.editing" class="input-group multi-select"
                                        style="margin-bottom: 0;">

                                        <div class="text-weight-med heading-text margin-bottom-10 margin-top-10">Edit {{
                                            module.label
                                        }} Module</div>

                                        <!-- Module: Title -->
                                        <div v-if="module.type != 'scorecard'" class="input-group">
                                            <label>Chart Title</label>
                                            <input type="text" v-model="module.title" />
                                        </div>

                                        <!-- Module: Scorecard -->
                                        <div v-if="module.type === 'scorecard'">
                                            <label>Metrics</label>
                                            <multiselect v-model="module.metrics" class="full-width text-align-left"
                                                :options="availableMetrics" :close-on-select="false" :searchable="false"
                                                :multiple="true" :hide-selected="false">
                                                <template slot="option" slot-scope="{ option }">
                                                    <span>{{ option }}</span>
                                                </template>
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                </template>
                                                <template slot="selection" slot-scope="{ values }">
                                                    <span v-for="value in values" :key="value" class="chip">
                                                        {{ value }}
                                                    </span>
                                                    <!-- <span class="multiselect__single" v-if="values.length">{{ values.length }} / 3 metrics selected</span> -->
                                                </template>
                                            </multiselect>
                                        </div>

                                        <!-- Module: Bar -->
                                        <div v-if="module.type === 'bar'">
                                            <label>Metric</label>
                                            <multiselect v-model="module.x" class="full-width text-align-left"
                                                :options="module.availableMetrics" :close-on-select="false"
                                                :searchable="false" :multiple="false" :hide-selected="false"
                                                track-by="id" label="label" @input="selectMetrics(module)">
                                                <template slot="option" slot-scope="{ option }">
                                                    <span>{{ option.label }}</span>
                                                </template>
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option.label }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                </template>
                                                <template slot="selection" slot-scope="{ values }">
                                                    <span v-for="value in values" :key="value" class="chip">
                                                        {{ value }}
                                                    </span>
                                                    <!-- <span class="multiselect__single" v-if="values.length">{{ values.length }} / 3 metrics selected</span> -->
                                                </template>
                                            </multiselect>
                                        </div>

                                        <!-- Module: Pie -->
                                        <div v-if="module.type === 'pie'">
                                            <label>Metric</label>
                                            <multiselect v-model="module.x" class="full-width text-align-left"
                                                :options="module.availableMetrics" :close-on-select="false"
                                                :searchable="false" :multiple="false" :hide-selected="false"
                                                track-by="id" label="label" @input="selectMetrics(module)">
                                                <template slot="option" slot-scope="{ option }">
                                                    <span>{{ option.label }}</span>
                                                </template>
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                    <span class="custom__tag">
                                                        <span>{{ option.label }}</span>
                                                        <span class="custom__remove" @click="remove(option)">❌</span>
                                                    </span>
                                                </template>
                                                <template slot="selection" slot-scope="{ values }">
                                                    <span v-for="value in values" :key="value" class="chip">
                                                        {{ value }}
                                                    </span>
                                                    <!-- <span class="multiselect__single" v-if="values.length">{{ values.length }} / 3 metrics selected</span> -->
                                                </template>
                                            </multiselect>
                                        </div>

                                        <!-- Module: Stacked Bar -->
                                        <div v-if="module.type === 'stackedBar'">
                                            <div>
                                                <label>Metric</label>
                                                <multiselect v-model="module.x" class="full-width text-align-left"
                                                    :options="module.availableMetrics" :close-on-select="false"
                                                    :searchable="false" :multiple="false" :hide-selected="false"
                                                    track-by="id" label="label" @input="selectMetrics(module)">
                                                    <template slot="option" slot-scope="{ option }">
                                                        <span>{{ option.label }}</span>
                                                    </template>
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.label }}</span>
                                                            <span class="custom__remove"
                                                                @click="remove(option)">❌</span>
                                                        </span>
                                                    </template>
                                                    <template slot="selection" slot-scope="{ values }">
                                                        <span v-for="value in values" :key="value" class="chip">
                                                            {{ value }}
                                                        </span>
                                                        <!-- <span class="multiselect__single" v-if="values.length">{{ values.length }} / 3 metrics selected</span> -->
                                                    </template>
                                                </multiselect>
                                            </div>
                                            <div class="margin-top-15">
                                                <label>Group by</label>
                                                <multiselect v-model="module.y" class="full-width text-align-left"
                                                    :options="module.availableDimensions" :close-on-select="false"
                                                    :searchable="false" :multiple="false" :hide-selected="false"
                                                    track-by="id" label="label" @input="selectMetrics(module)">
                                                    <template slot="option" slot-scope="{ option }">
                                                        <span>{{ option.label }}</span>
                                                    </template>
                                                    <template slot="tag" slot-scope="{ option, remove }">
                                                        <span class="custom__tag">
                                                            <span>{{ option.label }}</span>
                                                            <span class="custom__remove"
                                                                @click="remove(option)">❌</span>
                                                        </span>
                                                    </template>
                                                    <template slot="selection" slot-scope="{ values }">
                                                        <span v-for="value in values" :key="value" class="chip">
                                                            {{ value }}
                                                        </span>
                                                        <!-- <span class="multiselect__single" v-if="values.length">{{ values.length }} / 3 metrics selected</span> -->
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>


                                    </div>
                                    <div v-else class="text-weight-med heading-text">
                                        {{ module.label }}
                                    </div>
                                </div>

                                <!-- Edit Module -->
                                <a v-if="!module.editing" class="edit-button margin-right-10"
                                    v-on:click="toggleEditModule(index, true)">Edit</a>
                                <!-- Remove Module -->
                                <a v-if="!module.editing" class="remove-button icon icon-trash-2-icon padding-10"
                                    v-on:click="removeModule(index)"></a>

                                <!-- Editing Actions -->
                                <div v-if="module.editing"
                                    class="actions flex flex-justify-end full-width flex-v-center padding-bottom-15 padding-top-10">
                                    <a class="unformatted margin-right-20 text-xs"
                                        v-on:click="toggleEditModule(index, false)">Cancel</a>
                                    <button class="button primary text-xs" v-on:click="saveModule(index)">Save</button>
                                </div>

                            </div>
                        </draggable>
                    </div>

                    <h4 class="text-weight-md margin-bottom-15 margin-right-10">Add a new Report</h4>
                    <div class="add-report-element">
                        <div class="text-sm margin-bottom-10">Choose from the list options below:</div>
                        <div class="input-group multi-select flex-1">
                            <multiselect v-model="newReportElement" class="full-width text-align-left"
                                :options="reportTypes" track-by="id" label="label" placeholder="Select an report type">
                                <template slot="option" slot-scope="{ option }">
                                    <span>{{ option.label }}</span>
                                </template>
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <span v-if="option.label">{{ option.label }}</span>
                                    <span v-else class="color-grey-40 text-sm">Select a report type</span>
                                </template>
                            </multiselect>
                        </div>
                        <button class="button primary" :class="{ inactive: !newReportElement }" @click="addModule()">Add
                            report</button>
                    </div>

                </div>

                <!-- Preview (must have userMetrics) -->
                <div id="preview" class="column padding-right-40 sticky" style="height: 100%;">

                    <div class="margin-bottom-20 flex flex-justify-space-between w-full">
                        <h3 class="text-weight-md margin-bottom-15 margin-right-10">Preview</h3>

                        <!-- Actions: Send Test Email -->
                        <div class="actions">
                            <button class="button primary" v-on:click="showModal('sendTest')"
                                v-bind:class="{ disabled: !activePlan }">
                                <span v-if="!sendingTest">Send test email</span>
                                <span v-else>Sending...</span>
                            </button>
                            <div v-if="!activePlan" class="margin-top-20">Please choose a plan to test your email.</div>
                        </div>
                    </div>

                    <div class="card background-alternate">

                        <!-- Preview Header -->
                        <div class="head margin-bottom-20">
                            <div><b>Subject:</b> <span>{{ config.title }}</span> </div>
                        </div>

                        <!-- Preview Body -->
                        <table class="attributeEmail preview full-width"
                               v-bind:style="`max-width: ${sender ? sender.emailSettings.maxWidth : 600}px; background: ${sender ? sender.emailSettings.background : 'white'};`">
                            <thead>
                                <tr v-if="sender && sender.emailSettings.logo.visible">
                                    <td class="logo-header"
                                        :style="`text-align: ${sender.emailSettings.logo.position}; background: ${sender.emailSettings.header.background};`">
                                        <img :src="sender.emailSettings.logo.url" class="logo"
                                            :style="`width: ${sender.emailSettings.logo.width}px`" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <h1 class="padding-top-10 padding-left-20 padding-right-20 margin-auto"
                                            v-html="config.title">
                                        </h1>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="text-align-center padding-top-10">
                                            {{ periodCaption }}
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <table border="0" cellspacing="15px" width="100%">
                                            <tbody>
                                                <tr>
                                                    <td v-if="sender && sender.emailSettings"
                                                        v-bind:style="`color: ${sender.emailSettings.textColor}`">
                                                    </td>
                                                </tr>
                                                <tr v-for="(module, index) in config.modules" class="notifications"
                                                    :key="`${module.id}`">
                                                    <td v-if="module.type === 'scorecard'" class="module-wrapper">
                                                        <Loading v-if="isLoading" imageClass="max-height-150"></Loading>
                                                        <Scorecard v-else :module="module" :userData="userData"
                                                            :limit="channelLimit"
                                                            :startDate="periodStart.format('YYYY-MM-DD')"
                                                            :userMetrics="userMetrics" />
                                                    </td>
                                                    <td v-else-if="module.type === 'bar'" class="module-wrapper">
                                                        <Loading v-if="isLoading" imageClass="max-height-150"></Loading>
                                                        <Bar v-else :module="module" :userData="userData"
                                                            :limit="channelLimit" :userMetrics="userMetrics" />
                                                    </td>
                                                    <td v-else-if="module.type === 'pie'"
                                                        class="module-wrapper text-align-center">
                                                        <Loading v-if="isLoading" imageClass="max-height-150"></Loading>
                                                        <Pie v-else :module="module" :userData="userData"
                                                            :limit="channelLimit" :userMetrics="userMetrics" />
                                                    </td>
                                                    <td v-else-if="module.type === 'stackedBar'"
                                                        class="module-wrapper text-align-center">
                                                        <Loading v-if="isLoading" imageClass="max-height-150"></Loading>
                                                        <StackedBar v-else :module="module" :userData="userData"
                                                            :limit="channelLimit" :userMetrics="userMetrics" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <!-- <tr>
                                    <td v-bind:style="`background: ${sender.emailSettings.footer.background}`"
                                        v-html="sender.emailSettings.footer.content"></td>
                                </tr> -->
                            </tfoot>
                        </table>


                    </div>
                </div>
            </div>
        </div>

        <!-- Save Button -->
        <div class="saveButton flex" v-bind:class="{ unsaved: !isSaved }">
            <button class="button full-width primary margin-right-15" v-on:click="saveUserData()"
                v-if="!isSaved">Save</button>
            <button class="button full-width primary inverse" v-on:click="discardChanges()"
                v-if="!isSaved">Cancel</button>
        </div>

        <!-- MODAL: Send Test Modal -->
        <Modal v-if="modals.sendTest.visible" @close="closeModal">

            <template v-slot:header>
                <div class="heading text-md text-weight-bold">Send a test email</div>
            </template>

            <template v-slot:body>
                <div class="wrapper width-400">
                    <div class="margin-bottom-15">Which emails would you like to send the test to?</div>
                    <div class="input-group inline-list margin-none">
                        <div class="email-input">
                            <div class="pills flex flex-row-wrap margin-bottom-10">
                                <div v-for="(email, index) in testEmails" v-bind:key="email"
                                    class="pill removable margin-bottom-10 margin-right-10 padding-10 flex flex-v-center">
                                    <span class="flex-1">{{ email }}</span>
                                    <div class="icon icon-x-icon margin-left-10" @click='removeEmail(index, testEmails)'></div>
                                </div>
                            </div>
                            <input id="testEmails" type="text" class="full-width" name="testEmails" value required
                                autofocus placeholder="Add email addresses + Enter" @keydown.enter="(event) => { addEmail(event, testEmails) }"
                                @keydown.188="(event) => { addEmail(event, testEmails) }" @keydown.delete="(event) => { removeLastEmail(event, testEmails) }" />
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <div class="actions">
                    <button class="button primary margin-none height-50" v-on:click="sendEmail()"
                        v-bind:class="{ 'disabled outline': testEmails.length < 1 || sentTest || sendingTest }">
                        <span v-if="sentTest">Sent!</span>
                        <span v-else-if="!sendingTest">Send test</span>
                        <span v-else class="flex flex-v-center full-height">
                            <Loading imageClass="max-height-100p full-height"></Loading>
                            <span>Sending...</span>
                        </span>
                    </button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>

import draggable from 'vuedraggable'
import firebase from 'firebase/app';
import { functions, db } from '@/firebase';
import { mapGetters } from "vuex";
// import Vue from 'vue'
import Scorecard from "@/components/modules/scorecard.vue";
import Bar from "@/components/modules/bar.vue";
import Pie from "@/components/modules/pie.vue";
import StackedBar from "@/components/modules/stackedBar.vue";
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import Multiselect from 'vue-multiselect';
import moment from 'moment-timezone';
import { parse } from 'node-html-parser';

// Set the notification that we're working with
// const notificationId = "digest";
const intervals = require("@/assets/config/intervals.json");
const getEntryMetrics = functions.httpsCallable("getEntryMetrics");
const sendEmail = functions.httpsCallable("sendEmail");
const getNotifications = functions.httpsCallable("getNotifications");

export default {
    name: "Notifications",
    data: function () {
        return {
            isSaved: true,
            isLoading: true,
            defaultNotifications: require("@/assets/data-defaultNotifications.json"),
            defaultNotificationsConfig: require("@/assets/data-defaultNotificationsConfig.json"),
            initialConfig: null,
            config: null,
            intervals: intervals,
            notifications: [],
            notificationsConfig: [],
            availableMetrics: [
                "Entries",
                "Revenue",
                "Average Order Value",
                "Response Rate",
            ],
            reportTypes: [],
            notificationId: this.$route.params.id,
            notificationIntervals: Object.keys(intervals),
            notificationTimezones: [],
            channelLimit: 5,
            newReportElement: null,
            sender: null,
            userData: null,
            userMetrics: null,
            modals: {
                sendTest: { visible: false }
            },
            sendingTest: false,
            sentTest: false,
            testEmails: []
        }
    },
    components: {
        Scorecard,
        Bar,
        Pie,
        StackedBar,
        Multiselect,
        draggable,
        Modal,
        Loading
    },
    computed: {
        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            user: "user"
        }),
        notification: function () {
            return this.notifications.filter(x => x.id === this.notificationId)[0];
        },
        frequencyCaption: function () {
            var caption = "";

            if (this.notification.interval === "day" && this.notification.frequency === 1) {
                caption = "Daily";
            } else if (this.notification.interval === "day" && this.notification.frequency === 7) {
                caption = "Weekly";
            } else if (this.notification.interval === "month") {
                caption = "Monthly";
            }

            return caption;
        },
        periodStart: function () {

            var interval = this.config.period.interval,
                length = this.config.period.length;

            return moment().subtract(length, interval);
        },
        periodEnd: function () {
            return moment().subtract(1, 'day');
        },
        periodCaption: function () {
            return `${this.periodStart.format("MMM DD, YYYY")} --> ${this.periodEnd.format("MMM DD, YYYY")}`;
        },
        notificationTitle: function () {
            return `${this.userData.displayName} ${this.frequencyCaption} Digest`;
        }
    },
    created: async function () {
        // location.reload();

        console.log("notificationID:",this.notificationId)

        await this.getTimezones(); // Get timezones for the time config
        await this.getUserData(); // Get user data
        await this.prepareConfig(); // Prepare configuration and make sure we're not missing any new attributes

        // Check if we have an active plan or recipients
        if (!this.activePlan || this.config.recipients.length === 0) {
            this.config.active = false; 
            this.config.recipients = [ this.user.data.email ];
        }

        await this.saveUserData();

        // console.log("userMetrics:", this.userMetrics);

        this.initialConfig = JSON.stringify(this.config); // Set the intial config
        this.isSaved = true;
        this.isLoading = false;
    },
    methods: {
        prepareConfig: async function () {
            // Loop through the notification config keys to make sure new attributes are included
            this.config = this.notificationsConfig.length == 1 ? this.notificationsConfig.filter(x => x.id === this.notificationId)[0] : this.notificationsConfig;
            const defaultConfig = this.defaultNotificationsConfig.filter(x => x.id == this.notificationId)[0];

            for (const [key, value] of Object.entries(defaultConfig)) {
                if (!this.config[key]) {
                    this.config[key] = value;
                }
            }

            // Loop through the user modules to make sure that any new attributes are included
            defaultConfig.modules.forEach((defaultModule) => {
                this.config.modules.forEach((module) => {
                    for (const [key, value] of Object.entries(defaultModule)) {
                        if (module.type == defaultModule.type && !module[key]) {
                            module[key] = value;
                        }
                    }
                })
            })

        },
        getUserMetrics: async function () {

            var config = {
                userId: this.userData.id,
                start_date: this.periodStart.format("YYYY-MM-DD"),
                end_date: this.periodEnd.format("YYYY-MM-DD")
            }

            // console.log("[getUserMetrics] config:",config)

            // Get the metrics
            this.userMetrics = (await getEntryMetrics(config)).data;

        },
        getUserData: async function () {

            this.userData = (await firebase.getUser("email", this.user.data.email))[0];
            // console.log("[getUserData] sender:", this.userData);

            // Set the notifications to the user's saved config, otherwise use the default
            this.notifications = this.userData.notifications || this.defaultNotifications;
            this.notificationsConfig = this.userData.notificationsConfig || this.defaultNotificationsConfig;
            this.reportTypes = this.defaultNotificationsConfig[0].modules;

            if (this.userData.sender) { this.sender = this.userData.sender }

        },
        saveUserData: async function () {
            // console.log("save survey options:",this.surveyOptions);
            this.isLoading = true;
            this.config.modules = this.config.modules.map(x => { x.editing = false; return x; });
            this.config.active = this.config.recipients.length === 0 ? false : this.config.active;

            var vm = this;
            var user = db.collection("users").doc(vm.user.data.id);

            console.log("saveuserdata:", vm.config.modules)
            var data = {
                notificationsConfig: [vm.config]
            }

            // Save the user's config
            await user.update(data)
                .then(function () {
                    // vm.shopifyStoreName = vm.activeUser.auth.shopify.id; 
                    // console.log("Successfully updated user:",data);
                    vm.isSaved = true;
                })
                .catch(function (error) {
                    console.error("[saveUserData] Error updating user:", error);
                })

            console.log("getUserMetrics")

            // Update the userMetrics
            await this.getUserMetrics();
            this.isLoading = false;

        },
        discardChanges: async function () {
            this.config = await JSON.parse(this.initialConfig);
            this.isSaved = true;
        },
        getPeriod: function () {
            // Get the period settings
            var interval = this.config.period.interval,
                length = this.config.period.length;

            // Create the date formats using moment
            this.config.period.end_date = moment().format("MMM DD, YYYY"),
                this.config.period.start_date = moment().subtract(length, interval).format("MMM DD, YYYY");

        },
        getTimezones: async function () {
            // console.log("[getTimezones]:",moment.tz.names())
            this.notificationTimezones = moment.tz.names();
        },
        getTargetString: function (option) {

            var str = String(option);
            var lastChar = str.charAt(str.length - 1);
            var targetString = "th";

            if (lastChar === "1") { targetString = "st" }
            if (lastChar === "2") { targetString = "nd" }
            if (lastChar === "3") { targetString = "rd" }

            return targetString;
        },
        setListOrder: function (list) {

            list = list.map((x, index) => { x.order = index; return x; })
            // console.log("list",list);
            this.isSaved = false;
            return list;
        },
        selectMetrics: function (module) {
            console.log("[selectMetrics] module:", module)
        },
        addModule: function () {

            var reportModule = JSON.parse(JSON.stringify(this.newReportElement));
            reportModule.id = `report-${(this.config.modules.length + 1)}`;

            this.config.modules.push(reportModule)
            this.newReportElement = null;

        },
        toggleEditModule: function (index, state) {
            console.log("[toggleEditModule]", this.config.modules[index])
            this.config.modules[index].editing = state;
        },
        saveModule: function (index) {
            this.toggleEditModule(index, false);
            this.config.modules = JSON.parse(JSON.stringify(this.config.modules));
            this.saveUserData();
        },
        removeModule: function (index) {

            var confirmed = confirm("This will remove the report module from your notification - are you sure?");

            if (confirmed) {
                this.config.modules.splice(index, 1);
            }


        },
        /* Modals */
        showModal: function (modal) {
            this.modals[modal].visible = true;
        },
        closeModal: function () {
            this.modals.sendTest.visible = false;
            this.sentTest = false;
        },
        /* Emails */
        sendEmail: async function () {

            this.sendingTest = true;
            await getNotifications({
                userIds: [this.user.data.id],
                recipients: this.testEmails
            }).then((response) => {
                return response.data;
            }).catch((error) => {
                console.log("error getting notification emails:",error)
            });

            this.sendingTest = false;


        },
        addEmail: function (event, list) {
            event.preventDefault();
            var val = event.target.value.trim()
            if (val.length > 0) {
                list.push(val);
                event.target.value = '';
            }
        },
        removeEmail: function (index, list) {
            list.splice(index, 1)
        },
        removeLastEmail: function (event, list) {
            if (event.target.value.length === 0) {
                this.removeTag(list.length - 1)
            }
        }
    },
    watch: {
        isSaved: {
            handler: async function (value) {
                this.$emit("isSaved", value);
            }
        },
        config: {
            handler: function (value) {
                if (JSON.stringify(value) != this.initialConfig) {
                    this.isSaved = false;
                }
            },
            deep: true
        },
        'config.active': {
            handler: function (newValue) {
                console.log("[watch] config.active newValue:",newValue);
            }
        },
        'config.period.interval': {
            handler: function (newValue) {
                // Monitor the changes to the interval and update period length if greater than allowed period
                var maxValue = this.intervals[newValue].options.reduce((a, b) => Math.max(a, b), -Infinity);
                if (this.config.period.length > maxValue) {
                    this.config.period.length = maxValue;
                }
            }
        },
        'config.schedule.interval': {
            handler: function (newValue) {
                // Monitor the changes to the interval and update period length if greater than allowed period
                var maxValue = this.intervals[newValue].options.reduce((a, b) => Math.max(a, b), -Infinity);
                if (this.config.schedule.frequency > maxValue) {
                    this.config.schedule.frequency = maxValue;
                }
            }
        }
    }
};
</script>