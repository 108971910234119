import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/main.scss'
import moment from 'moment'
import { Chart, registerables, TimeScale } from "chart.js";
import 'chartjs-adapter-moment';
import zoomPlugin from "chartjs-plugin-zoom";
import VComp from "@vue/composition-api";
import VueMeta from 'vue-meta'
import { db } from "@/firebase"
import numeral from 'numeral'

Chart.register(...registerables, zoomPlugin, TimeScale);

Vue.config.productionTip = false

Vue.use(VComp);
Vue.use(VueMeta);

Vue.mixin({
  data: function() {
    return {
      environment: process.env.NODE_ENV,
      storage: { key: 'form', days: 30},
      entries: [],
      entriesTotalCount: 0,
      entriesTotalRevenue: 0,
      additionalFieldLabels: []
    }
  },
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ]
  },
  computed: {
    entryCount: function() {
      return db.collection("users").doc(this.user.data.id).entryCount;
    },
    activePlan: function() {
      return !(!this.user.data.billing || (this.user.data.billing && this.user.data.billing.selectedPlan === null))
    }
  },
  methods: {
    moment: function() {
      return moment();
    },
    formatDate: function(dateString, format) {
      var dateFormat = format || "YYYY-MM-DD";
      return moment(dateString).format(dateFormat);
    },
    formatNumber: function(number, format) {
      return numeral(number).format(format);
    },
    sortList: function(list, key, direction) {
      var sortedList = list.sort(function(a, b) {
          if (direction === "asc") { return (a[key] > b[key]) ? 1 : -1 }
          else { return (a[key] < b[key]) ? 1 : -1  }
      })
      return sortedList;
    },
    onlyUnique: function(value, index, self) {
      return self.indexOf(value) === index;
    },
    getMaxOfArray: function(numArray) {
      return Math.max.apply(null, numArray);
    },
    validateEmail: function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    validateUrl: function(url) {
        const re = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/; // eslint-disable-line 
        return re.test(String(url).toLowerCase());
    },
    validateShopifyUrl: function(url) {
        if (url && (url.indexOf('http') > -1 || url.indexOf('//') > -1 || url.indexOf(':') > -1)) {
          return false;
        } else {
          const re = /[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; // eslint-disable-line 
          return re.test(String(url).toLowerCase());
        }
    },
    mreplace: function (replacements, str) {
      let result = str;

      for (let [x, y] of replacements) {
        result = result.replace(x, y);
      }
      return result;
    },
    numberWithCommas: function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setSavedData: function(key, value) {

      if (value) {

        value.timestamp = new Date().getTime();
        localStorage.setItem(key, JSON.stringify(value));  

      } else {
        if (key == undefined) { key = this.storage.key; }
        this[key].timestamp = new Date().getTime();

        if (this[key] !== "undefined" && this[key] !== undefined) {
          // console.log("Key:",this[key]);
            localStorage.setItem(key, JSON.stringify(this[key]));  
        } else {
          // console.log({"Error": `${key} is undefined`});
        }
      }

      
    },
    getSavedData: function(key) {
      if (key == undefined) { key = this.storage.key; }
      var data = localStorage.getItem(key);
      if (data !== "undefined" && data !== undefined && data !== null) {
        var jsonData = JSON.parse(data);
        var now = new Date().getTime();
        var max_timestamp = now - (this.storage.days * 24 * 60 * 60 * 1000);
        if (max_timestamp > jsonData.timestamp) {
          jsonData = null;
          this.removeSavedData();
        }
      }
      return jsonData;
    },
    removeSavedData: function(key) { if (key == undefined) { key = this.storage.key; localStorage.removeItem(key); } },
    restoreSavedData: function(key, value) { this[key] = value; },
    getEntries: async function() {

        var vm = this;
        var entriesRef = db.collection("users").doc(this.user.data.id).collection("surveyEntries");
        var lastVisible;
        var recordLimit = this.recordLimit ? this.recordLimit : 25;

        // Reset all values
        vm.entries = [];
        vm.entriesTotalCount = 0;
        vm.entriesTotalRevenue = 0;

        console.log("[getEntries] recordLimit:",this.recordLimit);

        var records = await entriesRef.orderBy("order.created_at", "desc").limit(recordLimit).get().then((documentSnapshots) => {
          lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
          return documentSnapshots;
        })

        records.forEach(doc => {

            var data = doc.data();

            data.id = doc.id;
            data.created_at = data.order.created_at;
            data.order_name = data.order_name ? data.order_name : (data.order ? data.order.name : null);
            data.order_email = data.order_email ? data.order_email : (data.order ? data.order.email : null);
            data.order_url = data.order_url ? data.order_url : (data.order ? data.order.order_status_url : null);
            data.customer_name = data.customer_name ? data.customer_name : (data.order ? `${data.order.customer.first_name} ${data.order.customer.last_name}` : "");
            data.first_name = data.first_name ? data.first_name : (data.order ? `${data.order.customer.first_name}` : "");
            data.last_name = data.last_name ? data.last_name : (data.order ? `${data.order.customer.last_name}` : "");
            data.total_price = data.total_price ? data.total_price : (data.order ? data.order.total_price : null);
            data.product_titles = data.order ? data.order.line_items.map(x => x.title).join(", ") : null;
            data.variant_skus = data.order ? data.order.line_items.map(x => x.sku).filter(x => x !== "").join(", ") : null;
            data.variant_ids = data.order ? data.order.line_items.map(x => x.variant_id).join(", ") : null;
            data.state = data.order && data.order.shipping_address ? data.order.shipping_address.province : null;
            data.country = data.order && data.order.shipping_address ? data.order.shipping_address.country : null;
            data.tags = data.order ? data.order.tags : null;

            data.isSelected = false;

            /* Setup additional fields */
            data.additionalFields = [];

            // console.log("data:",Object.entries(data));
            Object.entries(data).forEach(([key, value]) => {
                if (key.indexOf("additionalField-") > -1) {
                    var fieldLabel = key.split("-")[1];
                    data.additionalFields.push({
                        id: fieldLabel,
                        value: value
                    });

                    // Push the field label to the table labels
                    if (vm.additionalFieldLabels.indexOf(fieldLabel) === -1) {
                        vm.additionalFieldLabels.push(fieldLabel);
                    }
                }
            })

            // console.log("data:",data);

            vm.entries.push(data);
            vm.entriesTotalCount += 1;
            vm.entriesTotalRevenue += Number(data.total_price);

        })

        vm.next = await entriesRef.orderBy("order.created_at", "desc").startAfter(lastVisible).limit(recordLimit).get();
        this.filteredEntries = await this.sortList(this.entries,"created_at",this.sortDirection);

        this.isSaved = true;

    },
    getMetric: async function(metric) {
      console.log("[getMetric] metric:",metric);
    }
  }
})

new Vue({
  router,
  store,
  render: h => {
    return h(App)
  },
}).$mount('#app')