<template>
  <div class="content-wrapper">
    <!-- Header -->
    <div class="header flex flex-align-center">
      <div class="flex-1 margin-right-40">
        <div class="primary-heading margin-bottom-20">Request Email Queue</div>
        <div class="text-sm">Your email queue contains a list of customers who have not filled out your Attribute Survey. Activate your queue to start delivering <b>Request Emails</b> to your customers. Emails will be sent out at 2:00am CDT every day, so make sure to update your settings before then!</div>
      </div>
      <div>
        <div class="input-group switch flex flex-align-center margin-0">
          <label class="margin-right-10">
            <span v-if="queue.active">Active</span>
            <span v-else>Inactive</span>
          </label>
          <label class="switch">
            <input type="checkbox" v-model="queue.active">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <!-- Queue -->
    <div id="queue" class="body full-height">
      <div class="">
          <!-- Search -->
          <div class="search-wrapper flex flex-align-end margin-bottom-20 background-primary">
              <div class="input-group margin-bottom-0 flex-1">
                  <label>Search your queue</label>
                  <input type="search" class="search" v-model="searchPattern"/>
              </div>
              <!-- Pagination -->
              <div class="pagination input-group multi-select single text-align-right margin-left-15">
                  <label>Records per page</label>
                  <multiselect v-model="recordLimit" 
                      class="full-width text-align-left"
                      :options="recordLimitOptions"
                      :close-on-select="true"
                      :searchable="false"
                      :showLabels="false"
                      :allow-empty="false"
                      @input="getEntries()"
                      >
                      <!-- <template slot="singleLabel" slot-scope="option">{{ option }}</template> -->
                  </multiselect>
              </div>
          </div>
          <!-- Table -->
          <div v-if="queue.data.length > 0" class="table-wrapper flex">

            <!-- Edit/More Actions -->
            <div v-if="entrySelected" class="more-actions flex flex-v-center margin-left-20" v-bind:class="[{ open: moreActionsVisible }]">
                <div class="selected-count padding-left-20 margin-left-5">{{ selectedEntries.length }} selected</div>
                <div class="flex-1"></div>

                <div class="more-actions-wrapper" @mouseenter="showActions()" @mouseleave="hideActions()">
                    <button class="button more-actions-button flex flex-v-center padding-10 margin-right-10">
                        <span>More actions</span>
                        <div class="icon icon-dropdown-icon"></div>
                    </button>
                    <div class="more-actions-list" :class="{ hidden: !moreActionsVisible }">
                        <div class="list-item" v-if="requestEmail && requestEmail.active" v-on:click="showModal('sendEmails')">Re-send Email</div>
                        <div class="list-item text-color-danger text-weight-bold" v-on:click="deleteEntries()">Delete</div>
                    </div>
                </div>
            </div>

            <!-- Table -->
            <div class="table">

                <div id="queue">
                  <div class="table-header">

                      <!-- Select -->
                      <div class="table-item-select" :class="{ checked: entrySelected }">
                          <input type="checkbox" v-on:click="!allSelected ? selectAll() : deselectAll()" :checked="selectedEntries.length > 0"/>
                      </div>


                      <div class="table-item" v-bind:class="[{ sorted: sortedBy === 'created_at' }, sortDirection ]" v-on:click="sortEntries('created_at')"><span>Scheduled at</span></div>
                      <div class="table-item"><span>Status</span></div>
                      <div class="table-item" v-bind:class="[{ sorted: sortedBy === 'order_email' }, sortDirection ]" v-on:click="sortEntries('order_email')"><span>Email</span></div>
                      <div class="table-item" v-bind:class="[{ sorted: sortedBy === 'customer_name' }, sortDirection ]" v-on:click="sortEntries('customer_name')"><span>Name</span></div>
                      <div class="table-item">Actions</div>

                  </div>
                
                  <div class="table-row" v-for="email in queue.data" v-bind:key="email.to" v-bind:class="[{ selected: email.isSelected }]">

                      <!-- Select -->
                      <div class="table-item-select" :class="{ checked: email.isSelected }">
                          <input type="checkbox" v-on:click="selectEntry(email)" v-bind:checked="email.isSelected"/>
                      </div>

                      <div class="table-item tooltip">
                        <div v-if="email.rescheduled_at">
                          <span v-html="formatDate(email.rescheduled_at,'YYYY-MM-DD')"></span>
                          <div class="tooltip-content top small">
                            <span class="text-weight-med">Rescheduled from:</span> <span v-html="formatDate(email.scheduled_at,'YYYY-MM-DD')"></span>
                          </div>
                        </div>
                        <span v-else v-html="formatDate(email.scheduled_at,'YYYY-MM-DD')"></span>
                      </div>

                      <div class="table-item" v-html="email.status"></div>
                      <div class="table-item" v-html="email.to"></div>
                      <div class="table-item" v-html="email.order.customer_name"></div>
                      <div class="table-item actions">
                        <!-- @TODO - Add email preview -->
                        <!-- <button class="margin-right-10 button" @click="showModal('previewEmail'); activeEmail = email;">Preview</button> -->
                        <button v-if="!email.skipped" class="margin-right-10 button primary outline" @click="showModal('skipEmail'); activeEmail = email;" v-bind:class="{ 'disabled': email.sent }">Skip</button>
                        <button v-if="email.skipped" class="margin-right-10 button  primary outline" @click="showModal('skipEmail'); activeEmail = email;">Unskip</button>
                        <button class="margin-right-10 button primary outline" @click="showModal('rescheduleEmail'); activeEmail = email;">Reschedule</button>
                      </div>
                  </div>
                </div>
            </div>
          </div>

          <div v-else>
            <div class="info-box warning">
              There are no emails currently in the queue. Choose a plan to activate your queue.
            </div>
          </div>
      </div>
    </div>

    <!-- Modals -->
    <!-- MODAL: Send Test Modal -->
    <Modal v-if="modals.sendEmails.visible" @close="closeModal">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Re-send emails to your customers</div>
        </template>

        <template v-slot:body>
          <div class="wrapper max-width-450">
            <div class="margin-bottom-15">Please confirm that you would like to resend emails to the customers listed below.</div>
            <div class="input-group inline-list margin-none">
              <div class="email-input">
                <div class="pills flex flex-row-wrap">
                  <div v-for="(email, index) in selectedEmails" v-bind:key="`email-${index}`" class="pill removable margin-bottom-10 margin-right-10 padding-10 flex flex-v-center">
                      <span class="flex-1">{{ email }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
               <button class="button primary margin-none" v-on:click="sendEmail()" v-bind:class="{ 'disabled outline': selectedEmails.length < 1 || sentEmails }">
                 <span v-if="!sendingEmails && !sentEmails">Confirm & Send</span>
                 <span v-else-if="sentEmails">Sent!</span>
                 <span v-else>Sending...</span>
               </button>
            </div>
        </template>
    </Modal>

    <!-- Modal: Reschedule Email -->
    <Modal v-if="modals.rescheduleEmail.visible" @close="closeModal">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Reschedule Email</div>
        </template>

        <template v-slot:body>
          <div class="wrapper max-width-450">
            <div class="margin-bottom-20">What date would you like to reschedule the Request Email to? (The email will go out to the customer at 2:00am CDT)</div>
            <div class="info-table">
              <div class="table-row">
                <div class="table-header">Email</div>
                <div class="table-item"><span v-html="activeEmail.to"></span></div>
              </div>
              <div class="table-row">
                <div class="table-header">Scheduled at</div>
                <div class="table-item">
                  <span v-html="formatDate(activeEmail.scheduled_at,'YYYY-MM-DD')"></span>
                </div>
              </div>
            </div>
            <div class="input-group date margin-top-20">
              <input type="date" v-model="activeEmail.rescheduled_at" />
            </div>
            <div class="info-table single">
              <div class="table-row">
                <div class="table-header"><b>Reschedule to</b> </div>
                <div class="table-item">
                  <span v-if="activeEmail.rescheduled_at" span v-html="formatDate(activeEmail.rescheduled_at,'YYYY-MM-DD')"></span>
                  <span v-else>--</span>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions flex">
               <button class="button primary margin-none" v-on:click="rescheduleEmail()" v-bind:class="{ 'disabled outline': !activeEmail.rescheduled_at || isConfirmed }">
                 <span v-if="isConfirmed">Success!</span>
                 <span v-else-if="isConfirming">Rescheduling...</span>
                 <span v-else>Confirm</span>
               </button>
               <div class="flex-1"></div>
               <button v-if="activeEmail.rescheduled_at && !isConfirmed && !isConfirming" class="button secondary outline" v-on:click="resetEmailSchedule()" v-bind:class="{ 'disabled outline': isConfirmed }">
                 <span v-if="isConfirmed">Success!</span>
                 <span v-else>Clear Schedule</span>
               </button>
            </div>
        </template>
    </Modal>

    <!-- Modal: Reschedule Email -->
    <Modal v-if="modals.skipEmail.visible" @close="closeModal">

        <template v-slot:header>
            <div v-if="!activeEmail.skipped" class="heading text-md text-weight-bold">Skip Email</div>
            <div v-else class="heading text-md text-weight-bold">Unskip Email</div>
        </template>

        <template v-slot:body>
          <div class="wrapper max-width-300">
            <div v-if="!activeEmail.skipped" class="margin-bottom-10">Are you sure you would like to skip sending a request email to <span class="text-weight-med">{{ activeEmail.to }}</span>?</div>
            <div v-else class="margin-bottom-10">Are you sure you would like to unskip the email to <span class="text-weight-med">{{ activeEmail.to }}</span>?</div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
               <button v-if="!activeEmail.skipped" class="button primary margin-none" v-on:click="skipEmail()">
                 <span v-if="isConfirmed">Success!</span>
                 <span v-else-if="isConfirming">Skipping...</span>
                 <span v-else>Confirm</span>
               </button>
               <button v-else class="button primary margin-none" v-on:click="unskipEmail()">
                 <span v-if="isConfirmed">Success!</span>
                 <span v-else-if="isConfirming">Unskipping...</span>
                 <span v-else>Confirm</span>
               </button>
            </div>
        </template>
    </Modal>

    <!-- Save Button -->
    <div class="saveButton" v-bind:class="{ unsaved: !isSaved }">
        <button v-if="!isSaved" class="button parimary padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 margin-left-20" v-on:click="saveUserData()">
          <span v-if="isSaving">Saving...</span>
          <span v-else>Save</span>
        </button>
    </div>
  </div>
</template>

<style>
#queue .table,
#queue .table .table-row {
  grid-template-columns: 150px 250px 200px 1fr;
}
#queue .table .table-row:last-child .table-item, 
#queue .table .table-row:last-child .table-item-select {
  border-bottom:  none;
}
#queue .table .table-header {
  grid-template-columns: 151px 250px 200px 1fr;
}
#queue .table .table-item.tooltip .tooltip-content.top {
  bottom: 100%;
  padding: 5px 10px;
  border: none;
  left: 1%;
  right: 1%;
  margin: 0 auto;
}
.modal {
  max-width: 420px;
}
</style>

<script>
import { mapGetters } from "vuex"
import firebase from "@firebase/app";
import { db, functions } from "@/firebase"
import Fuse from "fuse.js"
import moment from "moment"
import Modal from '@/components/Modal.vue'
import Multiselect from 'vue-multiselect'
import NProgress from 'nprogress'

const sendEmail = functions.httpsCallable('sendEmail');
const createQueue = functions.httpsCallable('createQueueTest');

export default {
  name: "EmailQueue",
  data: function() {
    return {
      queue: {
        active: false,
        data: [],
        default: []
      },
      entries: [],
      entriesTotalCount: 0,
      userData: null,
      requestEmail: null,
      filteredEntries: [],
      searchPattern: "",
      sortDirection: "desc",
      sortedBy: "created_at",
      isSaved: true,
      isSaving: false,
      isConfirming: false,
      isConfirmed: false,
      isLoaded: false,
      entrySelected: false,
      allSelected: false,
      moreActionsVisible: false,
      activeEmail: {},
      sendingEmails: false,
      sentEmails: false,
      selectedEmails: [],
      recordLimit: 25,
      recordLimitOptions: [ 25, 50, 100 ],
      modals: {
          sendEmails: { visible: false },
          previewEmail: { visible: false },
          skipEmail: { visible: false },
          rescheduleEmail: { visible: false }
      }
    }
  },
  components: {
    Modal,
    Multiselect
  },
  computed: {
      ...mapGetters({
        // map `this.user` to `this.$store.getters.user`
        user: "user"
      }),
      selectedEntries: function() {
          return this.queue.data.filter(x => x.isSelected === true);
      }
  },
  created: async function() {
    NProgress.start();

    await this.getUserData();
    await this.getEntries();

    this.filteredEntries = this.sortList(this.entries,"created_at",this.sortDirection);

    await this.getQueue();
    await this.getEmailStatus();

    this.isSaved = true;
    this.isLoaded = true;

    console.log("[created] this.user.data:",this.userData)
    console.log("this.requestEmail:",this.requestEmail)
    NProgress.done();
  },
  methods: {
    getUserData: async function() {

        // Get user data from Firebase, otherwise, return default
        var user = await firebase.getUser("email", this.user.data.email);
        var emails = user[0].emails;

        if (emails.length > 0) {
          console.log("[getUserData] emails:",emails);
          emails.forEach(email => {
            if (email.id === "request-1") {
              console.log("[getUserData] request email:",email);
              this.email = emails ? emails[0] : this.email;
            }
          })
        }

        if (user) { 
          this.userData = user[0]; 
          this.requestEmail = (this.userData.emails.filter(x => x.id === 'request-1'))[0];
          this.queue.active = this.userData.queue ? this.userData.queue.active : false;
        }
        
    },
    saveUserData: function() {
        // Compile data to be saved
        var vm = this;
        var user = db.collection("users").doc(vm.user.data.id);
        var data = { 
            queue: {
              active: vm.queue.active,
              length: vm.queue.data.length
            }
        }

        return user.update(data)
        .then(function() {
          // console.log("[MAIN] Successfully updated user:",data);
          vm.isSaved = true;
        })
        .catch(function(error) {
          console.error("[DATABASE] Error updating user:",error);
        })
    },
    getQueue: async function() {

      var vm = this;
      var queueRef = db.collection("users").doc(this.user.data.id).collection("emailQueue");
      
      await queueRef.limit(25).get().then(async (querySnapshot) => {

        // If we don't have a queue setup, create a queue from the surveyEntries
        if (querySnapshot.docs.length === 0) {

          console.log("create a queue")

          await createQueue();

          console.log("newQueueRef:",vm.queue.data);
        } else {
          
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            vm.queue.data.push(data);
            vm.queue.default.push(data);
          })
          console.log("Already have an emailQueue:",vm.queue.data);
        }
      });



    },
    getEmailStatus: async function(activeEmail) {
      var today = moment();

      // If we're updating a specific email, just update that email
      if (activeEmail) {

        this.queue.data = this.queue.data.map((email) => {
          console.log("[getEmailStatus] email:",email);
          if (email.id === activeEmail.id) {
            email = activeEmail;
          }
          if (email.sent) {
            email.status = "Sent"
          } else if (email.skipped) {
            email.status = "Skipped"
          } else if (!email.sent && moment(email.rescheduled_at) > today) {
            email.status = "Rescheduled";
          } else if (!email.sent && moment(email.scheduled_at) > today) {
            email.status = "Scheduled"
          } else {
            email.status = "Never Sent"
          }
          return email;
        })

      } else {

        // Otherwise, update all email statuses
        this.queue.data = await this.queue.data.map((email) => {
          if (email.sent) {
            email.status = "Sent"
          } else if (email.skipped) {
            email.status = "Skipped"
          } else if (!email.sent && moment(email.rescheduled_at) > today) {
            email.status = "Rescheduled";
          } else if (!email.sent && moment(email.scheduled_at) > today) {
            email.status = "Scheduled"
          } else {
            email.status = "Never Sent"
          }
          return email;
        })

        console.log("this.queue.data:",this.queue.data);
      }
      
    },
    showActions: function() {
        this.moreActionsVisible = true;
    },
    hideActions: function() {
        this.moreActionsVisible = false;
    },
    deselectAll: function() {
        this.allSelected = false;
        this.entrySelected = false;
        this.queue.data = this.queue.data.map(x => { x.isSelected = false; return x;})
    },
    selectAll: function() {
        this.allSelected = true;
        this.entrySelected = true;
        this.queue.data = this.queue.data.map(x => { x.isSelected = true; return x;})
    },
    selectEntry: function(entry, isSelected) {
        
        var vm = this;
        if (!entry.isSelected) { isSelected = true; } else { isSelected = false; }
        vm.filteredEntries = vm.filteredEntries.map(x => { if (x.id === entry.id) { x.isSelected = isSelected; } return x; } )
        vm.entrySelected = false;

        vm.filteredEntries.forEach(function(item) {
            if (item.isSelected) {
                vm.entrySelected = true;
            }
        })
    },
    searchEntries: function(pattern) {
      const options = {
        keys: [
          "to",
          "order.name",
          "order.customer_name"
        ]
      };
      const fuse = new Fuse(this.queue.default, options);

      return fuse.search(pattern);
    },
    sendEmail: async function() {
        
        this.sendingEmails = true;

        for (var i in this.selectedEntries) {

            var entry = this.selectedEntries[i];

            console.log()

            await sendEmail({
              to: entry.to,
              senderName: this.userData.sender.name,
              from: this.userData.sender.from,
              subject: this.requestEmail.content.subject,
              bodyHtml: this.requestEmail.content.bodyHtml,
              customerName: entry.order.customer_name
            }).then(function(response) {
              console.log("Sent Email:",response);
              // vm.sendingTest = false;
            }).catch(function(err) {
              console.log("Error:",err);
            })

        }

        this.sendingEmails = false;
        this.sentEmails = true;
    },
    sortEntries: function(key) {
        this.sortedBy = key;
        if (this.sortDirection === "desc") { this.sortDirection = "asc"; } else { this.sortDirection = "desc"; }
        this.queue.data = this.sortList(this.queue.data, key, this.sortDirection);
    },
    // Email Actions
    rescheduleEmail: async function() {

      this.isConfirming = true;
      this.activeEmail.sent = false;
      var queueRef = db.collection("users").doc(this.user.data.id).collection("emailQueue");
      
      await queueRef.doc(this.activeEmail.id).update({
        "rescheduled_at": this.activeEmail.rescheduled_at,
        "sent": this.activeEmail.sent
      }).then(() => {
        this.isConfirming = false;
        this.isConfirmed = true;
        
        // console.log("[rescheduleEmail] Successfully rescheduled email");
      }).catch((err) => {
        console.log("[rescheduleEmail] error rescheduling email:",err);
      });

      await this.getEmailStatus(this.activeEmail);
    },
    resetEmailSchedule: async function() {
      this.isConfirming = true;
      this.activeEmail.rescheduled_at = null;
      var queueRef = db.collection("users").doc(this.user.data.id).collection("emailQueue");
      
      await queueRef.doc(this.activeEmail.id).update({
        "rescheduled_at": this.activeEmail.rescheduled_at
      }).then(() => {
        this.isConfirming = false;
        this.isConfirmed = true;
        
        // console.log("[rescheduleEmail] Successfully rescheduled email");
      }).catch((err) => {
        console.log("[rescheduleEmail] error rescheduling email:",err);
      });

      await this.getEmailStatus(this.activeEmail);
    },
    skipEmail: async function() {
      this.isConfirming = true;
      this.activeEmail.skipped = true;
      var queueRef = db.collection("users").doc(this.user.data.id).collection("emailQueue");

      await queueRef.doc(this.activeEmail.id).update({
        "skipped": this.activeEmail.skipped
      }).then(() => {
        this.isConfirming = false;
        this.isConfirmed = true;
        
        // console.log("[rescheduleEmail] Successfully skipped email");
      }).catch((err) => {
        console.log("[rescheduleEmail] error skipped email:",err);
      });

      await this.getEmailStatus(this.activeEmail);
    },
    unskipEmail: async function() {
      this.isConfirming = true;
      this.activeEmail.skipped = false;
      var queueRef = db.collection("users").doc(this.user.data.id).collection("emailQueue");

      await queueRef.doc(this.activeEmail.id).update({
        "skipped": this.activeEmail.skipped
      }).then(() => {
        this.isConfirming = false;
        this.isConfirmed = true;
        
        console.log("[rescheduleEmail] Successfully unskipped email");
      }).catch((err) => {
        console.log("[rescheduleEmail] error skipped email:",err);
      });

      await this.getEmailStatus(this.activeEmail);
    },
    // Modals
    showModal: function(modal) {
      this.modals[modal].visible = true;
    },
    closeModal: function() {
      for (const key in this.modals) {
        this.modals[key].visible = false;
        this.isConfirmed = false;
      }
    },
  },
  watch: {
    isSaved: {
      handler(value) {
        this.$emit("isSaved",value);
      }
    },
    searchPattern: {
      handler: function(value) {
        var vm = this;
        this.entriesTotalCount = 0;
        this.entriesTotalRevenue = 0;

        value == "" ? this.queue.data = this.queue.default : this.queue.data = this.searchEntries(value).map(x => x.item);

        this.queue.data.forEach(function(entry) {
            vm.entriesTotalCount += 1;
            vm.entriesTotalRevenue += Number(entry.total_price);
        }) 
      }
    },
    selectedEntries: {
      handler: function() {
          this.sentEmails = false;
          this.selectedEmails = this.selectedEntries.map(x => x.to);
      }
    },
    filteredEntries: {
      handler: function(newValue, oldValue) {
          if (oldValue.length === 0) {
              this.isSaved = true;
          } else if (newValue.length > 0 && this.isLoaded) {
              this.isSaved = false;
          }
      },
      deep: true
    },
    "queue.active": {
      handler: function() {
        if (this.isLoaded) {
          this.isSaved = false;  
        }
      }
    }
  }
};
</script>