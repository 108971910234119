import Image from '@tiptap/extension-image'
import { mergeAttributes } from '@tiptap/core'

export default Image.extend({
    name: 'custom-image',

    addAttributes() {
        return {
            ...Image.config.addAttributes(),
            size: {
                // default: 'small',
                parseHTML: element => element.getAttribute("data-size") || "small",
                // rendered: false
            },
            align: {
                // default: 'left',
                parseHTML: element => element.getAttribute("data-align"),
                // rendered: false
            }

        }
    },

    addCommands() {
        return {
            setImage: (options) => ({ tr, commands }) => {
                // console.log("set image");
                console.log("image options:",options)
                if(tr.selection?.node?.type?.name == 'custom-image') {
                    // console.log("Update custom image:",options);
                    return commands.updateAttributes('custom-image', options)
                }
                else {
                    console.log("this.name:",this.name);
                    return commands.insertContent({
                        type: this.name,
                        attrs: options
                    })
                }
            }
        }
    },

    renderHTML({ node, HTMLAttributes }) {

        // console.log("node:",node)
        HTMLAttributes["data-size"] = node.attrs.size || this.options["data-size"];
        HTMLAttributes["data-align"] = node.attrs.align || this.options["data-align"];

        return [
            'img',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)
        ]
        
    }
})