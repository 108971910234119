<template>
    <div class="content-wrapper">
        <div class="header flex">
            <div class="logo margin-right-20">
                <img class="full-width" :src="integration.icon"/>
            </div>
            <div class="content">
                <h1 class="">{{ integration.name }}</h1>
                <div class="text-sm">{{ integration.welcomeMessage }}</div>
            </div>
        </div>
        <div class="body">
            <div v-if="isLoading">
                Loading...
            </div>
            <div v-else class="integration">
                <div class="flex flex-v-center margin-bottom-20">
                    <h3 class="margin-right-10 margin-bottom-0">{{ integration.heading }}</h3>
                    <div v-if="integration.status === 'active'" class="pill active">Connected</div>
                    <div v-else class="pill inactive">Disconnected</div>
                </div>
                <div class="form">
                    <div class="fields">
                        <div class="field" v-for="field in integration.fields" v-bind:key="field.label" v-bind:class="[{ hidden: field.hide }]">

                            <!-- Fields: Text -->
                            <div v-if="field.type === 'text'" class="flex full-width flex-align-center">
                                <div class="input-group flex-1">
                                    <label v-bind:for="field.label" v-html="field.label"></label>
                                    <input v-bind:id="field.label" v-bind:type="field.type" v-bind:placeholder="field.placeholder" v-model="field.value"/>
                                </div>
                            </div>

                            <!-- Fields: Select -->
                            <div v-if="field.type === 'select' && integration.handle === 'klaviyo'">
                                <div class="input-group multi-select full-width">
                                    <label>{{ field.label }}</label>

                                    <multiselect 
                                        v-if="klaviyoLists.length > 0" 
                                        v-model="field.value" 
                                        class="full-width"
                                        :options="klaviyoLists" 
                                        track-by="list_name" 
                                        label="list_name" 
                                        :multiple="false" 
                                        :close-on-select="true" 
                                        :clear-on-select="false" 
                                        :searchable="false"
                                        placeholder="Select a list"
                                        >
                                        <template slot="singleLabel" slot-scope="props">
                                            <div class="list-option" v-if="props.option.list_name">
                                                <span class="margin-right-5">{{ props.option.list_name }}</span> 
                                                <span class="list-id">({{ props.option.list_id }})</span>
                                            </div>
                                        </template>
                                        <template slot="option" slot-scope="props">
                                            <div class="list-option">
                                                <span class="margin-right-5">{{ props.option.list_name }}</span> 
                                                <span class="list-id">({{ props.option.list_id }})</span>
                                            </div>
                                        </template>
                                    </multiselect>

                                </div>
                            </div>

                            <!-- Fields: Map -->
                            <div v-if="field.type === 'map' && integration.handle === 'airtable' && integration.databaseMapping" class="field-mapping margin-bottom-20">
                                <div class="heading margin-top-20">
                                    <h3>Field Mapping</h3>
                                    <div class="text-weight-reg"><b>Make sure to map your fields!</b> Any unmapped fields will not sync data to your Airtable base.</div>
                                </div>
                                <div class="headers flex flex-v-center">
                                    <h4 class="flex-1 margin-right-20 padding-right-15">Attribute Database</h4>
                                    <div class="margin-left-20 margin-right-20" style="color: transparent;">
                                        =>
                                    </div>
                                    <h4 class="flex-1 margin-left-10">Airtable</h4>
                                </div>
                                <div v-for="column in integration.databaseMapping.fields" :key="`column-${column.id}`" class="mapping flex flex-v-center">
                                    <div :id="`column-${column.id}`" class="flex-1 padding-15 background-grey-3 margin-bottom-10 border-1 border-radius-4 margin-right-10">{{ column.label }}</div>
                                    <div class="margin-left-10 margin-right-20 margin-bottom-10">
                                        =>
                                    </div>
                                    <div class="input-group flex-1" style="margin-bottom: 10px;">
                                        <select class="background-white border-1 border-radius-4 full-width padding-15" v-model="column.mapping[integration.handle].mappedField" @change="updateAirtableMappingOptions(column.mapping[integration.handle].mappedField)">
                                            <option v-for="option in integration.databaseMapping.availableOptions" :key="`${column.id}-${option.key}`" v-bind:value="option.key" :disabled="option.mapped">{{ option.key }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!-- Fields: Shopify only -->
                            <!-- <div class="shopify" v-if="integration.handle === 'shopify' && integration.status === 'active'">
                                <div class="input-group flex-1">
                                    <label v-bind:for="field.label">{{ field.label }}</label>
                                    <input v-bind:id="field.label" v-bind:type="field.type" v-bind:placeholder="field.placeholder" v-model="field.value"/>
                                </div>
                            </div> -->

                        </div>

                    </div>

                    <!-- Integration: Actions -->
                    <div class="actions flex">
                        <div class="field" v-for="field in integration.fields" v-bind:key="field.label" v-bind:class="[{ hidden: field.hide }]">
                            <div v-if="field.type === 'button'" class="flex full-width flex-align-center">
                                <!-- Button: Connect -->
                                <div v-if="field.id === 'connect'" id="connect" class="input-group flex-1">
                                    <div v-if="isLoading" class="loading flex flex-align-center margin-bottom-10 margin-top-10">
                                        <label class="flex-1">Loading...</label>
                                    </div>
                                    <div v-else>
                                        <div v-if="integration.status === 'active' && isSaved === true" class="flex flex-v-center">
                                            <button class="button primary outline" v-on:click="disconnectIntegration()">Disconnect</button>
                                        </div>
                                        <button v-else class="button primary full-width" :class="{ inactive: !validForm }" v-on:click="runMethod(field.method)">{{ field.text }}</button>
                                    </div>
                                </div>
                                <!-- Button: Other -->
                                <div v-else class="input-group flex-1">
                                    <div v-if="integration.status === 'active' && isSaved === true" class="flex flex-v-center">
                                        <button class="button primary full-width margin-left-15" v-on:click="runMethod(field.method)" :class="field.class">{{ field.text }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="notifications">
                        <div v-if="isSyncing">
                            Syncing to {{ integration.name }}...
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Save Button -->
        <div class="saveButton actions"  v-bind:class="{ unsaved: !isSaved }">
          <button class="button full-width" v-on:click="saveIntegrationData()" v-if="!isSaved && !isLoading">Save</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.content-wrapper {
    max-width: 750px !important;
}
.field-actions {
    margin-bottom: 1px;
}
.logo {
    width:  80px;
    height:  80px;
}
.status {
    text-transform: capitalize;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    import { functions, db } from "@/firebase";
    import { firebase } from "@firebase/app"
    import Multiselect from 'vue-multiselect'

    // Functions: Authentication
    const klaviyoAuth = functions.httpsCallable('klaviyoAuth');
    const shopifyAuth = functions.httpsCallable('shopifyAuth');
    const airtableAuth = functions.httpsCallable('airtableAuth');

    // Functions: General
    const klaviyo = functions.httpsCallable('klaviyo');
    const airtable = functions.httpsCallable('airtable');

    // Data: Integration Logos
    const integrations = require("../assets/data-defaultIntegrations.json").map(integration => {

        if (integration.icon) { integration.icon = require("@/assets/"+integration.icon); }
        
        return integration;
    })


    // Start controller
    export default {
        name: "Integration",
        components: {
            Multiselect
        },
        data: function() {
            return {
                isSaved: true,
                isLoading: true,
                isSyncing: false,
                validForm: true,
                disconnected: false,
                defaultIntegrations: [],
                savedIntegrations: [],
                klaviyoLists: [],
                integration: null,
                integrations: integrations,
                fieldKeys: [],
                airtableRecords: [],
                databaseColumns: {
                    available: [
                        { id: "id", label: "Order Id" },
                        { id: "created_at", label: "Created At" },
                        { id: "order_name", label: "Order Number", type: "link" },
                        { id: "order_email", label: "Order Email" },
                        { id: "order_url", label: "Order URL" },
                        { id: "customer_name", label: "Customer Name" },
                        { id: "first_name", label: "Customer First Name" },
                        { id: "last_name", label: "Customer Last Name" },
                        { id: "referralOption", label: "Referred By" },
                        { id: "writeInValue", label: "Referred By: Detail" },
                        { id: "product_titles", label: "Product Titles" },
                        { id: "variant_skus", label: "SKUs" },
                        { id: "variant_ids", label: "Variant Ids" },
                        { id: "total_price", label: "Total Price"},
                        { id: "state", label: "State/Province" },
                        { id: "country", label: "Country" },
                        { id: "additionalField-birthday", label: "Birthday" }
                    ]
                }
            }
        },
        computed: {
            ...mapGetters({
              user: "user"
            }),
            handle: function() {
                return this.$route.params.handle;
            },
            appUrl: function() {
                return window.location.host.indexOf("localhost") > -1 ? "http://"+String(window.location.host)+this.$route.path : "https://"+String(window.location.host)+this.$route.path;
            }
        },
        created: async function() {

            // Set up the integration based on the props.handle
            const vm = this;

            // console.log("[INTEGRATION] environment:",this.environment);
            vm.environment = "Production";

            vm.integration = this.integrations[this.integrations.findIndex(x => x.handle === this.$route.params.handle)];
            vm.defaultIntegration = JSON.parse(JSON.stringify(vm.integration)); // Create the default blank integration
            vm.integration = JSON.parse(JSON.stringify(vm.integration)); // Create the default blank integration

            console.log("[created] integration:",vm.integration);

            // Get the integration data
            await this.getIntegrationData();
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (vm.user.data) {
              vm.activeUser = vm.user.data;
              // console.log("Active User:",vm.activeUser); 

              // Get Shopify Access Token
              if (urlParams.has('code')) { 
                vm.getShopifyAccessToken(urlParams.get('code')); 
              }

              // Get Klaviyo Lists if we have an API Key
              if (vm.integration.handle === "klaviyo" && vm.integration.fields.filter(x => x.id === "apiKey")[0].value) {
                // console.log("Klaviyo: has API Key, get lists.");
                await vm.getKlaviyoLists();
                // console.log("KLAVIYO: Lists:",this.klaviyoLists);
                if (this.klaviyoLists.length > 0) {
                    await vm.integrations.map(x => { if (x.handle === "klaviyo") { 
                            x.fields.forEach(field => {
                                field.id === "syncedList" ? field.hide = false : "";
                            })
                        }
                    })
                    vm.isSaved = true;
                }
              }

              // Init: Airtable
              if (vm.integration.handle === "airtable") {
                var apiKey = this.integration.fields.filter(x => x.id === "apiKey")[0].value;
                if (apiKey) {
                    await this.getAirtableFields();
                }
              }
              
            }

            console.log("[Integration] vm.integration:",vm.integration);
            // console.log("[Integration] vm.isSaved:",vm.isSaved);

            this.isLoading = false;
            this.isSaved = true;


        },
        methods: {
            ...mapMutations({
                setUserData: "SET_USER_DATA"
            }),
            updateOnboardingStep: function(stepId, value) {

                var onboardingSteps = this.user.data.onboardingSteps;
                var stepIndex = onboardingSteps.findIndex(x => x.id === stepId);
                onboardingSteps[stepIndex].complete = value;

                this.setUserData({
                    key: "onboardingSteps",
                    value: onboardingSteps
                });

            },
            getIntegrationData: async function() {
                
                var vm = this;
                var user = await firebase.getUser("email", vm.user.data.email);

                vm.savedIntegrations = user[0].integrations;

                console.log("[getIntegrationData] savedIntegrations:",vm.savedIntegrations);

                // Get any saved field values from the integration data
                if (vm.savedIntegrations.length > 0) {

                    var savedIntegration = vm.savedIntegrations.filter(x => x.handle === vm.integration.handle)[0];

                    if (savedIntegration) {
                        vm.integration.status = savedIntegration.status || "inactive";

                        if (savedIntegration.databaseMapping) {
                            vm.integration.databaseMapping = savedIntegration.databaseMapping;
                            vm.integration.fields = vm.integration.fields.map(x => { if (x.id === "fieldMapping") { x.hide = false; } return x;})
                        }

                        vm.integration.fields = vm.integration.fields.map(x => {

                            var field = savedIntegration.fields.filter(y => y.id === x.id)[0];
                            if (field) {
                                x.value = field.value && x ? field.value : null;
                            }

                            return x;

                        })
                    }

                    vm.isSaved = true;

                    // console.log("[getIntegrationData] savedIntegration:",vm.integration);

                } 
            },
            saveIntegrationData: function() {

                // Only update the integration values
                var vm = this;
                var user = db.collection("users").doc(this.user.data.id);
                var integrations = this.integrations;

                if (this.savedIntegrations.length > 0) {
                    integrations = this.savedIntegrations.map(x => { 
                        if (x.handle === vm.integration.handle) { 
                            return vm.integration; 
                        } else { 
                            return x; 
                        }
                    })
                    if (integrations.findIndex(x => x.handle === vm.integration.handle) === -1) {
                        integrations.push(vm.integration);
                    }
                }
                // console.log("[saveIntegrationData] vm.integration:",vm.integration);
                // console.log("[saveIntegrationData] integrations:",integrations);
                
                // Set the data for the update to the updated integration
                var data = { 
                    integrations: integrations, 
                    onboardingSteps: vm.user.data.onboardingSteps,
                    storeAddress: vm.storeAddress ? vm.storeAddress : null
                };

                return user.update(data)
                .then(function() {
                  // Integration update successful 
                  console.log("Successfully updated user:",data);
                  vm.isSaved = true;
                })
                .catch(function(error) {
                    // Integration update error
                  console.error("Error updating user:",error);
                })
            },
            disconnectIntegration: function() {
                this.integration = this.defaultIntegration;
                this.integration.status = "inactive";
                this.storeAddress = null;
                this.disconnected = true;
            },
            runMethod: function(method) {
                // console.log("run action:",action);
                this[method]();
            },
            getShopifyAccessToken: function(code) {

              var vm = this;
              var storeAddress = this.integration.fields.filter(x => x.id === "storeAddress")[0].value;

              shopifyAuth({
                method: 'getAccessToken',
                shopify_store_name: storeAddress,
                code: code,
                environment: vm.environment
              }).then(async function(result) {
                // console.log("access token result:",result)
                if (vm.activeUser) {
                  // Assign the access token
                  vm.integration.accessToken = result.data.access_token;
                  vm.integration.status = "active";
                  vm.storeAddress = storeAddress;

                  // Update the onboarding step and save the integration data
                  await vm.updateOnboardingStep('shopify', true);
                  await vm.saveIntegrationData();

                  // Rewrite the URL to remove the code
                  vm.$router.replace({ name: "Integration", params: { handle: "shopify" }});
                }

              }).catch(function(error) {
                console.log("Error getting access code:",error)
              })

              // this.$router.push({ name: 'IntegrationsShopify' });

            },
            getKlaviyoLists: async function() {
                
                this.klaviyoLists = await klaviyo({
                    method: "GET",
                    url: "https://a.klaviyo.com/api/v2/lists",
                    apiKey: this.integration.fields.filter(x => x.id === "apiKey")[0].value
                }).then(function(response) {
                    console.log(response.data)
                    return response.data;
                }).catch(function(err) {
                    console.log("error:",err);
                    return err;
                })

            },
            getAirtableFields: async function() {

                var vm = this;
                var view = this.integration.fields.filter(x => x.id === "view")[0].value;
                var viewParam = view ? `&view=${view}` : "";
                var airtableData = await airtable({
                    method: "GET",
                    userId: this.user.data.id,
                    params: `?maxRecords=1${viewParam}`
                })

                console.log("[syncToAirtable] airtableRecords:",airtableData.data)

                if (airtableData.data) {

                    this.airtableRecords = airtableData.data;
                    this.fieldKeys = Object.keys(this.airtableRecords[0].fields);

                    if (!this.integration.databaseMapping || this.integration.databaseMapping.fields.length == 0) {

                            this.integration.databaseMapping = {};
                            this.integration.databaseMapping.fields = this.databaseColumns.available.map(x => {
                                x.mapping = { airtable: {} };
                                return x;
                            })

                            this.integration.fields = this.integration.fields.map(x => { if (x.id === "fieldMapping" || x.id === "sync") { x.hide = false; x.fields = vm.fieldKeys; } return x;})
                    } else {
                        this.integration.fields = this.integration.fields.map(x => { if (x.id === "fieldMapping" || x.id === "sync") { x.hide = false; } return x;})
                    }

                    // Set up databaseMapping options
                    this.integration.databaseMapping.mappedOptions = this.integration.databaseMapping.fields.map(field => {
                        if (field.mapping.airtable.mappedField) {
                            return field.mapping.airtable.mappedField;
                        }
                    }).filter(field => field !== undefined);
                    this.integration.databaseMapping.availableOptions = this.fieldKeys.map(key => {
                        var isMapped = vm.integration.databaseMapping.mappedOptions.indexOf(key) > -1 ? true : false;
                        return {
                            key: key,
                            mapped: isMapped
                        }
                    });
                    
                    // console.log("[getAirtableFields] this.integration.databaseMapping",this.integration.databaseMapping);

                } else {
                    console.log("[refreshConnection] ERROR: could not authenticate with Airtable");
                }
            },
            syncToAirtable: async function() {

                this.isSyncing = true;
                var requestData = [];

                // Get Entries && last 1000 Airtable records so that we don't post any duplicates
                if (this.entries.length === 0) {
                    await this.getEntries();
                }

                this.airtableRecords = (await airtable({
                    method: "GET",
                    userId: this.user.data.id,
                    params: "?maxRecords=1000&sort%5B0%5D%5Bfield%5D=Order%20Date&sort%5B0%5D%5Bdirection%5D=desc"
                })).data
                
                var mapping = this.integration.databaseMapping.fields.map(field => {
                    return {
                        id: field.id,
                        mappedTo: field.mapping.airtable.mappedField
                    }
                }).filter(field => field.mappedTo !== undefined);
                
                console.log("[syncToAirtable] entries:",this.entries)
                console.log("[syncToAirtable] mapping:",mapping)
                console.log("[syncToAirtable] airtableRecords:",this.airtableRecords)

                requestData = this.entries.map(entry => {
                    var obj = { fields: {} };
                    var existingRecordIndex = this.airtableRecords.findIndex(x => x.fields["Email"] === entry["order_email"]);

                    if (existingRecordIndex === -1) {
                        mapping.forEach(field => {
                            obj.fields[field.mappedTo] = entry[field.id];
                        })
                        return obj;
                    } else {
                        console.log("[syncToAirtable] record already exists.")
                        return;
                    }
                    
                }).filter(entry => entry !== undefined);

                console.log("[syncToAirtable] requestData:",requestData);

                await airtable({
                    method: "POST",
                    userId: this.user.data.id,
                    data: requestData
                }).then((response) => {
                    console.log("[syncToAirtable] POST response:",response.data);
                }).catch((err) => {
                    console.log("[syncToAirtable] POST ERROR:",err);
                })

                this.isSyncing = false;
            },
            updateAirtableMappingOptions: function() {
                var vm = this;
                // Set up databaseMapping options
                this.integration.databaseMapping.mappedOptions = this.integration.databaseMapping.fields.map(field => {
                    if (field.mapping.airtable.mappedField) {
                        return field.mapping.airtable.mappedField;
                    }
                }).filter(field => field !== undefined);

                this.integration.databaseMapping.availableOptions = this.fieldKeys.map(key => {
                    var isMapped = vm.integration.databaseMapping.mappedOptions.indexOf(key) > -1 ? true : false;
                    return {
                        key: key,
                        mapped: isMapped
                    }
                });

                this.isSaved = false;

            },
            testConnection: async function() {

                var options, result;

                if (this.integration.handle === "klaviyo") {

                    options = {
                        apiKey: this.integration.fields.filter(x => x.id === "apiKey")[0].value
                    }
                    result = await klaviyoAuth(options);

                    // console.log("result:",result);
                    if (result.data.length > 0) {
                        this.integration.status = "active";
                        this.saveIntegrationData();
                    }

                } else if (this.integration.handle === "shopify") {

                    options = {
                        method: 'getAuthUrl',
                        shopify_store_name: this.integration.fields.filter(x => x.id === "storeAddress")[0].value,
                        app_url: this.appUrl,
                        scope: "read_orders,read_script_tags,write_script_tags,read_customers,read_products",
                        environment: this.environment
                    }
                    
                    result = await shopifyAuth(options);

                    await this.saveIntegrationData();

                    window.location.href = result.data.permission_url;

                } else if (this.integration.handle === "airtable") {
                    
                    options = {
                        apiKey: this.integration.fields.filter(x => x.id === "apiKey")[0].value,
                        base: this.integration.fields.filter(x => x.id === "base")[0].value,
                        table: this.integration.fields.filter(x => x.id === "table")[0].value,
                    }
                    result = await airtableAuth(options);

                    console.log("[testConnection] airtable result:",result);

                    // console.log("[testConnection] airtable result:",result);
                    if (result.data.records.length > 0) {
                        this.integration.status = "active";
                    }

                    await this.saveIntegrationData();
                    await this.getAirtableFields();
                    
                } else {
                    console.log("[testConnection] error: this integration does not have a connection method defined.")
                }
            },
            refreshConnection: async function() {
                if (this.integration.handle === "airtable") {

                    // console.log("[refreshConnection] this.integration.fields:",this.integration.fields);
                    // console.log("[refreshConnection] this.integration.databaseMapping:",this.integration.databaseMapping);
                    await this.getAirtableFields();

                } else {
                    console.log("[refreshConnection] error: this integration does not have a connection method defined.")
                }
            }
        },
        watch: {
            "integration.fields": {
                handler: function() {

                    var vm = this;

                    this.isSaved = false;
                    this.validForm = true;

                    if (this.integration) {
                        this.integration.fields.forEach((field) => {
                            if ((field.value == null || field.value == "") && field.required === true) {
                                vm.validForm = false;
                            }
                            if (field.id === "storeAddress") {
                                console.log("[validateShopifyUrl]:",vm.validateShopifyUrl(vm.storeAddress));
                                if (!vm.validateShopifyUrl(field.value)) {
                                    vm.validForm = false;
                                }
                            }
                        })
                    }
                    

                },
                deep: true
            },
            "integration.databaseMapping.fields": {
                handler: function() {
                    if (this.integration) {
                        console.log("changed database mapping:",this.integration.databaseMapping)
                        this.isSaved = false;
                    }
                },
                deep: true
            },
            "isSaved": {
              handler(value) {
                if (!this.isLoading) {
                    this.$emit("isSaved",value);    
                }
              }
            },
        }
    };
</script>