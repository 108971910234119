<template>
  <div class="content-wrapper">

    <div class="header width-900">
        <div class="primary-heading margin-bottom-20">Email settings</div>
        <div class="text-sm">Global settings for all of your Attribute emails.</div>
    </div>
    <div class="body two-columns" :class="{ hidden: !isLoaded }">

        <div id="configuration" class="column padding-right-40">

            <!-- Sender: Details -->
            <section id="sender-details">
              <div class="section-header margin-bottom-10">
                <div class="heading text-lg">Sender Details</div>
              </div>

              <div class="card-row flex">
                <div class="input-group flex-1">
                  <label>Sender Name</label>
                  <input type="text" v-model="sender.name">
                </div>
              </div>

              <div class="card-row">
                <!-- Email: From -->
                <div class="flex flex-v-end margin-bottom-20 email-verification">
                  <div class="input-group margin-bottom-0 flex-1">
                    <label>Sender Email</label>
                    <input type="text" v-model="sender.from">
                  </div>
                  <!-- Verify Sender -->
                  <button class="button margin-left-15"
                          v-on:click="createSender()" 
                          v-bind:class="[
                           { 'outline inactive verified': sender.verified },
                           { 'outline inactive pending': sender.verified === 'pending' }, 
                           { 'primary': !sender.verified }
                          ]">
                    <span v-if="!sender.verified">Verify</span>
                    <span v-else-if="isVerifying">Verifying...</span>
                    <span v-else-if="sender.verified === 'Pending'">Verification Sent!</span>
                    <span v-else>Verified</span>
                  </button>
                </div>
              </div>
            </section>

            <!-- Email: Formatting -->
            <section id="email-settings">

              <div class="section-header margin-bottom-10">
                <div class="heading text-lg">Style & Formatting</div>
              </div>
              
              <!-- Max width, background, logo -->
              <div class="flex">
                <div class="input-group flex-1">
                  <label>Max Width</label>
                  <input type="number" min="480" max="1200" v-model="sender.emailSettings.maxWidth"/>
                </div>
              </div>

              <div>
                <div class="heading text-md margin-bottom-10">Colors</div>
                <div class="flex">
                  <div class="input-group color">
                    <label>Text</label>
                    <input type="color" v-model="sender.emailSettings.textColor">
                  </div>
                  <div class="input-group color margin-left-15">
                    <label>Email</label>
                    <input type="color" v-model="sender.emailSettings.background">
                  </div>
                  <div class="input-group color margin-left-15">
                    <label>Body</label>
                    <input type="color" v-model="sender.emailSettings.body.background">
                  </div>
                  <div class="input-group color margin-left-15">
                    <label>Header</label>
                    <input type="color" v-model="sender.emailSettings.header.background">
                  </div>
                  <div class="input-group color margin-left-15">
                    <label>Footer</label>
                    <input type="color" v-model="sender.emailSettings.footer.background">
                  </div>
                </div>
              </div>

              <!-- Email: Header -->
              <div class="heading text-md margin-bottom-10 margin-top-10">Header & Footer</div>

              <!-- Logo -->
              <div class="flex">
                <div class="input-group image">
                  <label>Logo</label>
                  <button 
                    @click="showModal('custom-image')"
                    :style="`background-image: url(${sender.emailSettings.logo.url})`">
                  </button>
                </div>
                <div class="input-group flex-1 margin-left-15 max-width-75">
                  <label>Width</label>
                  <input type="number" min="15" max="600" v-model="sender.emailSettings.logo.width"/>
                </div>
                <div class="input-group flex-1 margin-left-15">
                  <label>Position</label>
                  <select class="full-width" v-model="sender.emailSettings.logo.position">
                    <option default value="center">Center</option>
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </select>
                </div>
                <div class="input-group switch margin-left-15">
                  <div class="text-weight-med text-xs color-headings margin-bottom-5">Show logo?</div>
                  <label class="switch">
                    <input type="checkbox" v-model="sender.emailSettings.logo.visible">
                    <span class="slider round"></span>
                  </label>
                  
                </div>
              </div>

              <div class="input-group editor" v-if="isLoaded">
                <label>Global Header</label>
                <editor 
                  :modelValue="{ 
                    content: sender.emailSettings.header.content, 
                    background: sender.emailSettings.header.background, 
                    isSaved: true 
                  }" 
                  @modelValue="updateHeaderContent" 
                  :modelClass="'attributeEmail'">
                </editor>
              </div>

              <!-- Email: Footer -->
              <div class="input-group editor" v-if="isLoaded">
                <label>Global Footer</label>
                <editor 
                  :modelValue="{ 
                    content: sender.emailSettings.footer.content, 
                    background: sender.emailSettings.footer.background, 
                    isSaved: true 
                  }" 
                  @modelValue="updateFooterContent" 
                  :modelClass="'attributeEmail'">
                </editor>
              </div>

              <!-- Global Custom CSS -->
              <div class="card-row">
                <div class="input-group code">
                    <label>Global Custom CSS</label>
                    <prism-editor v-model="sender.emailSettings.customCss" class="min-height-200" :highlight="highlighter" line-numbers language="css"></prism-editor>
                </div>
              </div>
            </section>
        </div>

        <div id="preview" class="column padding-right-40 sticky" :class="{ unsaved: !isSaved }">
            <h3 class="text-weight-md margin-bottom-15 margin-right-10">Preview</h3>
            <div class="card background-alternate">

                <!-- Preview Header -->
                <div class="head margin-bottom-20">
                  <div><b>From:</b> <span>{{ sender.name }}</span> <span>&#x3C;{{ sender.from }}&#x3E;</span></div>
                  <div><b>Subject:</b> <span>[Your email subject]</span> </div>
                </div>

                <!-- Preview Body -->
                <table class="attributeEmail preview" v-bind:style="`max-width: ${sender.emailSettings.maxWidth}px; background: ${sender.emailSettings.background};`">
                  <thead>
                    <tr v-if="sender.emailSettings.logo.visible">
                      <td class="logo-header" :style="`text-align: ${sender.emailSettings.logo.position}; background: ${sender.emailSettings.header.background};`">
                        <img :src="sender.emailSettings.logo.url" class="logo" :style="`width: ${sender.emailSettings.logo.width}px`"/>
                      </td>
                    </tr>
                    <tr v-if="sender.emailSettings.header.content !== '<p></p>'"><th v-html="sender.emailSettings.header.content"></th></tr>
                  </thead>
                  <tbody>
                    <tr><td>
                      <table border="0" cellspacing="0" width="100%">
                        <tbody>
                          <tr>
                            <td v-html="emailBody()" v-bind:style="`color: ${sender.emailSettings.textColor}`">
                            </td>
                          </tr>
                          <tr v-if="sender.emailSettings.body.survey">
                            <td v-html="sender.emailSettings.body.survey"></td>
                          </tr>                              
                        </tbody>
                      </table>
                    </td></tr>
                  </tbody>
                  <tfoot>
                    <tr><td v-bind:style="`background: ${sender.emailSettings.footer.background}`" v-html="sender.emailSettings.footer.content"></td></tr>
                  </tfoot>
                </table>

            </div>
        </div>

    </div>


    <!-- Modals -->
    <!-- MODAL: Add Image -->
    <Modal id="modal-custom-image" v-if="modals['custom-image'].visible" @close="closeModal('custom-image')">

        <template v-slot:header>
            <div class="heading text-md text-weight-bold">Select an image</div>
        </template>

        <template v-slot:body>
          <div class="wrapper">
            <div class="image-preview" v-if="imagePreviewUrl">
              <div class="width-300 height-300 background-image margin-bottom-20 border-radius-4" :style="`background-image: url('${imagePreviewUrl}')`">
              </div>
            </div>
            <div class="input-group file">
              <label 
                class="file-input-label full-width full-height background-image border-radius-4 border-1">
                  <input type="file"
                    class="file-input full-width full-height"
                    @change="onImageSelected"
                    fieldKey="logo"
                    accept="image/*"
                    path="media"
                  />
                  <div>
                    <span v-if="!imagePreviewUrl">Upload Image</span>
                    <span v-else>Change Image</span>
                  </div>
              </label>
            </div>
          </div>
        </template>

        <template v-slot:footer>
            <div class="actions">
                <button type="button" class="button small full-width" v-on:click="confirmImage()" v-if="imagePreviewUrl">
                    <span v-if="!loadingImage">Confirm</span>
                    <span v-else>Loading...</span>
                </button>
            </div>
        </template>
    </Modal>

    <!-- MODAL: Resend Verification -->
    <Modal v-if="modals['resend-verification'].visible" @close="closeModal('resend-verification')">

      <template v-slot:header>
          <div class="heading text-md text-weight-bold">Resend Verification</div>
      </template>

      <template v-slot:body>
        <div class="wrapper">
          <div v-if="!isSent">This sender email address already exists - do you want to re-send the verification email?</div>
          <div v-else>Please check your email to verify this sender.</div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="actions">
          <button v-if="isSent" type="button" class="button primary full-width" v-on:click="closeModal('resend-verification')">
            <span>Done</span>
          </button>
          <button v-else type="button" class="button primary full-width" v-on:click="resendVerification(sender.id)">
            <span v-if="!isSending">Resend Verification</span>
            <span v-else>Sending...</span>
          </button>
        </div>
      </template>

    </Modal>

    <!-- MODAL: Existing User -->
    <Modal v-if="modals['existing-sender'].visible" @close="closeModal('existing-sender')">

      <template v-slot:header>
          <div class="heading text-md text-weight-bold">Sender already exists.</div>
      </template>

      <template v-slot:body>
        <div class="wrapper">
          <div>Sorry, this sender email is already associated with an account. Please use another email address or reach out to us at <a href="mailto:sebastian@getattribute.com?subject=Existing%20Sender%20Issue">sebastian@getattribute.com</a> for assistance.</div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="actions">
          <button type="button" class="button primary full-width" v-on:click="closeModal('existing-sender')">Close</button>
        </div>
      </template>

    </Modal>

    <!-- Save Button -->
    <div class="saveButton actions"  v-bind:class="{ unsaved: !isSaved }">
      <button class="button primary full-width" v-on:click="saveUserData()" v-if="!isSaved">Save</button>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .sticky { top: 100px; height: 125vh; }
  .sticky.unsaved { top: 150px; }
  .email-verification {
    position: relative;

    .button {
      padding: 15px 40px;
      transition: none;
      margin-bottom: 20px;
    }

    .verified { 
      position: absolute;
      right: 15px;
      bottom: 10px;
      background: none;
      border: none;
      padding: 5px 0;

      .verification-status {
        color: #6F6666;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }
  .card {
    overflow-x: scroll;
  }
</style>

<!-- @TODO  -->
<!-- @TODO  -->

<script>
import { PrismEditor } from 'vue-prism-editor';
import Prism from "prismjs";
import Modal from "@/components/Modal.vue"
import Editor from '@/components/editor/TextEditor.vue';
import { mapGetters } from "vuex";
import firebase from "@firebase/app";
import { functions, db } from "@/firebase";
import NProgress from "nprogress";

import "vue-prism-editor/dist/prismeditor.min.css"; 
import "prismjs/themes/prism.css";
import externalCss from "!!raw-loader!@/styles/external-email.css"; // load the css for the email

const createSender = functions.httpsCallable('createSender');
const postmark = functions.httpsCallable('postmark');

export default {
  name: "Settings",
  components: {
    PrismEditor,
    Editor,
    Modal,
  },
  data: function() {
    return {
      isSaved: true,
      isVerifying: false,
      isLoaded: false,
      isSending: false,
      isSent: false,
      loadingImage: false,
      verificationMessage: null,
      imagePreviewUrl: null,
      modals: {
        "custom-image": { visible: false },
        "resend-verification": { visible: false },
        "existing-sender": { visible: false }
      },
      sender: {
        id: null,
        active: false,
        from: null,
        name: null,
        verified: false,
        emailSettings: {
          maxWidth: 600,
          background: `#FFFFFF`,
          textColor: `#000000`,
          logo: {
            url: null,
            width: 60,
            visible: true,
            position: "center"
          },
          header: {
            content: `<div></div>`,
            background: `#FFFFFF`
          },
          body: {
            background: `#FFFFFF`
          },
          footer: {
            content: `<p style="text-align: center; font-size: 12px; line-height: 1.62em;"><span style="color: #000000">Thanks for supporting {{ shop_name }}! Don't want to receive emails like this? </span><br/><a href="{{ unsubscribe_link }}" data-text="" data-type="link" data-textcolor="white" style="color: white; background: white; width: auto" data-background="white" data-width="auto" class="custom-link"><strong><span style="color: #000000">Click here</span></strong></a><strong><span style="color: #000000"> to unsubscribe.</span></strong></p>`,
            background: `#FFFFFF`
          },
          customCss: ".attributeEmail { max-width: 600px; }"
        }
      }
    }
  },
  computed: {
      ...mapGetters({
        // map `this.user` to `this.$store.getters.user`
        user: "user"
      }),
  },
  created: async function() {

    NProgress.start();
    
    // Get user data and init customCss
    await this.getUserData();
    await this.initCustomCss();

    // If we have a sender, then update the settings
    if (this.sender) {
      if (this.sender.id) { await this.getSender(this.sender.id); }
      if (this.sender.emailSettings.logo.url) { this.imagePreviewUrl = this.sender.emailSettings.logo.url; }
    }
    
    this.isSaved = true;
    this.isLoaded = true;

    NProgress.done();

  },
  methods: {
    updateHeaderContent: function(value) {
      this.sender.emailSettings.header.content = value.content;
      // this.isSaved = this.isLoaded ? value.isSaved : true;
    },
    updateFooterContent: function(value) {
      this.sender.emailSettings.footer.content = value.content;
      // this.isSaved = this.isLoaded ? value.isSaved : true;
    },
    emailBody: function() {
      if (this.sender.emailSettings.body.content) {
        return this.mreplace([
          // [/{{ discount_code }}/gm, discountCode],
          [/{{ shop_name }}/gm, this.user.data.displayName],
          // [/{{ first_name }}/gm, 'Test User'],
        ], this.sender.emailSettings.body.content)
      } else {
        return;
      }
    },
    highlighter(code) {
      // js highlight example
      return Prism.highlight(code, Prism.languages.css, "CSS");
    },
    formatCss: function() {

      if (!this.sender.emailSettings.customCss) { this.sender.emailSettings.customCss = "" }
      console.log("customCss:",this.sender.emailSettings.customCss);


      var baseCss = externalCss;
      // Add max width
      baseCss += `\ntable.attributeEmail { \n  max-width: ${this.sender.emailSettings.maxWidth}px; }`;

      var formattedCss = baseCss + ".attributeEmail " + this.mreplace([ 
        [/}/gm, "} .attributeEmail"],
        [/,/gm, ", .attributeEmail"]
      ], this.sender.emailSettings.customCss)
      // console.log("[EMAIL] formattedCss:",formattedCss);

      return formattedCss;
    },
    initCustomCss: function() {
        var formattedCss = this.formatCss();
        var styleNode = document.createElement("style");
        styleNode.id = "customCss";
        styleNode.innerHTML = formattedCss;
        
        document.getElementById("preview").append(styleNode);
    },
    updateCustomCss: function() {
        var formattedCss = this.formatCss();
        var styleNode = document.getElementById("customCss");
        if (styleNode) {
            styleNode.innerHTML = formattedCss;
        }
        
    },
    getUserData: async function() {

        // Get user data from Firebase, otherwise, return default
        var user = await firebase.getUser("email", this.user.data.email);


        console.log("[getUserData] user emails:",user[0].emails);
        
        if (user[0].sender) { this.sender = user[0].sender }
        if (!this.sender.emailSettings.body) { this.sender.emailSettings.body = { background: "#FFFFFF", body: "<div>Your email content here</div>" } }

        if (user[0].emails) {
          if (user[0].emails.length > 0) { this.sender.emailSettings.body.content = user[0].emails[0].content.body; }
          if (user[0].emails.length > 0) { this.sender.emailSettings.body.survey = user[0].emails[0].content.survey; }
        }
        


        console.log("this.sender.emailSettings",this.sender.emailSettings);

        // console.log("[getUserData] user:",user[0]);

    },
    saveUserData: async function() {

        // Compile data to be saved
        var vm = this;
        var user = db.collection("users").doc(vm.user.data.id);

        var data = { sender: vm.sender };

        return user.update(data)
        .then(function() {
          vm.isSaved = true;
        })
        .catch(function(error) {
          console.error("Error updating user:",error);
        })
    },
    getSender: async function(senderId) {

      var sender = (await postmark({
        "method": "GET",
        "path": `/senders/${senderId}`
      })).data

      // if (!sender) { this.email.verifiedSender = true; }
      if (!sender.Confirmed) { this.sender.verified = "Pending"; }
      else if (sender.Confirmed) { this.sender.verified = true; }

      return sender;

    },
    getSenders: async function() {

      var senders = (await postmark({
        "method": "GET",
        "path": "/senders?count=500&offset=0"
      })).data

      return senders.SenderSignatures;

    },
    resendVerification: async function(senderId) {

      // console.log("[Configure] resendVerification:",senderId);

      await postmark({
        "method": "POST",
        "path": `/senders/${senderId}/resend`
      })

      this.isSent = true;

      // console.log("[Configure] resent verification:",verification);

    },
    createSender: async function() {

      var vm = this;
      vm.isVerifying = true;
      // console.log("[EMAIL] Verify sender:",this.email.content.from);

      // Check to see if the sender already exists
      var senders = await this.getSenders();
      var senderIndex = senders.findIndex(x => x.EmailAddress === vm.sender.from);

      // If the sender already exists, raise an error
      if (senderIndex > -1) {

        var existingSender = senders[senderIndex];
        console.log("[createSender] existingSender:",existingSender);

        if (existingSender.Confirmed) {
          vm.showModal('existing-sender');
          await vm.getUserData();
          vm.sender.verified = true;
          vm.isVerifying = false;
          vm.isSaved = true;
          
        } else {
          vm.sender.verified = "Pending";
          vm.sender.id = existingSender.ID;
          vm.showModal('resend-verification');
        }

        vm.verificationMessage = "Sender already exists.";
        

      } else {
        // If no sender, create a new one
        await createSender({
          "senderName": this.sender.name, 
          "nickname": this.user.data.id,
          "senderEmail": this.sender.from
        }).then(async (response) => {

          console.log("[EMAIL] Sent verification request:",response);

          if (response.data.DKIMUpdateStatus && response.data.DKIMUpdateStatus === "Pending") {

            vm.sender.verified = "Pending";
            vm.sender.id = response.data.ID;

          } else if (response.data.ErrorCode && response.data.ErrorCode === 504) {

            console.log("[createSender] sender already exists:",vm.sender.id);

            if (vm.sender.id) {
              // Get the sender and see if it's verified yet, if not confirmed, re-send verification link
              var sender = await vm.getSender(vm.sender.id);

              console.log("[Configure] sender:",sender)
              if (!sender.Confirmed) { 
                await vm.resendVerification(sender.ID);
              }
            }

          } else {
            vm.verificationMessage = vm.verificationMessages.error;
            vm.sender.verified = false;
          }

        }).catch((err) => {
          console.log("[EMAIL] Error verifying sender:",err);
          vm.sender.verified = false;
        })

        vm.saveUserData();
        vm.isVerifying = false;

        
      }

      

    },
    onImageSelected: async function() {

      var path = event.target.getAttribute('path');
      // var fieldKey = event.target.getAttribute('fieldKey');
      var uploadedImageUrl;

      console.log("[EDITOR] upload file to path:",path);
      // console.log("[EDITOR] upload file to user ID:",this.user.data.id);

      if (path) {

        var uploadFile = event.target.files[0];
        var uploadPath;

        // console.log("[EDITOR] uploadFile:",uploadFile)

        uploadPath = `${this.user.data.id}/${path}/${uploadFile.name}`;
        uploadedImageUrl = await this.saveImageToFirebase(uploadPath, uploadFile);

        this.imagePreviewUrl = uploadedImageUrl;

      }

    },
    saveImageToFirebase: async function(uploadPath, uploadFile) {

      var existingImage = await firebase.getFileMetadata(this.user, uploadPath);
      var file = uploadFile;

      if (!existingImage.customMetadata) {
        file.metadata = { 
          customMetadata: {
            productId: this.$route.params.id, 
            contentType: file.type
          }
        }
      } else {
        file.metadata = {
          customMetadata: existingImage.customMetadata
        }
      }

      var uploadedImage    = await firebase.uploadFile(this.user, uploadPath, file);
      var uploadedImageURL = await uploadedImage.ref.getDownloadURL().then((downloadURL) => { return downloadURL; });
      
      return uploadedImageURL;

    },
    confirmImage: function() {
      this.sender.emailSettings.logo.url = this.imagePreviewUrl;
      this.saveUserData();
      this.closeModal("custom-image");
    },
    showModal: function(modalId) {
      this.modals[modalId].visible = true;
    },
    closeModal: function(modalId) {
      // console.log("close modal:",modalId);
      this.modals[modalId].visible = false;
      this.isSent = false;
    },

  },
  watch: {
    "isSaved": {
      handler(value) {
        this.$emit("isSaved",value);
      }
    },
    "sender.from": {
      handler() {
        if (this.isLoaded) {
          console.log("[watch] sender.from:",this.isLoaded);
          this.isSaved = false;
          this.sender.verified = false;
        }
      }
    },
    "sender.emailSettings": {
      handler() {
        if (this.isLoaded) {
          console.log("[watch] sender.from:",this.isLoaded);
          this.isSaved = false;
        }
      },
      deep: true
    }
  }
};
</script>