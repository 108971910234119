// Add the Firebase services that you want to use
import { firebase } from "@firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// const dotenv = require('dotenv').config({path: __dirname + '/.env'});

// console.log("[FIREBASE] ENVIRONMENT:",process.env.NODE_ENV);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

// console.log("firebase config:",firebaseConfig);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

// collection refs
const usersCollection = db.collection('users');

// Getters
firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

firebase.getUser = (key, value) => {
  var usersRef = db.collection("users");
  var query = usersRef.where(key, "==", value);
  var result = [];

  // console.log("Users DB:",usersRef);

  return query.get().then(function(querySnapshot) {
    if (querySnapshot.docs.length > 0) {
      querySnapshot.forEach(function(doc) {
          // console.log(doc.id, " => ", doc.data());
          var user = doc.data();
          user.id = doc.id;
          result.push(user);
      });  
    } else {
      console.log("No document found where",key,"= "+value)
    }
    return result;
  }).catch(function(error) {
      console.log("Error getting documents: ", error);
  });
  
}

firebase.getFile = async (path) => {

  var storageRef = storage.ref();
  var fileRef = storageRef.child(path);

  return await fileRef.getDownloadURL()
  .then((url) => {
    // Insert url into an <img> tag to "download"
    return url;
  })
  .catch((error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    return error.code;
  });

}

// Upload Files
firebase.uploadFile = async (user, path, file) => {

  console.log("user:",user.data.id)
  console.log("path:",path)

  var storageRef = storage.ref(`users/${user.data.id}`);
  var fileRef = storageRef.child(path);

  return await fileRef.put(file, file.metadata)
  .then((snapshot) => {
    return snapshot;
  }).catch((error) => {
    // Uh-oh, an error occurred!
    return error;
  });

}

firebase.updateFileMetadata = async (user, path, data) => {

  var storageRef = storage.ref(`users/${user.data.id}`);
  var fileRef = storageRef.child(path);

  return await fileRef.updateMetadata(data)
  .then((metadata) => {
    // Updated metadata for 'images/forest.jpg' is returned in the Promise
    return metadata;
  }).catch((error) => {
    // Uh-oh, an error occurred!
    return error;
  });

}

firebase.getFileMetadata = async (user, path) => {

  var storageRef = storage.ref(`users/${user.data.id}`);
  var fileRef = storageRef.child(path);

  return await fileRef.getMetadata()
  .then((metadata) => {
    // Updated metadata for 'images/forest.jpg' is returned in the Promise
    return metadata;
  }).catch((error) => {
    // Uh-oh, an error occurred!
    return error;
  });

}


// export utils/refs
export {
  db,
  auth,
  functions,
  storage,
  usersCollection
}