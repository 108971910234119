<template>
    <div v-if="checkingInstallation" class="content-wrapper width-400">
        <div class="header text-align-center">
            <div class="primary-heading margin-bottom-20" v-html="installationMessage"></div>
        </div>
    </div>
    <div v-else-if="!isLoading" class="content-wrapper width-400">
        <div v-if="integration.status !== 'active'">
            <div class="header text-align-center">
                <div class="primary-heading margin-bottom-20">Install Attribute</div>
                <div class="text-md">Please provide your Shopify store name and email address to install Attribute on Shopify.</div>
            </div>
            <div class="body">
                <div class="field" v-for="field in integration.fields" v-bind:key="field.label" v-bind:class="{ hidden: field.hide }">
                    <div v-if="field.type === 'text'" class="flex full-width flex-align-center">
                        <div class="input-group flex-1">
                            <label v-bind:for="field.label" v-html="field.label"></label>
                            <input v-bind:id="field.label" v-bind:type="field.type" v-bind:placeholder="field.placeholder" v-model="field.value" :class="{ disabled: field.disabled }"/>
                        </div>
                    </div>
                    <div v-if="field.type === 'email'" class="flex full-width flex-align-center">
                        <div class="input-group flex-1">
                            <label v-bind:for="field.label" v-html="field.label"></label>
                            <input v-bind:id="field.label" v-bind:type="field.type" v-bind:placeholder="field.placeholder" v-model="field.value"/>
                        </div>
                    </div>
                    <div v-if="field.type === 'button'" class="flex full-width flex-align-center">
                        <div v-if="field.id === 'connect'" id="connect" class="input-group flex-1 full-width">
                            <div v-if="isLoading" class="loading flex flex-align-center margin-bottom-10 margin-top-10">
                                <label class="flex-1">Loading...</label>
                            </div>
                            <div v-else>
                                <div v-if="integration.status === 'active' && isSaved === true" class="flex flex-v-center full-width">
                                    <button class="button primary outline full-width" v-on:click="disconnectIntegration()">Disconnect</button>
                                </div>
                                <button v-else class="button primary full-width" :class="{ disabled: validStoreDetails }" v-on:click="runMethod(field.method)">{{ field.text }}</button>
                            </div>
                        </div>
                        <div v-else class="input-group flex-1">
                            <label v-html="field.label"></label>
                            <button class="button full-width" v-on:click="runMethod(field.method)">{{ field.text }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="error" class="error text-align-center margin-bottom-20">
                <span v-html="error"></span>
            </div>
        </div>
        <div v-else-if="error" class="error content-wrapper width-400 text-align-center margin-bottom-20">
            <span v-html="error"></span>
        </div>
        <div v-else class="header text-align-center">
            <div class="primary-heading margin-bottom-20">Congrats!</div>
            <div class="text-md margin-bottom-15">You've connected Attribute to Shopify.</div>
            <div class="text-md margin-bottom-20">Please check your email to set your password and activate your account.</div>
            <button class="button primary full-width" @click="$router.push({ name: 'Plan' })">Choose a plan</button>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .app-content {
        text-align: center;
    }
    .content-wrapper {
        padding-top: 60px !important;
        margin: 60px auto !important;
    }
</style>

<script>
    import { mapGetters } from "vuex";
    import { functions, db, auth } from "@/firebase";
    import { firebase } from "@firebase/app"
    import { mapMutations } from "vuex";

    // Functions: Authentication
    const klaviyoAuth = functions.httpsCallable('klaviyoAuth');
    const shopifyAuth = functions.httpsCallable('shopifyAuth');

    export default {
        name: "Install",
        computed: {
            ...mapGetters({
              user: "user"
            })
        },
        data: function() {
            return {
                appUrl: "http://"+String(window.location.host)+this.$route.path, // @TODO - remove http://
                isLoading: true,
                checkingInstallation: false,
                disconnected: false,
                integration: null,
                integrations: require('../assets/data-defaultIntegrations.json'),
                savedIntegrations: [],
                shopifyStoreAddress: "admin",
                onboardingSteps: require("../assets/data-onboardingSteps.json"),
                userId: null,
                userEmail: null,
                error: null,
                installationMessage: "",
                shopify_auth: {},
                permissionUrl: null,
                validStoreDetails: false
            }
        },
        created: async function() {

            const vm = this;
            const integrationHandle = this.$route.params.handle;
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            var isValidHmac = false;

            this.shopifyStoreAddress = urlParams.get('shop');
            this.shopify_auth = this.getSavedData("shopify_auth");
            this.environment = "Production";

            // Set the default integrations
            vm.integration = this.integrations[this.integrations.findIndex(x => x.handle === integrationHandle)];
            vm.defaultIntegration = JSON.parse(JSON.stringify(vm.integration)); // Create the default blank integration

            console.log("[install] integration:",vm.integration)
            console.log("[install] integration:",vm.shopify_auth)

            // Get the integration data
            if (this.user.data) {
                vm.activeUser = vm.user.data;
                await this.getIntegrationData();
            }

            // Check if nonces match && Get Shopify Access Token
            if (urlParams.has('code') && this.shopify_auth && urlParams.get('state') === this.shopify_auth.nonce) { 

                // Check the installation and hmac
                this.checkingInstallation = true;
                this.installationMessage = "Checking your installation...";
                isValidHmac = await this.isValidHmac(queryString);

                if (isValidHmac) {
                    // If we don't have an active user, then create one, get the user, and set to active
                    if (!this.user.data && this.shopify_auth.email) { 

                        console.log("[checkUserAccount] no account, create new user:",this.shopify_auth.email);
                        // Get access token from Shopify and save
                        await vm.getShopifyAccessToken(urlParams.get('code'), this.shopify_auth.storeAddress, this.shopify_auth.email); 

                        // Create User
                        await this.createUser(this.shopify_auth.email, this.shopify_auth.storeAddress);

                        // In case of an error creating the user, uninstall the app from shopify
                        if (vm.error) {
                            await shopifyAuth({
                                method: 'uninstall',
                                shopify_store_name: this.shopify_auth.storeAddress, 
                                shopify_access_token: this.integration.accessToken
                            });
                        } else {
                            // Save the integration
                            await vm.updateOnboardingStep('shopify', true);
                            await vm.saveIntegrationData(this.userId); 
                        }
                        
                        // End installation process and redirect to main install page
                        this.checkingInstallation = false;
                        this.$router.replace({ name: "Install", params: { handle: "shopify" }});

                    } else {

                        console.log("[checkUserAccount] this.user.data:",this.user.data);
                        console.log("[checkUserAccount] this.shopify_auth:",this.shopify_auth);

                        if (this.user.data && this.user.data.email) {

                            // If we have an existing user with an email, get access token from Shopify and save
                            await vm.getShopifyAccessToken(urlParams.get('code'), this.shopify_auth.storeAddress, this.shopify_auth.email); 

                            // Update User
                            await db.collection("users").doc(this.user.data.id).update({ storeAddress: this.shopify_auth.storeAddress });

                            // Save the integration
                            await vm.updateOnboardingStep('shopify', true);
                            await vm.saveIntegrationData(this.user.data.id);

                            // Refresh the page
                            this.checkingInstallation = false;
                            this.$router.replace({ name: "Install", params: { handle: "shopify" }});

                        } else {
                            // Prompt the user to enter their email and create an account
                            this.integration.fields = this.integration.fields.map(field => {
                                if (field.id === "storeAddress") {
                                    field.value = this.shopify_auth.storeAddress;
                                    field.disabled = true;
                                }
                                if (field.id === "connect") {
                                    field.text = "Create account";
                                }
                                return field;
                            });
                            this.error = "Please <b>create an account</b> to continue.";
                            this.checkingInstallation = false;
                        }
                        
                    }
                } else {                    
                    vm.error = "<b>Error:</b> Could not validate HMAC from Shopify";
                }
            }
            // Check if this is the App install link
            else if (urlParams.has('hmac') && urlParams.has('shop')) {
                this.checkingInstallation = true;
                this.installationMessage = "Validating installation request...";
                isValidHmac = await this.isValidHmac(queryString);

                // console.log("[created] isValidHmac:",isValidHmac)
                if (isValidHmac) {
                    this.installationMessage = "Redirecting you to Shopify..."
                    this.permissionUrl = await this.getShopifyPermissionUrl(urlParams.get('shop'));
                    // console.log("[created] permissionUrl:",permissionUrl);
                    // Send to shopify install page
                    window.location = this.permissionUrl;
                } else {
                    this.installationMessage = "<b>Error:</b> Could not validate installation request."
                }
            }
            
            this.isLoading = false;

        },
        methods: {
            ...mapMutations({
                setUserData: "SET_USER_DATA"
            }),
            updateOnboardingStep: function(stepId, value) {

                console.log("[updateOnboardingStep] this.onboardingSteps",this.onboardingSteps);
                var vm = this;

                vm.onboardingSteps = vm.onboardingSteps.map(x => {
                    if (x.id === stepId) {
                        x.complete = value;
                    }
                    return x;
                });

            },
            isValidHmac: async function(queryString) {

                var vm = this;
                var queryHmac = (new URLSearchParams(queryString)).get("hmac");
                var validateHmac = await shopifyAuth({
                    method: "validateHmac",
                    queryString: queryString,
                    environment: vm.environment
                })
               
                console.log("[validateHmac] queryHmac:",queryHmac);
                console.log("[validateHmac] hmac:",validateHmac.data);
                return validateHmac.data === queryHmac;

            },
            checkStoreDetails: async function() {

                var isValidEmail = await this.validateEmail(this.userEmail);
                var isValidStore = await this.validateUrl(this.storeAddress);

                if (isValidEmail && isValidStore) {
                    var user = (await firebase.getUser("email", this.userEmail))[0];
                    console.log("[checkStoreDetails] user:",user);

                    if (user && user.storeAddress !== null) {
                        this.error = `<b>${this.userEmail}</b> already in use. Please <a href="/login">log into your account</a>.`;
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            },
            resetPassword: function(email) {
                firebase.auth().sendPasswordResetEmail(email)
                  .then(() => {
                    // Password reset email sent!
                    // ..
                    console.log("[resetPassword] sent:",true);
                  })
                  .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // ..
                    console.log(`Error resetting password: ${errorCode} - ${errorMessage}`)
                });
            },
            createUser: async function(email, storeAddress) {

                var vm = this;
                var password = Math.random().toString(36).slice(-8);

                // First, make sure the user is signed out
                console.log("[createUser] signOutUser");
                await this.signOutUser();

                if (email) {
                    var existingUser = (await firebase.getUser("email", email))[0];

                    if (existingUser) {
                        console.log("[createUser] existingUser found:",existingUser);
                        if (existingUser.storeAddress === null) {
                            console.log("[createUser] no storeAddress set, update user.")
                            vm.userId = existingUser.id;
                            vm.storeAddress = storeAddress;
                            await vm.saveIntegrationData(vm.userId);
                        } else {
                            vm.error = `A user already exists with that email address.`
                        }
                        
                    } else {
                        await auth.createUserWithEmailAndPassword(email, password)
                            .then(async data => {
                                await data.user
                                    .updateProfile({
                                      displayName: storeAddress
                                    })
                                    .then(async (response) => {
                                        console.log("New User Registered:",response);

                                        // Send reset password email
                                        await vm.resetPassword(email);

                                        await db.collection("users").add({
                                          email: email,
                                          displayName: storeAddress,
                                          storeAddress: storeAddress,
                                          defaultSurveyOptions: [
                                            { name: "Instagram", order: 0 },
                                            { name: "Google", order: 1 },
                                            { name: "Facebook", order: 2 },
                                            { name: "YouTube", order: 3 },
                                            { name: "TikTok", order: 4 },
                                            { name: "Friends/Family", order: 5 }
                                          ],
                                          integrations: vm.integrations,
                                          onboardingSteps: vm.onboardingSteps
                                        })
                                        .then(async function(docRef) {

                                            vm.userId = docRef.id;
                                            await vm.saveIntegrationData(docRef.id);

                                        })
                                        .catch(function(error) {
                                            vm.error = error;
                                            console.error("Error adding document: ", error);
                                        });

                                    });
                            })
                            .catch(async (err) => {
                                console.log("[createUser] err:",err);
                                vm.error = err.message;
                            });
                    }
                } else {
                    console.log("[createUser] must provide a valid email.")
                    vm.error = "Please provide a valid email.";
                }
                
                
            },
            getIntegrationData: async function() {
                
                var vm = this;
                var user = await firebase.getUser("email", vm.user.data.email);

                vm.savedIntegrations = user[0].integrations;

                // console.log("[getIntegrationData] savedIntegrations:",vm.savedIntegrations);

                // Get any saved field values from the integration data
                if (vm.savedIntegrations.length > 0) {

                    var savedIntegration = vm.savedIntegrations.filter(x => x.handle === vm.integration.handle)[0];
                    vm.integration.status = savedIntegration.status || "inactive";

                    vm.integration.fields.map(x => {

                        var field = savedIntegration.fields.filter(y => y.id === x.id)[0];
                        if (field) {
                            x.value = field.value && x ? field.value : null;
                        }

                    })

                    vm.isSaved = true;

                } else {
                    // this.integrations = this.user.data.integrations;
                }
            },
            saveIntegrationData: function(userId) {

                // Only update the integration values
                var vm = this;
                var user = db.collection("users").doc(userId);
                var integrations = this.integrations;

                vm.activeUser = user;

                if (this.savedIntegrations.length > 0) {
                    integrations = this.savedIntegrations.map(x => { 
                        if (x.handle === vm.integration.handle) { 
                            return vm.integration; 
                        } else { 
                            return x; 
                        }
                    })
                }
                

                console.log("[saveIntegrationData] user:",user);

                // Set the data for the update to the updated integration
                var data = { 
                    integrations: integrations,
                    onboardingSteps: vm.onboardingSteps
                };

                return user.update(data)
                .then(function() {
                  // Integration update successful 
                  console.log("Successfully updated user:",data);
                  vm.isSaved = true;
                })
                .catch(function(error) {
                    // Integration update error
                  console.error("Error updating user:",error);
                })
            },
            getShopifyPermissionUrl: async function(storeAddress) {

                const nonce = Math.random().toString(36).slice(-8);
                const email = this.integration.fields.filter(x => x.id === "userEmail")[0].value || null;

                const options = {
                    method: 'getAuthUrl',
                    shopify_store_name: storeAddress,
                    app_url: this.appUrl,
                    scope: "read_orders,read_script_tags,write_script_tags",
                    nonce: nonce,
                    environment: this.environment
                }
                
                // For the production app, add access to all orders
                // if (this.environment === "Production") { options.scope += ",read_all_orders"; }

                // Authorize the account with Shopify && store the nonce in localStorage
                var result = await shopifyAuth(options);
                this.setSavedData("shopify_auth", { "nonce": nonce, "email": email, "storeAddress": storeAddress });

                if (result.data.permission_url) {
                    return result.data.permission_url;
                } else {
                    console.log("Error: failed to create permission_url")
                    this.error = "<b>Error:</b> Failed to create permission URL."
                }
            },
            getShopifyAccessToken: async function(code, storeAddress, userEmail) {

              var vm = this;

              await shopifyAuth({
                method: 'getAccessToken',
                shopify_store_name: storeAddress,
                code: code,
                environment: vm.environment
              }).then(async function(result) {

                  // If we have a user, assign the access token to the user
                  vm.integration.accessToken = result.data.access_token;
                  vm.integration.storeAddress = storeAddress;
                  vm.integration.userEmail = userEmail;
                  vm.integration.status = "active";

                  // Update the integration fields
                  vm.integration.fields = vm.integration.fields.map(x => {
                    if (x.id === "userEmail") { x.value = userEmail; }
                    if (x.id === "storeAddress") { x.value = storeAddress; }
                    return x;
                  })

              }).catch(function(error) {
                console.log("Error getting access code:",error)
                vm.error = "Could not get access code from shopify."
              })

              // this.$router.push({ name: 'IntegrationsShopify' });

            },
            disconnectIntegration: function() {
                this.integration = this.defaultIntegration;
                this.disconnected = true;
            },
            runMethod: function(method) {
                // console.log("run action:",action);
                this[method]();
            },
            signOutUser: function() {
                var vm = this;
                // const auth = getAuth();
                auth.signOut().then(() => {
                  // Sign-out successful.
                  if (vm.environment === "development") { console.log("[HEADER] Signout Successful") }

                  console.log("[HEADER] emit signIn:",false)
                  vm.$emit("signedIn",false);
                  // vm.$router.push({ name: "Login" });
                }).catch((error) => {
                  // An error happened.
                  if (vm.environment === "development") { console.log("[HEADER] Error signing out user:",error); }
                });
            },
            testConnection: async function() {
                // console.log("test connection:",this.integration);

                var options, result;

                if (this.integration.handle === "klaviyo") {

                    options = {
                        apiKey: this.integration.fields.filter(x => x.id === "apiKey")[0].value
                    }
                    result = await klaviyoAuth(options);

                    // console.log("result:",result);
                    if (result.data.length > 0) {
                        this.integration.status = "active";
                        this.saveIntegrationData();
                    }

                } else if (this.integration.handle === "shopify") {

                    var vm = this;
                    var email = this.integration.fields.filter(x => x.id === "userEmail")[0].value;
                    var storeAddress = this.integration.fields.filter(x => x.id === "storeAddress")[0].value;
                    var existingStore;
                    var existingUser;

                    // Check if we already have these users/emails for users who are not logged in
                    if (!vm.user.data) {
                        existingStore = (await firebase.getUser("storeAddress", storeAddress))[0];
                        existingUser = (await firebase.getUser("email", email))[0];
                    }

                    if (existingStore) { 
                        console.log("[testConnection] existingStore:",existingStore);
                        vm.error = `<b>Error:</b> An installation for ${storeAddress} already exists. Please contact the store owner for access.`;
                        return; 
                    } else if (existingUser && existingUser.storeAddress !== null) { 
                        vm.error = `<b>Error:</b> A user with that email already exists. Please <a href="/login">log into your account</a>.`;
                        return; 
                    } else {
                        if (!this.permissionUrl){
                            this.permissionUrl = await this.getShopifyPermissionUrl(this.integration.fields.filter(x => x.id === "storeAddress")[0].value);
                            window.location = this.permissionUrl;
                        } else {
                            console.log("[getShopifyAccessToken] email:",email);
                            // await this.getShopifyAccessToken(urlParams.get('code'), this.shopify_auth.storeAddress, email);
                        }
                        console.log("[testConnection] permissionUrl:",this.permissionUrl)
                    }
                    

                }
            }
        },
        watch: {
            'integration.fields': {
                handler: async function(value) {
                    if (!this.isLoading) {
                        this.userEmail = value.filter(x => x.id === "userEmail")[0].value;
                        this.storeAddress = value.filter(x => x.id === "storeAddress")[0].value;
                        this.validStoreDetails = await this.checkStoreDetails();
                    }
                },
                deep: true
            }
        }
    }
</script>