<script>
import { LineChart, useLineChart } from "vue-chart-3";
import { ref, computed, defineComponent } from "@vue/composition-api";
// import moment from "moment";

export default defineComponent({
  name: "BarChart",
  components: {
    LineChart,
  },
  props: { 
    report: Object 
  },
  setup(props) {

    const imgData = ref(null);

    const options = computed(() => ({
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          type: 'linear',
          suggestedMax: 5,
          ticks: {
            stepSize: 1
          }
        },
        x: {
            offset: true,
            stacked: true,
            type: "time",
            distribution: "linear",
            min: new Date(props.report.startDate),
            max: new Date(props.report.endDate),
            time: {
                unit: 'day',
                round: 'day',
                displayFormats: {
                  day: 'MMM D'
                },
            }
        }
      },
      responsive: true
    }));

    

    const chartData = computed(() => ({
      datasets: props.report.chartData
    }));

    const { lineChartProps, lineChartRef } = useLineChart({
      chartData,
      options,
    });

    return { lineChartProps, lineChartRef, imgData, chartData };

  },
  render: function (createElement) {

    // console.log("this.chartData:",this.chartData)

    if (this.chartData) {
      return createElement(LineChart, { props: this.lineChartProps });
    } else {
      return createElement('h1',"Loading...")
    }
  }
});
</script>