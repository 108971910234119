<template>
    <div class="content-wrapper">
        <div class="header width-xm" style="margin-bottom: 20px;">
            <div class="primary-heading margin-bottom-15">Get Started</div>
            <div class="text-sm">Welcome to Attribute! Follow the steps below to get your survey set up - and start to learn how your customers really find out about your brand.</div>
        </div>
        <div class="body">
            <div v-bind:class="[ 'card single margin-bottom-10 xm flex flex-v-center', { complete: step.complete }]" v-for="(step, index) in onboardingSteps" v-bind:key="step.id">
                <div class="status-icon flex flex-v-center flex-h-center" v-on:click.stop="toggleStep(step)" :class="[{ 'complete': step.complete }]">
                    <span v-if="!step.complete" class="flex-1">{{ (index+1) }}</span>
                    <span v-else class="flex-1 icon icon-check-icon"></span>
                </div>
                <div class="margin-left-15 content flex-1">
                    <div class="heading flex-1">
                        {{ step.heading }}
                    </div>
                    <div>
                        {{ step.info }}
                    </div>
                </div>
                <div class="actions">
                    <button class="button small primary" :class="{ inactive: step.complete }" v-on:click="goToStep(step)">
                        <span v-if="!step.complete">{{ step.buttonText }}</span>
                        <span v-else>Complete</span>
                    </button>
                </div>
            </div>

            <div v-if="onboardingComplete()" class="width-xm margin-top-20">
                <div class="text-sm">
                    Nice! You've completed all of the onboarding steps. <router-link :to="{ name: 'Reports' }">View your reports</router-link> to see your survey in action.
                </div>
            </div>

            <div class="secondary-heading margin-top-40 margin-bottom-15">Learn More</div>
            <div class="text-sm margin-bottom-20 width-xm">Looking to learn more about your customers, or give them more reasons to fill out your survey? Check out our guides below.</div>

            <div class="articles flex">
                <a class="card sm article-preview margin-right-15 max-width-328" v-for="article in featuredArticles" v-bind:key="article.handle" v-on:click="$router.push({ name: 'SupportArticle', params: { handle: article.handle }})" v-bind:class="`article-${article.type}`">
                    <div class="heading margin-bottom-10">{{ article.title }}</div>
                    <div class="excerpt margin-bottom-15">{{ article.excerpt }}</div>
                    <div class="article-type pill">{{ article.type }}</div>
                </a>
            </div>

        </div>
    </div>
</template>

<style lang="scss">
#Welcome {
    .articles {
        .article {
            .tag {
                text-transform: capitalize;
            }
        }
    }
}
</style>

<script>
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { db } from "@/firebase";

var articles = require("@/assets/data-supportArticles.json");

export default {
    name: "Welcome",
    data: function() {
        return {
            onboardingSteps: require("@/assets/data-onboardingSteps.json"),
            featuredArticles: articles.filter(x => x.featured === true),
            isSaved: true
        }
    },
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    created: async function() {

        var onboardingSteps = this.user.data.onboardingSteps;

        // Do an initial set of the onboarding steps
        if (onboardingSteps) {
            this.onboardingSteps = this.onboardingSteps.map(x => {
                for (var i in onboardingSteps) {
                    var step = onboardingSteps[i];
                    if (x.id === step.id) { x.complete = step.complete; }
                }
                return x;
            })

        } else {

            this.user.data.onboardingSteps = this.onboardingSteps;
            await this.setUserData({
                key: "onboardingSteps",
                value: this.onboardingSteps
            })

            this.saveUserData();
        }

    },
    methods: {
        ...mapMutations({
            setUserData: "SET_USER_DATA"
        }),
        toggleStep: function(step) {
            for (var index in this.onboardingSteps) {
                if (step.id === this.onboardingSteps[index].id) {
                    this.onboardingSteps[index].complete = !this.onboardingSteps[index].complete;
                }
            }
            this.user.data.onboardingSteps = this.onboardingSteps;
            this.saveUserData();
        },
        goToStep: function(step) {
            if (!step.complete) {
                this.$router.push(step.route) 
            }
        },
        onboardingComplete: function() {

            return this.onboardingSteps.filter(x => x.complete === false).length === 0;
            
        },
        saveUserData: function() {
            var vm = this;
            var user = db.collection("users").doc(vm.user.data.id);
            console.log("user:",vm.user.data);

            return user.update(vm.user.data)
            .then(function() {
              // Integration update successful 
              console.log("Successfully updated user:",vm.user.data);
              // vm.isSaved = true;
            })
            .catch(function(error) {
                // Integration update error
              console.error("Error updating user:",error);
            })
        }
    }
};
</script>