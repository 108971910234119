<template>
    <div class="content-wrapper">
        <div class="header">
            <div class="primary-heading margin-bottom-20">Install your survey</div>
            <div class="text-sm">Install your attribution survey on your Shopify or Recharge checkout.</div>
        </div>
        <div class="body width-750">
            <h3 class="heading">Installation Script</h3>
            <div class="text-sm margin-top-10 margin-bottom-20">Copy the below script and paste it into the Additional Scripts field in your Checkout settings. For more information, see the instructions below.</div>

            <h3 class="heading margin-top-40">Instructions</h3>
            <div class="text-sm margin-top-10 margin-bottom-20">Choose the platform that you'll be installing your attribution survey on.</div>

            <div v-for="instruction in instructions" class="card collapsable margin-top-20 full-width" v-bind:key="instruction.handle">
                <div class="heading flex flex-v-center" v-on:click="instruction.isHidden = !instruction.isHidden">
                    <div class="icon margin-right-20">
                        <img :src="instruction.icon" class="full-width"/>
                    </div>
                    <h3 class="flex-1 margin-bottom-0">{{ instruction.heading }}</h3>
                    <span class="line-height-0 collapse-icon margin-right-10">
                        <img v-if="instruction.isHidden" src="@/assets/icon-plus.svg" class="width-25">
                        <img v-else src="@/assets/icon-minus.svg" class="width-25">
                    </span>
                </div>
                <div v-bind:class="[ 'body', { hidden: instruction.isHidden } ]">
                    <ol class="steps">
                        <li class="step text-sm" v-for="step in instruction.steps" v-html="step" v-bind:key="step"></li>
                    </ol>
                    <div class="card-wrapper margin-top-20" v-if="instruction.installationScript">
                        <div class="card background-alternate code padding-15">
                            <code id="scriptTemplate">
                                &lt;script&gt;
                                    {{ scriptTemplate }}
                                &lt;/script&gt;
                            </code>
                        </div>
                        <button class="button primary margin-top-10" v-on:click="copyToClipboard(`<script>${scriptTemplate}</script>`)">
                            <span v-if="isCopied">Copied!</span>
                            <span v-else>Copy</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.icon {
    width: 48px;
    height: 48px;
}
.steps {
    padding: 10px 40px 0;
}
</style>

<script>
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { db } from "@/firebase";

export default {
    name: "Installation",
    data: function() {
        return {
            isCopied: false,
            userData: { id: null },
            instructions: [
                {
                    handle: "shopifyInstructions",
                    heading: "Shopify",
                    isHidden: true,
                    icon: require("@/assets/logo-shopify.png"), 
                    steps: [
                        `Go to the <a href="/install/shopify">Shopify Install</a> page`,
                        `We'll then install your Attribute script automatically`,
                        `Once complete, check that your installation is active by viewing your <a href="/integrations">Integrations</a>`,
                        `Double-check that your <a href="/survey">Survey</a> is active`
                    ]
                },
                {
                    handle: "rechargeInstructions",
                    heading: "Recharge",
                    isHidden: true,
                    icon: require("@/assets/logo-recharge.png"), 
                    installationScript: true,
                    steps: [
                        `Copy the installation script below`,
                        `Go to your Recharge admin`,
                        `Navigate to Checkout`,
                        `Scroll down to <span class="text-weight-med">Additional scripts</span> and paste the script at the top of the text area`,
                        `Double-check that your <a href="/survey">Survey</a> is active`
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            user: "user"
        }),
        scriptTemplate: function() { 
            return `const attributeData = { "userId": "${this.userData.id}", "shop_name": "{{ shop.name }}"}<\/script><script> var attributeScript = document.createElement("script"); attributeScript.setAttribute("src","https://cdn.getattribute.com/attribute.js"); document.querySelector("body").append(attributeScript);` //eslint-disable-line
        }
    },
    created: function() {
        this.userData = this.user.data;
    },
    methods: {
        ...mapMutations({
            setUserData: "SET_USER_DATA"
        }),
        updateOnboardingStep: function(stepId, value) {

            var onboardingSteps = this.user.data.onboardingSteps;
            var stepIndex = onboardingSteps.findIndex(x => x.id === stepId);
            onboardingSteps[stepIndex].complete = value;

            this.setUserData({
                key: "onboardingSteps",
                value: onboardingSteps
            });

            var vm = this;
            var user = db.collection("users").doc(this.user.data.id);

            return user.update({ onboardingSteps: onboardingSteps })
            .then(function() {
              // vm.shopifyStoreName = vm.activeUser.auth.shopify.id; 
              // console.log("Successfully updated user:",vm.user.data);
              vm.isSaved = true;
            })
            .catch(function(error) {
              console.error("Error updating user:",error);
            })

        },
        copyToClipboard: function(text) {
            function fallbackCopyTextToClipboard(text) {
              var textArea = document.createElement("textarea");
              textArea.value = text;
              
              // Avoid scrolling to bottom
              textArea.style.top = "0";
              textArea.style.left = "0";
              textArea.style.position = "fixed";

              document.body.appendChild(textArea);
              textArea.focus();
              textArea.select();

              try {
                document.execCommand('copy');
              } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
              }

              document.body.removeChild(textArea);
            }
            function copyTextToClipboard(text) {
              if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
              }
              navigator.clipboard.writeText(text).then(function() {
                // console.log('Async: Copying to clipboard was successful!');
              }, function(err) {
                console.error('Async: Could not copy text: ', err);
              });
            }

            this.updateOnboardingStep("install",true);

            this.isCopied = true;

            return copyTextToClipboard(text);
        }
    }
};
</script>