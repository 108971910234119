<template>
	<div class="content-wrapper">
		<div class="header flex flex-v-center">
			<div class="icon icon-arrow-left-icon text-lg margin-right-10 clickable" @click="$router.push({ name: 'Support' })"></div>
			<div class="primary-heading">{{ article.title }}</div>
		</div>
		<div class="body width-750">
			<div id="introduction" class="text-sm">
				<div class="heading text-weight-bold margin-bottom-10 text-md">Introduction</div>
				<div v-html="article.introduction.message"></div>
				<div class="margin-top-20">What we're doing:</div>
				<ul class="margin-top-10">
					<li class="text-sm" v-for="item, index in article.introduction.contents" :key="`intro-${index}`" v-html="item">
					</li>
				</ul>
			</div>
			<div id="steps" class="text-sm margin-top-40">
				<div class="heading text-weight-bold margin-bottom-10 text-md">Steps</div>
				<ol>
					<li class="text-sm" v-for="item, index in article.steps" :key="`step-${index}`" v-html="item"></li>
				</ol>
			</div>
			<div id="contact" class="text-sm margin-top-40">
				<div class="heading text-weight-bold margin-bottom-10 text-md">Still need help?</div>
				<div>Contact us at <a href="mailto:support@getattribute.com">support@getattribute.com</a>.</div>
			</div>
		</div>
	</div>
</template>

<script>
var articles = require("@/assets/data-supportArticles.json");

export default {
	name: "SupportArticle",
	data: function() {
		return {
			article: articles.filter(x => x.handle === this.$route.params.handle)[0]
		}
	},
	created: function() {
	}
};
</script>