<template>
  <header id="header-navigation">
    <div class="logo flex flex-v-center">
      <router-link :to="{ name: 'Home' }" class="line-height-0">
        <img v-if="saved" src="@/assets/logo-attribute.svg" />
        <img v-else src="@/assets/logo-attribute-white.svg" />
      </router-link>
    </div>
    <div v-if="!isLoginRoute()" class="links flex flex-h-self-end flex-1 flex-v-center margin-right-15">

      <!-- Action Bar -->
      <div id="actionBar" class="flex-1" v-if="$route.name !== 'Home' && $route.name !== 'Submit' && !saved"
        :class="{ unsaved: saved === false }">
        <span>Unsaved {{ active_route }}</span>
      </div>
      <div v-if="saved" class="flex-1"></div>

      <!-- Icon: Support -->
      <div class="support margin-right-15">
        <a class="icon icon-help-circle-icon text-md secondary" v-on:click="$router.push({ name: 'Support' })"></a>
      </div>
      <!-- Account Menu -->
      <button class="account-menu unformatted" @click="subMenuVisible = !subMenuVisible">
        <span id="account-icon" class="icon background-primary-red color-white margin-right-10 icon-user-icon"></span>
        <span class="label text-sm heading-text">{{ username }}</span>

        <div v-if="user.data">
          <div v-if="subMenuVisible" class="sub-menu">
            <router-link :to="{ name: 'Account' }" class="unformatted flex flex-v-center">
              <span class="icon icon-user-icon secondary text-sm margin-right-10"></span>
              <span class="text-sm">Your account</span>
            </router-link>
            <router-link :to="{ name: 'Plan' }" class="unformatted flex flex-v-center">
              <span class="icon icon-credit-card-icon secondary text-sm margin-right-10"></span>
              <span class="text-sm">Plans &amp; billing</span>
            </router-link>
            <div class="divider"></div>
            <a @click="signOutUser()" class="unformatted flex flex-v-center">
              <span class="icon icon-log-out-icon secondary text-sm margin-right-10"></span>
              <span class="text-sm">Sign out</span>
            </a>
          </div>
        </div>
        <div v-else>
          <div v-if="subMenuVisible" class="sub-menu">
            <router-link :to="{ name: 'Login' }" class="unformatted flex flex-v-center">
              <span class="icon icon-user-icon secondary text-sm margin-right-10"></span>
              <span class="text-sm">Log in</span>
            </router-link>
          </div>
        </div>
      </button>
    </div>
  </header>
</template>

<style lang="scss">
#header-navigation {
  #account-icon {
    padding: 5px;
    border-radius: 100%;
  }

  .account-menu {
    position: relative;

    >span {
      pointer-events: none;
    }

    .sub-menu {
      position: absolute;
      top: 100%;
      right: 15px;
      margin-top: 15px;
      background-color: white;
      width: auto;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 15px 20px;

      .divider {
        height: 1px;
        background-color: #E6E6E6;
        width: 100%;
        margin: 10px 0;
      }

      a {
        display: block;
        padding: 10px 20px 10px 10px;
        color: #332626;
        white-space: nowrap;

        .icon {
          color: #6F6666;
        }

        &:hover {
          color: #FC4A3F;
          background-color: #FCEEED;
          border-radius: 4px;

          .icon {
            color: #FC4A3F;
          }
        }
      }

    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { auth } from "@/firebase";

export default {
  name: "Header",
  props: {
    signedIn: Boolean,
    saved: Boolean
  },
  data: function () {
    return {
      navItems: [
        { name: "Register", label: "Sign Up", icon: require("@/assets/icon-account.svg") },
        { name: "Login", label: "Log In", icon: require("@/assets/icon-account.svg") }
      ],
      username: "",
      subMenuVisible: false
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    active_route: function() { 
        return this.$route.name; 
    },
  },
  created: function () {
    var vm = this;

    if (this.user.loggedIn) {
      this.username = this.user.data.name;
    }

    document.querySelector('body').addEventListener('click', function (event) {
      var classList = event.target.classList;
      if (!classList.contains('account-menu')) {
        vm.subMenuVisible = false;
      }
    })
  },
  methods: {
    signOutUser: function () {
      var vm = this;
      // const auth = getAuth();
      auth.signOut().then(() => {
        // Sign-out successful.
        if (vm.environment === "development") { console.log("[HEADER] Signout Successful") }

        console.log("[HEADER] emit signIn:", false)
        vm.$emit("signedIn", false);
        vm.$router.push({ name: "Login" });
      }).catch((error) => {
        // An error happened.
        if (vm.environment === "development") { console.log("[HEADER] Error signing out user:", error); }
      });
    },
    isLoginRoute: function () {
      return this.$route.name === "Login" || this.$route.name === "Register";
    }
  }
};
</script>