<template>
    <div :class="{ hidden: !isLoaded }">
        <div v-if="sender" class="column" :class="{ unsaved: !isSaved }">
            <div id="attributeSurvey" class="background-alternate preview padding-30">
                <div v-if="sender" class="logo-header margin-bottom-30" :style="`text-align: ${sender.emailSettings.logo.position};`">
                  <img :src="sender.emailSettings.logo.url" class="logo" :style="`width: ${sender.emailSettings.logo.width}px`"/>
                </div>
                <div class="order">
                    <div class="order-information">
                        <div class="order-message">{{ survey.thankYouMessage }}</div>
                    </div>
                </div>
                <div class="form">
                    <div class="message">{{ survey.referralRequest }}</div>

                    <!-- Referral Question -->
                    <div class="input-group">
                        <label v-html="survey.questionLabel"></label>
                        <select class="full-width input" v-model="selectedOption">
                            <option value="" disabled selected>Please select an option</option>
                            <option v-for="option in surveyOptions" v-bind:key="option.name" v-bind:value="option">{{ option.name }}</option>
                        </select>
                        <div class="icon icon-chevron-down-icon text-lg v-center margin-top-10"></div>
                    </div>
                    <div class="input-group" v-if="selectedOption.isWriteIn">
                        <input type="text" v-model="selectedOption.writeInValue" :placeholder="selectedOption.writeInPrompt ? selectedOption.writeInPrompt : 'Write your answer here...'"/>
                    </div>

                    <!-- Additional Fields -->
                    <div class="input-group" v-for="field in survey.additionalFields" v-bind:key="field.id" v-bind:class="[ field.type ]">
                        <div v-if="field.visible">
                            <label v-html="field.label"></label>
                            <input :type="field.type" class="input"/>
                        </div>
                    </div>

                    <!-- Actions -->
                    <div class="actions">
                        <button class="button primary full-width" v-bind:style="`background-color: ${survey.submitButtonColor}; border: 1px solid ${survey.submitButtonColor};`" v-on:click="formSubmitted = !formSubmitted" v-bind:class="{ inactive: formSubmitted }">
                            <span v-if="!formSubmitted">{{ survey.submitButtonText }}</span>
                            <span v-else>Thanks!</span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="text-sm text-weight-bold margin-bottom-20 margin-top-40">Integrations</div> -->
            <!-- <div class="text-sm">Review your <router-link :to="{ name: 'Integrations'}" class="text-weight-bold">integrations here</router-link>.</div> -->
        </div>
        <div class="padding-30" v-else>
            <h1 class="margin-bottom-20">Sorry!</h1>
            <p class="margin-bottom-20">Looks like this page isn't working. Want to learn more about how <b>Attribute</b> works? </p>
            <button class="button primary" @click="$router.push({ name: 'Home' })">Visit our site</button>
        </div>
    </div>
</template>

<style>
    #attributeSurvey {
        max-width:  360px;
        margin:  0 auto;
    }
</style>

<script>
import { db } from "@/firebase";

export default {
    name: "Submit",
    data: function() {
        return {
            isLoaded: false,
            isSaved: true,
            user: null,
            sender: null,
            orderId: null,
            survey: {},
            selectedOption: {},
            surveyOptions: [],
            formSubmitted: false
        }
    },
    created: async function() {
        console.log("[created] query:",this.$route.query);

        if (this.$route.query.userId && this.$route.query.firstName) {
            await this.getUserData();
            await this.initSelectOption();
        }

        this.isLoaded = true;

    },
    methods: {
        initSelectOption: function() {
            this.selectedOption = this.surveyOptions.filter(x => x.name === this.$route.query.option)[0];
        },
        getUserData: async function() {

            // Get user data from Firebase, otherwise, return default
            var user = (await db.collection("users").doc(this.$route.query.userId).get()).data();
            var surveyOptions = user.surveyOptions;
            var survey = user.survey;

            if (survey) {
                Object.entries(survey).forEach(([key, value]) => {
                    // console.log(`{ ${key}: ${value} }`);
                    this.survey[key] = value;
                })
            }

            console.log("[getUserData] user:",user);

            this.survey.thankYouMessage = this.mreplace([
                    [/{{ customer_name }}/gm, this.$route.query.firstName],
                    [/{{ first_name }}/gm, this.$route.query.firstName],
                    [/{{ shop_name }}/gm, user.displayName],
                    [/{{ sender_name }}/gm, user.sender.name],
                    [/{{ order_id }}/gm, this.$route.query.orderId]
                    ],this.survey.thankYouMessage)

            this.survey.referralRequest = this.mreplace([
                    [/{{ customer_name }}/gm, this.$route.query.firstName],
                    [/{{ first_name }}/gm, this.$route.query.firstName],
                    [/{{ shop_name }}/gm, user.displayName],
                    [/{{ sender_name }}/gm, user.sender.name],
                    [/{{ order_id }}/gm, this.$route.query.orderId]
                    ],this.survey.referralRequest)

            this.surveyOptions = surveyOptions;
            this.sender = user.sender;


        },
    }
}
</script>