<template>
    <router-view @isSaved="isSaved"/>
</template>

<script>
export default {
  name: "Notifications",
  methods: {
    isSaved: function(value) {
      this.$emit("isSaved",value);
    }
  }
};
</script>