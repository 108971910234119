<script>
import { DoughnutChart, useDoughnutChart } from "vue-chart-3";
import { ref, computed, defineComponent } from "@vue/composition-api";
// import moment from "moment";

export default defineComponent({
  name: "PieChart",
  components: {
    DoughnutChart,
  },
  props: { 
    report: Object 
  },
  setup(props) {

    var totalCustomers = 0;
    var totalRevenue = 0;

    props.report.chartData.forEach(x => totalCustomers += x.totalCount);
    props.report.chartData.forEach(x => totalRevenue += x.totalValue);

    const imgData = ref(null);
    const options = computed(() => ({
        plugins: {
            legend: {
              display: true,
              position: 'right'
            },
            tooltip: {
                callbacks: {
                    label: function(context) {

                        let label = context.label || null;
                        if (label) { label += ': '; }
                        if (context.parsed !== null) { label += (context.parsed + "%") }

                        return label;
                    }
                }
            }
        }
    }));

    const chartData = computed(() => ({
      labels: props.report.chartData.map(x => { return x.label }),
      datasets: [{
        data: props.report.id == "customers" ? props.report.chartData.map(x => (x.totalCount/totalCustomers*100).toFixed(2)) : props.report.chartData.map(x => (x.totalValue/totalRevenue*100).toFixed(2)),
        borderWidth: 1,
        backgroundColor: props.report.chartData.map (x => x.backgroundColor),
      }]
    }));


    

    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData,
      options
    });

    return { doughnutChartProps, doughnutChartRef, imgData, chartData };

  },
  render: function (createElement) {

    console.log("[PieChart] chartData:",this.chartData)

    if (this.chartData) {
      return createElement(DoughnutChart, { props: this.doughnutChartProps });
    } else {
      return createElement('h1',"Loading...")
    }
  }
});
</script>