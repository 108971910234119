<template>
	<div class="content-wrapper">
		<div class="header">
			<div class="primary-heading">Privacy Policy</div>
		</div>
		<div class="body">
			<div>
				{{ appName }} "the App” provides an Attribution Data &amp; Reporting Platform "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.
			</div>

			<div class="text-sm text-weight-bold margin-top-20 margin-bottom-10">Personal Information the App Collects</div>

			<div>
				<p>When you install the App, we are automatically able to access certain types of information from your Shopify account: Read Orders, Read Customers</p>
				<p>Additionally, we collect the following types of personal information from you and/or your customers once you have installed the App: Information about you and others who may access the App on behalf of your store, such as your name, address, email address, phone number, and billing information; Information about individuals who visit your store, such as their IP address, web browser details, time zone, and information about the cookies installed on the particular device.</p>
				<p>We collect personal information directly from the relevant individual, through your Shopify account, or using the following technologies: “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</p>
			</div>

			<div class="text-sm text-weight-bold margin-top-20 margin-bottom-10">How Do We Use Your Personal Information?</div>

			<div>
				<p>We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services. </p>
			</div>

			<div class="text-sm text-weight-bold margin-top-20 margin-bottom-10">Sharing Your Personal Information</div>

			<div>
				<p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
				<p>Data Retention When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>
				<p>Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
				<p>Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at srbryers@gmail.com or by mail using the details provided below:</p>
				<p>3403 Merrie Lynn Ave, Austin TX 78722</p>
			</div>


		</div>
	</div>
</template>

<style scoped>
	p {
		margin-bottom: 10px;
		margin-top: 0;
	}
</style>

<script>
export default {
	name: "Privacy",
	data: function() {
		return {
			appName: "@ttribute"
		}
	}
};
</script>