<template>
  <div id="app" v-bind:class="[{ signedIn: signedIn && $route.name !== 'Home' && $route.name !== 'Submit' },{ siteNavigation: active_route === 'Home'}]">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;0,600;0,700;1,300;1,500;1,700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,500;0,600;0,700;1,300;1,500;1,700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Material+Icons" rel="stylesheet">
    <link rel="canonical" href="https://getattribute.com/" />
    <!-- <div class="spacer"></div> -->

    <router-view name="header" :signedIn="signedIn" :saved="saved" :class="['header flex', { unsaved: saved === false }]"></router-view>

    <div class="full-height">
      <div :id="$route.name" class="app-content">
        
        <router-view @signedIn="signIn" @isSaved="isSaved"/>
        
      </div>

      <router-view name="sidebar" :signedIn="signedIn"></router-view>
      
    </div>

    <Modal v-show="isModalVisible" @close="closeModal"></Modal>
  </div>
</template>

<script>
import Modal from './components/Modal.vue';
import { mapGetters } from "vuex";

export default {
  name: 'App',
  components: {
    Modal
  },
  data: function() {
    return {
      isModalVisible: false,
      saved: true,
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    root_route: function() { 
        var full_route = this.$route.path.split("/"); 
        full_route.shift(); full_route.pop()
        return full_route; 
    },
    active_route: function() { 
        // console.log("[APP] Active route:",this.$route);
        this.isSaved(true); // Set default state to saved on app navigation
        return this.$route.name; 
    },
    signedIn: function() {
      // console.log("[APP] signedIn:",this.user.data !== null ? true : false);
      return this.user.data !== null ? true : false;
    }
  },
  updated: function() {
    
  },
  methods: {
    showModal: function() {
      this.isModalVisible = true;
    },
    closeModal: function() {
      this.isModalVisible = false;
    },
    signIn: function(value) {
      if (value === false) { this.user.data = null; }
    },
    isSaved: function(value) {
      this.saved = value;
      // console.log("[APP] saved:",value);
    }
  },
};
</script>
