<template>
    <div class="content-wrapper">
        <!-- Header -->
        <div class="header">
            <div class="primary-heading">Your customers</div>
            <div class="text-sm flex flex-v-center">
                <span class="flex-1">Search all of your survey entries or export them to a CSV to build reports of your own.</span>
                <button class="button secondary outline padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 margin-left-20">
                    <download-csv :data="exportableEntries" name="attribute-export.csv" class="flex">
                        <div class="icon icon-download-icon text-lg margin-right-10"></div>
                        <span>Export</span>
                    </download-csv>
                </button>
            </div>
        </div>
        <div class="body" :class="{ hidden: !isLoaded }">
            <div class="">

                <!-- Search -->
                <div class="search-wrapper flex flex-align-end margin-bottom-20 background-primary">
                    <div class="input-group margin-bottom-0 flex-1">
                        <label>Search all entries</label>
                        <input type="search" class="search" v-model="searchPattern" placeholder="Search by number, date, email, etc." />
                    </div>
                    <!-- Columns -->
                    <div class="input-group margin-bottom-0 multi-select width-300 margin-left-15">
                        <label>Columns</label>
                        <!-- <v-select multiple :options="surveyOptions" label="name" v-model="selectedChannels"></v-select> -->
                        <multiselect v-model="databaseColumns.visible" 
                            :options="databaseColumns.available" 
                            track-by="id" label="label" 
                            :multiple="true" 
                            :close-on-select="false" 
                            :clear-on-select="false" 
                            :searchable="false">
                            <template slot="selection" slot-scope="{ values }"><span class="multiselect__single" v-if="values.length">{{ values.length }} / {{ databaseColumns.available.length }} selected</span></template>
                        </multiselect>
                    </div>
                    <!-- Pagination -->
                    <div class="pagination input-group multi-select single text-align-right margin-left-15">
                        <label>Records per page</label>
                        <multiselect v-model="recordLimit" 
                            class="full-width text-align-left"
                            :options="recordLimitOptions"
                            :close-on-select="true"
                            :searchable="false"
                            :showLabels="false"
                            :allow-empty="false"
                            @input="getEntries()"
                            >
                            <!-- <template slot="singleLabel" slot-scope="option">{{ option }}</template> -->
                        </multiselect>
                    </div>
                </div>

                <!-- Table -->
                <div class="table-wrapper flex">

                    <!-- Edit/More Actions -->
                    <div v-if="entrySelected" class="more-actions flex flex-v-center margin-left-20" v-bind:class="[{ open: moreActionsVisible }]">
                        <div class="selected-count padding-left-20 margin-left-5">{{ selectedEntries.length }} selected</div>
                        <div class="flex-1"></div>

                        <div class="editing-actions">
                            <div v-if="selectedEntries.length < 11">
                                <button class="button primary margin-right-10" @click="initBulkEditor()">Edit</button>
                            </div>
                            <div v-else>
                                <span class="margin-right-10 text-sm">Cannot edit more than 10 entries at a time</span>
                                <button class="button disabled inactive margin-right-10">Edit</button>
                            </div>
                        </div>
                        
                        <div class="more-actions-wrapper" @mouseenter="showActions()" @mouseleave="hideActions()">
                            <button class="button more-actions-button flex flex-v-center padding-10 margin-right-10">
                                <span>More actions</span>
                                <div class="icon icon-dropdown-icon"></div>
                            </button>
                            <div class="more-actions-list" :class="{ hidden: !moreActionsVisible }">
                                <div class="list-item" v-if="emailData && emailData.active" v-on:click="showModal('sendEmails')">Re-send Email</div>
                                <div class="list-item text-color-danger text-weight-bold" v-on:click="deleteEntries()">Delete</div>
                            </div>
                        </div>
                    </div>

                    <div class="table" :class="{ editing: isEditing }">

                        <div class="table-header">

                            <!-- Select -->
                            <div class="table-item-select" :class="{ checked: entrySelected }">
                                <input type="checkbox" v-on:click="!allSelected ? selectAll() : deselectAll()" :checked="selectedEntries.length > 0"/>
                            </div>

                            <!-- Primary Fields -->
                            <div v-for="column in databaseColumns.visible" v-bind:key="column.id" class="table-item" v-bind:class="[{ sorted: sortedBy === column.id }, sortDirection ]" v-on:click="sortEntries(column.id)"><span>{{ column.label }}</span></div>

                            <!-- Additional Fields -->
                            <div class="table-item" v-for="label in additionalFieldLabels" v-bind:key="`${label}-label`" v-html="label"></div>

                            <!-- Price -->
                            <div class="table-item" style="text-align: right;" v-bind:class="[{ sorted: sortedBy === 'total_price' }, sortDirection ]" v-on:click="sortEntries('total_price')"><span>Order Total</span></div>
                        </div>
                        <div class="table-row" v-for="entry in filteredEntries" v-bind:key="entry.id" v-bind:class="[{ selected: entry.isSelected }]">

                            <!-- Select -->
                            <div class="table-item-select" :class="{ checked: entry.isSelected }">
                                <input type="checkbox" v-on:click="selectEntry(entry)" v-bind:checked="entry.isSelected"/>
                            </div>

                            <!-- Primary Fields -->
                            <div v-for="(column, index) in databaseColumns.visible" v-bind:key="`${column.id}-${index}`" class="table-item">
                                <span v-if="column.id === 'order_name'"><a target="_blank" :href="entry.order_url">{{ entry[column.id] }}</a></span>
                                <span v-else>{{ entry[column.id] }}</span>
                            </div>

                            <!-- Additional Fields -->
                            <div class="table-item" v-for="label in additionalFieldLabels" v-bind:key="`${label}-label`">
                                <div v-for="field in entry.additionalFields" v-bind:key="`${field.id}-value`">
                                    {{ field.value }}
                                </div>
                            </div>
                            

                            <!-- Price -->
                            <div class="table-item text-align-right" v-html="Number(entry.total_price).toFixed(2)"></div>
                        </div>
                        <div class="table-footer">
                            <div class="table-item">Total</div>
                            <div class="table-item">{{ entriesTotalCount }}</div>
                            <div class="table-item" v-for="(column, index) in databaseColumns.visible" v-bind:key="`footer-column-${index}`" :class="{ hidden: index <= 1 }"></div>
                            <div class="table-item" v-for="label in additionalFieldLabels" v-bind:key="`${label}-total`"></div> 
                            <div class="table-item text-align-right">{{ entriesTotalRevenue.toFixed(2) }}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- MODAL: Send Test Modal -->
        <Modal v-if="modals.sendEmails.visible" @close="closeModal">

            <template v-slot:header>
                <div class="heading text-md text-weight-bold">Re-send emails to your customers</div>
            </template>

            <template v-slot:body>
              <div class="wrapper max-width-450">
                <div class="margin-bottom-15">Please confirm that you would like to resend emails to the customers listed below.</div>
                <div class="input-group inline-list margin-none">
                  <div class="email-input">
                    <div class="pills flex flex-row-wrap">
                      <div v-for="(email, index) in selectedEmails" v-bind:key="`email-${index}`" class="pill removable margin-bottom-10 margin-right-10 padding-10 flex flex-v-center">
                          <span class="flex-1">{{ email }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:footer>
                <div class="actions">
                   <button class="button primary margin-none" v-on:click="sendEmail()" v-bind:class="{ 'disabled outline': selectedEmails.length < 1 || sentEmails }">
                     <span v-if="!sendingEmails && !sentEmails">Confirm & Send</span>
                     <span v-else-if="sentEmails">Sent!</span>
                     <span v-else>Sending...</span>
                   </button>
                </div>
            </template>
        </Modal>

        <!-- Save Button -->
        <div class="saveButton" v-bind:class="{ unsaved: !isSaved }">
            <button v-if="!isSaved" class="button padding-top-10 padding-bottom-10 padding-left-20 padding-right-20 margin-left-20" v-on:click="saveUserData()">
              <span v-if="isSaving">Saving...</span>
              <span v-else>Save</span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
/*#app #content {
    max-width: 100%;
}*/
.content-wrapper {
    max-width: initial !important;
}
.table {
    max-height: 62vh;
}
.table-header {
    position: sticky;
    top: 0;
    z-index: 2;
}
</style>

<script>
import { mapGetters } from "vuex"
import { functions, db } from "@/firebase"
import firebase from "@firebase/app"
import Fuse from "fuse.js"
import JsonCSV from 'vue-json-csv'
import Modal from '@/components/Modal.vue'
import NProgress from 'nprogress'
import Multiselect from 'vue-multiselect'

const sendEmail = functions.httpsCallable('sendEmail');

export default {
    name: "Database",
    data: function() {
        return {
            userData: {},
            entries: [],
            filteredEntries: [],
            additionalFieldLabels: [],
            entriesTotalRevenue: 0,
            entriesTotalCount: 0,
            searchPattern: "",
            sortDirection: "desc",
            sortedBy: "created_at",
            isLoaded: false,
            isSaved: true,
            isSaving: false,
            isEditing: false,
            entrySelected: false,
            allSelected: false,
            moreActionsVisible: false,
            sendingEmails: false,
            sentEmails: false,
            selectedEmails: [],
            recordLimit: 25,
            recordLimitOptions: [ 25, 50, 100 ],
            databaseColumns: {
                available: [
                    { id: "created_at", label: "Created At" },
                    { id: "order_name", label: "Order Number", type: "link" },
                    { id: "order_email", label: "Order Email" },
                    { id: "customer_name", label: "Customer Name" },
                    { id: "referralOption", label: "Referred By" },
                    { id: "writeInValue", label: "Referred By: Detail" },
                    { id: "product_titles", label: "Product Titles" },
                    { id: "variant_skus", label: "SKUs" },
                    { id: "variant_ids", label: "Variant Ids" },
                    { id: "state", label: "State/Province" },
                    { id: "country", label: "Country" },
                    { id: "tags", label: "Order Tags" }
                ],
                visible: [
                    { id: "created_at", label: "Created At" },
                    { id: "order_name", label: "Order Number", type: "link" },
                    { id: "order_email", label: "Order Email" },
                    { id: "customer_name", label: "Customer Name" },
                    { id: "referralOption", label: "Referred By" },
                    { id: "writeInValue", label: "Referred By: Detail" },
                    { id: "product_titles", label: "Product Titles" },
                    { id: "tags", label: "Order Tags" }
                ]
            },
            modals: {
                sendEmails: {
                    visible: false
                }
            }
        }
    },
    components: {
        downloadCsv: JsonCSV,
        Modal,
        Multiselect
    },
    computed: {
        ...mapGetters({
          // map `this.user` to `this.$store.getters.user`
          user: "user"
        }),
        selectedEntries: function() {
            return this.filteredEntries.filter(x => x.isSelected === true);
        },
        exportableEntries: function() {
            return this.filteredEntries.map (x => {
                return {
                    id: String(x.id),
                    created_at: x.created_at,
                    order_id: x.order.id,
                    order_number: x.order.name,
                    order_email: x.order_email,
                    customer_name: x.customer_name,
                    total_price: x.total_price,
                    referral_option: x.referralOption,
                    referral_detail: x.writeInValue,
                    product_titles: x.product_titles,
                    variant_skus: x.variant_skus,
                    variant_ids: x.variant_ids,
                    state: x.state,
                    country: x.country,
                    additionalInfo_birthday: x["additionalField-birthday"]
                }
            })
        }
    },
    created: async function() {
        NProgress.start();
        await this.getEntries(); // from main.js
        await this.getUserData();
        await this.getColumns();
        
        console.log("Entries Next:",this.next);

        this.isSaved = true;
        this.isLoaded = true;
        NProgress.done();
    },
    methods: {
        getUserData: async function() {
            // Get user data from Firebase, otherwise, return default
            this.userData = (await firebase.getUser("email", this.user.data.email))[0];
            this.emailData = this.userData.emails ? this.userData.emails[0] : undefined;
            this.isSaved = true;
        },
        getColumns: function() {
            // console.log("[getColumns] databaseColumns",this.user.data);
            if (this.user.data.config && this.user.data.config.databaseColumns) {
                this.databaseColumns.visible = this.user.data.config.databaseColumns.visible; 
            }
        },
        saveUserData: function() {
            // Compile data to be saved
            var vm = this;
            var user = db.collection("users").doc(vm.user.data.id);
            var data = { 
                config: { 
                    databaseColumns: { 
                        visible: vm.databaseColumns.visible 
                    } 
                }
            }

            return user.update(data)
            .then(function() {
              // console.log("[MAIN] Successfully updated user:",data);
              vm.isSaved = true;
            })
            .catch(function(error) {
              console.error("[DATABASE] Error updating user:",error);
            })
        },
        searchEntries: function(pattern) {
          const options = {
            keys: [
              "referralOption",
              "writeInValue",
              "order_name",
              "order_email",
              "customer_name",
              "product_titles",
              "product_skus"
            ]
          };

          // Add additional fields to search keys
          this.additionalFieldLabels.forEach(function(label) {
            options.keys.push("additionalField-"+label);
          })

          const fuse = new Fuse(this.entries, options);

          return fuse.search(pattern);
        },
        sortEntries: function(key) {
            this.sortedBy = key;
            if (this.sortDirection === "desc") { this.sortDirection = "asc"; } else { this.sortDirection = "desc"; }
            this.filteredEntries = this.sortList(this.filteredEntries, key, this.sortDirection);
        },
        batchDeleteEntries: function() {
            var entriesToDelete = this.filteredEntries.filter(x => x.isSelected);
            console.log("delete entries:",entriesToDelete);
            var batch = db.batch();
            var entry, entryRef;
            var vm = this;

            for (var i = 0; i < entriesToDelete.length; i++) {
                entry = entriesToDelete[i];
                entryRef = db.collection("users").doc(this.user.data.id).collection("surveyEntries").doc(entry.id);
                batch.delete(entryRef)
            }

            batch.commit().then(() => {
                vm.isSaving = false;
                vm.isSaved = true;
                // console.log("Successfully deleted entries:",entriesToDelete);
            })
        },
        saveDatabase: async function() {

            this.isSaving = true;
            this.isSaved = true;
            this.isEditing = false;

        },
        deselectAll: function() {
            this.allSelected = false;
            this.entrySelected = false;
            this.filteredEntries = this.filteredEntries.map(x => { x.isSelected = false; return x;})
        },
        selectAll: function() {
            this.allSelected = true;
            this.entrySelected = true;
            this.filteredEntries = this.filteredEntries.map(x => { x.isSelected = true; return x;})
        },
        selectEntry: function(entry, isSelected) {
            
            var vm = this;
            if (!entry.isSelected) { isSelected = true; } else { isSelected = false; }
            vm.filteredEntries = vm.filteredEntries.map(x => { if (x.id === entry.id) { x.isSelected = isSelected; } return x; } )
            vm.entrySelected = false;

            vm.filteredEntries.forEach(function(item) {
                if (item.isSelected) {
                    vm.entrySelected = true;
                }
            })
        },
        showActions: function() {
            this.moreActionsVisible = true;
        },
        hideActions: function() {
            this.moreActionsVisible = false;
        },
        deleteEntries: async function() {
            var confirm = window.confirm("Are you sure want to delete these entries? This is permanent, and you will also have to save your database to confirm the deletion process.");
            if (confirm) {
                this.isSaving = true;
                // console.log("Confirmed: delete entries");
                await this.batchDeleteEntries();
                this.filteredEntries = this.filteredEntries.filter(x => !x.isSelected);
            } else {
                console.log("Canceled: Do not delete entries");
            }
            
        },
        initBulkEditor: function() {
            var entryIds = encodeURIComponent(this.selectedEntries.map(entry => entry.id).join(","));
            this.$router.push({ name: "BulkEditor", query: { model: "database", ids: entryIds }});
        },
        sendEmail: async function() {
            
            this.sendingEmails = true;

            for (var i in this.selectedEntries) {

                var entry = this.selectedEntries[i];

                // console.log("[Database] emailData:",this.emailData);

                await sendEmail({
                  to: entry.order_email,
                  senderName: this.emailData.content.senderName,
                  from: this.emailData.content.from,
                  subject: this.emailData.content.subject,
                  bodyHtml: this.emailData.content.bodyHtml,
                  customerName: entry.customer_name
                }).then(function(response) {
                  console.log("Sent Email:",response);
                  // vm.sendingTest = false;
                }).catch(function(err) {
                  console.log("Error:",err);
                })

            }

            this.sendingEmails = false;
            this.sentEmails = true;
        },
        showModal: function(modal) {
          this.modals[modal].visible = true;
        },
        closeModal: function() {
          for (const key in this.modals) {
            console.log("key",key)
            this.modals[key].visible = false;
          }
        },
    },
    watch: {
      searchPattern: {
        handler: function(value) {
          var vm = this;
          this.entriesTotalCount = 0;
          this.entriesTotalRevenue = 0;

          value == "" ? this.filteredEntries = this.entries : this.filteredEntries = this.searchEntries(value).map(x => x.item);

          this.filteredEntries.forEach(function(entry) {
              vm.entriesTotalCount += 1;
              vm.entriesTotalRevenue += Number(entry.total_price);
          }) 
        }
      },
      selectedEntries: {
        handler: function() {
            this.sentEmails = false;
            this.selectedEmails = this.selectedEntries.map(x => x.order_email);
        }
      },
      "databaseColumns.visible": {
        handler: function() {
            this.isSaved = false;
        }
      },
      isSaved: {
        handler(value) {
          this.$emit("isSaved",value);
        }
      }
    }
};
</script>