import Link from "@tiptap/extension-link"
// import { mergeAttributes } from "@tiptap/core"

export default Link.extend({
    name: 'custom-link',

    addAttributes() {
        return {
            href: {
              parseHTML: element => element.getAttribute("href")
            },
            text: {
              parseHTML: element => {
                return element.getAttribute("data-text") || "";
              },
              renderHTML: attributes => {
                return {
                  "data-text": attributes.text
                }
              }
            },
            type: {
              // default: "link",
              parseHTML: element => {
                return element.getAttribute("data-type") || "link";
              },
              renderHTML: attributes => {
                return {
                  "data-type": attributes.type
                }
              }
            },
            textcolor: { 
              parseHTML: element => {
                // console.log("[textColor] element:",element)
                return element.getAttribute("data-textcolor") || "white";
              },
              renderHTML: attributes => {
                return {
                  "data-textcolor": attributes.textcolor,
                  "style": `color: ${attributes.textcolor}`
                }
              }
            },
            background: { 
              parseHTML: element => {
                return element.getAttribute("data-background") || "darkblue";
              },
              renderHTML: attributes => {
                return {
                  "data-background": attributes.background,
                  "style": `background: ${attributes.background}`
                }
              }
            },
            width: { 
              parseHTML: element => {
                return element.getAttribute("data-width") || "auto";
              },
              renderHTML: attributes => {
                return {
                  "data-width": attributes.width,
                  "style": `width: ${attributes.width}`
                }
              }
            },
        }
    },

    addCommands() {
        return {
            setLink: (options) => ({ tr, commands }) => {
              // console.log("[setLink]:",options)

              if (commands.extendMarkRange('custom-link') || tr.selection?.node?.type?.name == 'custom-link') {
                commands.deleteSelection(); // delete the old button/link
              }

              return commands.insertContent(`
                <a href="${options.href}" 
                   data-type="${options.type}" 
                   data-text="${options.text}"
                   data-textcolor="${options.textcolor}"
                   data-background="${options.background}"
                   data-width="${options.width}"
                   >${options.text}</a>`); 
            }
        }
    },

    renderHTML({ HTMLAttributes }) {

        // console.log("custom-link node.attrs:",node);
        // console.log("custom-link HTMLAttributes:",HTMLAttributes);

        HTMLAttributes.class = `custom-link`;

        return ['a',HTMLAttributes, 0]
        
    }
})