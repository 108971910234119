<template>
    <div class="content-wrapper">

        <!-- Header -->
        <div class="header">
            <div class="primary-heading margin-bottom-20">Design your attribution survey</div>
            <div class="text-sm">Use the editor below to change how your post-checkout survey looks and functions.</div>
        </div>
        <div class="body two-columns" :class="{ hidden: !isLoaded }">

            <div id="configuration" class="column padding-right-40">
                <!-- Is active -->
                <div class="card-row flex flex-v-center margin-bottom-15">
                  <div class="flex-1 flex flex-v-center">
                      <h3 class="text-weight-md margin-bottom-0 margin-right-10">Configuration</h3>
                      <div class="pill" :class="[{ 'active': survey.active }, { 'inactive': !survey.active }]">
                        <span v-if="survey.active">Active</span>
                        <span v-else>Inactive</span>
                      </div>
                  </div>
                  <div class="input-group switch flex flex-v-center margin-0">
                    <label class="switch" v-bind:class="{ inactive: !activePlan }">
                      <input type="checkbox" v-model="survey.active" v-if="activePlan">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>

                <!-- Warning: Choose a plan -->
                <div v-if="!activePlan && isLoaded" class="info-box warning margin-bottom-20 clickable">
                  <span class="icon icon-info-icon"></span>
                  <div>
                    <div class="text-md text-weight-med heading">
                        <span>Your survey is currently inactive.</span>
                    </div>
                    <div class="body">Please <router-link :to="{ name: 'Plan' }">select a plan</router-link> to activate your survey.</div>
                  </div>
                </div>

                <!-- Warning: Deactivate survey -->
                <div v-else-if="!survey.active && isLoaded" class="info-box warning margin-bottom-20">
                  <span class="icon icon-info-icon"></span>
                  <div>
                      <div class="text-md text-weight-med heading">
                        <span>Your survey </span><span v-if="!isSaved">will be deactivated!</span><span v-else>is currently inactive.</span>
                    </div>
                      <div class="body">The survey will <b>not be displayed</b> in the shopify checkout.</div>
                  </div>
                </div>

                <!-- Config -->
                <div class="">

                    <div class="card-row">
                        <div class="input-group textarea">
                            <label>Question text</label>
                            <textarea type="text" v-model="survey.referralRequest"/>
                        </div>
                        <div class="flex">
                            <div class="input-group text flex-1 margin-right-10">
                                <label>Question label</label>
                                <input type="text" v-model="survey.questionLabel"/>
                            </div>
                            <div class="input-group checkbox display-block">
                                <label>Required?</label>
                                <input type="checkbox" v-model="survey.questionRequired" class="flex-1"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-row">
                        <div class="input-group draggable">
                            <label>Options</label>
                            <draggable v-model="surveyOptions" draggable=".option" @change="setListOrder(surveyOptions)" class="draggable-wrapper">
                                <div v-for="option in surveyOptions" v-bind:key="option.order" :class="[ 'option', { editing: option.editing }]">
                                    <div class="dragger icon icon-menu-icon color-grey-20"></div>
                                    <!-- <div class="option-value" v-html="option.name"></div> -->
                                    <!-- <span class="close color-primary clickable" v-on:click="removeSurveyOption(option)">Remove</span> -->
                                    <input v-model="option.name"
                                           class="option-input text-weight-mid"
                                           type="text"
                                           autofocus>
                                    <a v-if="!option.editing" class="edit-button margin-right-10" v-on:click="editSurveyOption(option)">Edit</a>
                                    <a v-if="!option.editing" class="remove-button icon icon-trash-2-icon" v-on:click="showRemoveOptionModal(option)"></a>
                                    <div :class="[ 'edit-option' ]">
                                        <div class="checkbox margin-right-15">
                                            <input type="checkbox" name="yes" v-model="option.isWriteIn" class="margin-right-10"/>
                                            <span>Allow write-in text field</span>
                                        </div>
                                        <div v-if="option.isWriteIn" class="write-in input-group">
                                            <label>Answer text prompt</label>
                                            <input type="text" v-model="option.writeInPrompt" placeholder="e.g. Which influencer?"/>
                                        </div>
                                        <div class="actions flex flex-justify-end full-width flex-v-center" :class="{ 'margin-top-20': option.isWriteIn }">
                                            <a class="unformatted margin-right-20" v-on:click="cancelEditSurveyOption(option)">Cancel</a>
                                            <button class="button primary" v-on:click="saveSurveyOption(option)">Save</button>
                                        </div>
                                    </div>
                               </div>
                            </draggable>
                            <div class="actions margin-top-10">
                                <button class="button full-width flex flex-v-center flex-h-center primary" v-on:click="addSurveyOption()">
                                    <span class="icon icon-plus-circle-icon color-white margin-right-10"></span>
                                    <span>Add an option</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Button -->
                    <div class="card-row flex margin-top-20">
                        <div class="input-group textarea flex-1">
                            <label>Button Text</label>
                            <input type="text" v-model="survey.submitButtonText"/>
                        </div>
                        <div class="input-group color margin-left-10">
                            <label>Color</label>
                            <input type="color" v-model="survey.submitButtonColor"/>
                        </div>
                        
                    </div>

                    <div class="divider"></div>

                    <!-- Additional Details: Birthday -->
                    <div class="heading">
                        <h3>Additional Fields</h3>
                    </div>
                    <div class="card-row flex">
                        <div v-for="field in survey.additionalFields" v-bind:key="field.id" class="flex flex-1">
                            <div class="input-group text margin-right-10">
                                <label>Label</label>
                                <input type="text" v-model="field.label" class="flex-1"/>
                            </div>
                            <div class="input-group select flex-1 margin-right-10">
                                <label>Field Type</label>
                                <select v-model="field.type" class="full-width">
                                    <option value="text">Text</option>
                                    <option value="date">Date</option>
                                    <option value="number">Number</option>
                                </select>
                            </div>
                            <div class="input-group checkbox display-block">
                                <label>Active</label>
                                <input type="checkbox" v-model="field.visible" class="flex-1"/>
                            </div>
                        </div>
                    </div>

                    <div class="divider"></div>

                    <div class="card-row">
                        <div class="input-group code">
                            <label>Custom CSS</label>
                            <prism-editor v-model="survey.customCss" class="min-height-200" :highlight="highlighter" line-numbers language="css"></prism-editor>
                        </div>
                    </div>
                </div>
            </div>

            <div id="preview" class="column padding-right-40 sticky" :class="{ unsaved: !isSaved }">
                <h3 class="text-weight-md margin-bottom-15 margin-right-10">Preview</h3>
                <div id="attributeSurvey" class="card background-alternate preview padding-30">
                    <div class="order">
                        <div class="order-information">
                            <div class="order-number">Order #110011</div>
                            <div class="order-message">{{ survey.thankYouMessage }}</div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="message">{{ survey.referralRequest }}</div>

                        <!-- Referral Question -->
                        <div class="input-group select" :class="{ 'required': survey.questionRequired }">
                            <label v-html="survey.questionLabel"></label>
                            <select class="full-width input"  v-model="selectedOption" :data-required="survey.questionRequired">
                                <option value="" disabled selected>Please select an option</option>
                                <option v-for="option in surveyOptions" v-bind:key="option.name" v-bind:value="option">{{ option.name }}</option>
                            </select>
                            <div class="icon icon-chevron-down-icon text-lg v-center margin-top-10"></div>
                        </div>
                        <div class="input-group" v-if="selectedOption.isWriteIn">
                            <input type="text" v-model="selectedOption.writeInValue" :placeholder="selectedOption.writeInPrompt ? selectedOption.writeInPrompt : 'Write your answer here...'"/>
                        </div>

                        <!-- Additional Fields -->
                        <div class="input-group" v-for="field in survey.additionalFields" v-bind:key="field.id" v-bind:class="[ field.type ]">
                            <div v-if="field.visible">
                                <label v-html="field.label"></label>
                                <input :type="field.type" class="input"/>
                            </div>
                        </div>

                        <!-- Actions -->
                        <div class="actions">
                            <button class="btn button" 
                                v-bind:style="`background-color: ${survey.submitButtonColor}; border: 1px solid ${survey.submitButtonColor};`" 
                                v-on:click="formSubmitted = !formSubmitted" 
                                v-bind:class="[{ 'inactive': formSubmitted || !formIsValid }]">
                                <span v-if="!formSubmitted">{{ survey.submitButtonText }}</span>
                                <span v-else>Thanks!</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="text-sm text-weight-bold margin-bottom-20 margin-top-40">Integrations</div> -->
                <!-- <div class="text-sm">Review your <router-link :to="{ name: 'Integrations'}" class="text-weight-bold">integrations here</router-link>.</div> -->
            </div>

        </div>

        <!-- Save Button -->
        <div class="saveButton" v-bind:class="{ unsaved: !isSaved }">
            <button class="button full-width primary" v-on:click="saveUserData()" v-if="!isSaved">Save</button>
        </div>

        <!-- MODAL: Verify Sender Modal -->
        <Modal v-if="removeOptionModal.visible" @close="closeModal">

            <template v-slot:header>
                <div class="wrapper flex flex-v-center full-width">
                    <div class="icon text-lg margin-right-10 icon-trash-2-icon color-primary"></div>
                    <div class="heading text-md text-weight-bold line-height-0">Delete option</div>
                </div>
            </template>

            <template v-slot:body>
              <div class="wrapper width-300">
                <div>Are you sure you want to delete this option? This will permanently erase this option.</div>
              </div>
            </template>

            <template v-slot:footer>
                <div class="actions flex flex-justify-end flex-v-center">
                   <a class="unformatted margin-right-20" @click="closeModal">Cancel</a>
                   <button class="button primary" v-on:click="removeSurveyOption(removeOptionModal.option)">Delete</button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<style scoped>
    @import "../styles/external.css";

    .sticky { top: 100px; }
    .sticky.unsaved { top: 150px; }

    #preview #attributeSurvey {
        margin:  0;
    }

</style>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { db, functions } from "@/firebase";
import { firebase } from "@firebase/app";
import { PrismEditor } from 'vue-prism-editor';
import Prism from "prismjs";
import Modal from '@/components/Modal.vue';
import NProgress from 'nprogress';

import externalCss from "!!raw-loader!@/styles/external.css"; // load the css for the quiz
import "vue-prism-editor/dist/prismeditor.min.css"; 
import "prismjs/themes/prism.css";

const scriptTag = functions.httpsCallable('scriptTag');

export default {
    name: "Survey",
    components: {
        draggable,
        PrismEditor,
        Modal
    },
    computed: {
        ...mapGetters({
          user: "user"
        }),
        formIsValid: function() {
            // Check if the main survey question is required
            if (this.survey.questionRequired && this.selectedOption === "") {
                return false;
            } else {
                return true;
            }
        }
    },
    data: function() {
        return {
            isSaved: true,
            isLoaded: false,
            activePlan: false,
            removeOptionModal: {
                visible: false,
                option: {}
            },
            selectedOption: {},
            surveyOptions: [],
            defaultSurveyOptions: [
                { name: "Instagram", order: 0 },
                { name: "Google", order: 1 },
                { name: "Facebook", order: 2 },
                { name: "YouTube", order: 3 },
                { name: "TikTok", order: 4 },
                { name: "Friends/Family", order: 5 }
            ],
            survey: {
                active: false,
                referralRequest: "How did you find out about {{ shop_name }}?",
                questionLabel: "How did you hear about us?",
                questionRequired: false,
                thankYouMessage: "Thank you {{ customer_name }}!",
                submitButtonText: "Submit",
                submitButtonColor: "#000",
                customCss: null,
                additionalFields: [
                    { id: "birthday", label: "Birthday", type: "date", visible: false, placeholder: "yyyy-mm-dd"  }
                ]
            },
            formSubmitted: false
        }
    },
    created: async function() {
        
        // await scriptTag({
        //     method: "DELETE",
        //     userId: this.user.data.id,
        //     environment: this.environment,
        //     data: {
        //         "scriptTagId": 188996419782,
        //     }
        // });

    },
    mounted: async function() {

        NProgress.start();

        await this.getUserData();
        await this.initCustomCss();
        await this.initSelectOption();

        // Check if we have an active plan
        if (this.user.data.id !== "vg0ZjYhdtjj9zc0R02ka" && !this.user.data.billing || (this.user.data.billing && this.user.data.billing.selectedPlan === null)) {
            this.survey.active = false; this.activePlan = false; await this.saveUserData(); 
        } else { 
            this.activePlan = true; 
        }

        if (this.user.data.id == "vg0ZjYhdtjj9zc0R02ka") {
            this.activePlan = true;
            await this.saveUserData();
        }


        this.isSaved = true;
        this.isLoaded = true;

        NProgress.done();

    },
    methods: {
        ...mapMutations({
            setUserData: "SET_USER_DATA"
        }),
        updateOnboardingStep: function(stepId, value) {

            var onboardingSteps = this.user.data.onboardingSteps;
            var stepIndex = onboardingSteps.findIndex(x => x.id === stepId);
            onboardingSteps[stepIndex].complete = value;

            this.setUserData({
                key: "onboardingSteps",
                value: onboardingSteps
            });

        },
        addSurveyOption: async function() {
            await this.surveyOptions.push({ name: "", order: (this.surveyOptions.length+1), editing: true });
            this.editSurveyOption(this.surveyOptions[this.surveyOptions.length]);
            var options = document.getElementsByClassName('option-input');
            options[options.length-1].focus();
        },
        editSurveyOption: async function(option) {
            
            option.originalValue = String(option.name);

            if (option.editing === true) {
                option.editing = false;
            } else {
                option.editing = true;
            }
            this.surveyOptions = JSON.parse(JSON.stringify(this.surveyOptions));
            // console.log("option:",option)
        },
        cancelEditSurveyOption: function(option) {
            option.name = option.originalValue;
            option.editing = false;
            this.surveyOptions = JSON.parse(JSON.stringify(this.surveyOptions));
        },
        saveSurveyOption: function(option) {
            option.editing = false;
            this.surveyOptions = JSON.parse(JSON.stringify(this.surveyOptions));
            this.saveUserData();
        },
        removeSurveyOption: function(option) {
            this.surveyOptions = this.surveyOptions.filter(x => { if (x.name !== option.name) { return x; }});
            this.closeModal();
        },
        showRemoveOptionModal: function(option) {
            this.removeOptionModal.option = option;
            this.removeOptionModal.visible = true;
        },
        closeModal: function() {
            this.removeOptionModal.visible = false;
        },
        initSelectOption: function() {
            this.selectedOption = "";
        },
        getUserData: async function() {

            // Get user data from Firebase, otherwise, return default
            var user = await firebase.getUser("email", this.user.data.email);
            var surveyOptions = user[0].surveyOptions;
            var survey = user[0].survey;

            if (survey) {
                Object.entries(survey).forEach(([key, value]) => {
                    // console.log(`{ ${key}: ${value} }`);
                    this.survey[key] = value;
                })
            }

            this.surveyOptions = surveyOptions ? surveyOptions : this.user.data.defaultSurveyOptions;

        },
        saveUserData: async function() {
            // console.log("save survey options:",this.surveyOptions);
            var vm = this;
            var user = db.collection("users").doc(vm.user.data.id);
            var surveyTemplate = await vm.createSurveyTemplate();
            var data = { 
                surveyOptions: vm.surveyOptions, 
                surveyTemplate: surveyTemplate,
                survey: vm.survey,
            };

            this.updateOnboardingStep("design",true);

            data.onboardingSteps = this.user.data.onboardingSteps;

            return user.update(data)
            .then(function() {
              // vm.shopifyStoreName = vm.activeUser.auth.shopify.id; 
              // console.log("Successfully updated user:",data);
              vm.isSaved = true;
            })
            .catch(function(error) {
              console.error("Error updating user:",error);
            })
        },
        createSurveyTemplate: async function() {
            
            var vm = this;
            var options = "";
            var additionalFields = "";

            for (var i in vm.surveyOptions) {
                var option = vm.surveyOptions[i];
                options += `<option data-write-in="${ option.isWriteIn ? option.isWriteIn : false }" data-placeholder="${ option.writeInPrompt ? option.writeInPrompt : 'Write your answer here...' }">${ option.name }</option>\n`;
            }

            console.log("[createSurveyTemplate] additionalFields:",vm.survey.additionalFields)

            for (var j in vm.survey.additionalFields) {
                var field = vm.survey.additionalFields[j];
                var placeholder = field.placeholder;
                if (field.visible) {
                    if (field.type === "date") { placeholder = "yyyy-mm-dd"; }
                    additionalFields += `
                        <div class="input-group ${field.type}">
                            <label>${field.label}</label>
                            <input id="${field.id}" type="${field.type}" name="additionalField-${field.id}" placeholder="${placeholder}"/>
                        </div>`;
                }
            }

            // Make sure that we set a value for the questionRequired
            vm.survey.questionRequired = vm.survey.questionRequired !== undefined ? vm.survey.questionRequired : false;
            var surveyRequiredClass = vm.survey.questionRequired ? " required" : "";
            var buttonClass = vm.survey.questionRequired ? " inactive" : "";

            var template = `
                <form id="attributeSurvey" class="full-width">
                    <div class="card">
                        <div class="form">
                            <div class="message">${vm.survey.referralRequest}</div>
                            <div class="input-group select ${surveyRequiredClass}">
                                <label>${vm.survey.questionLabel}</label>
                                <select id="referralOption" class="full-width input" name="referralOption" data-required="${vm.survey.questionRequired}">
                                    <option value="" disabled selected>Please select an option</option>
                                    ${options}
                                </select>
                            </div>
                            <div id="writeInValue" class="input-group">
                                <input type="text" name="writeInValue" placeholder="Write your answer here..." class="input"/>
                            </div>
                            <div id="additionalFields">${additionalFields}</div>
                            <div class="actions">
                                <button class="btn button${buttonClass}" type="submit" style="background-color: ${vm.survey.submitButtonColor}; border: 1px solid ${vm.survey.submitButtonColor};">${vm.survey.submitButtonText}</button>
                            </div>
                        </div>
                    </div>
                </form>
                <style>${externalCss}</style>
                <style>${vm.survey.customCss}</style>
            `

            // console.log("[createSurveyTemplate] template:",template);

            return template;

        },
        setListOrder: function(list) {
          
          list = list.map((x, index) => { x.order = index; return x; })
          // console.log("list",list);
          this.isSaved = false;
          return list;
        },
        initCustomCss: function() {
            var styleNode = document.createElement("style");
            styleNode.id = "customCss";
            styleNode.innerHTML = this.survey.customCss;
            document.getElementById("attributeSurvey").append(styleNode);
        },
        updateCustomCss: function() {
            var styleNode = document.getElementById("customCss");
            if (styleNode) {
                styleNode.innerHTML = this.survey.customCss;
            }
            
        },
        updateOrCreateScriptTag: async function() {

            var existingScriptTags = await scriptTag({
                method: "GET",
                userId: this.user.data.id,
                environment: this.environment,
                params: `?src=https://cdn.getattribute.com/attribute.js?userId=${this.user.data.id}`
            }).then((response) => {
                return response;
            }).catch((err) => {
                console.log("[scriptTag] GET error:",err);
                return;
            })

            console.log("[updateOrCreateScriptTag] existingScriptTags:",existingScriptTags);

            // If we already have a script tag, update it
            if (existingScriptTags.data.script_tags.length === 0) {
                // Otherwise, create a new one
                await scriptTag({
                    method: "POST",
                    userId: this.user.data.id,
                    environment: this.environment,
                    data: {
                        "script_tag": {
                            "event": "onload",
                            "src": `https://cdn.getattribute.com/attribute.js?userId=${this.user.data.id}`
                        }
                    }
                }).then((response) => {
                    // console.log("[scriptTag] POST response:",response);
                    return response;
                }).catch((err) => {
                    console.log("[scriptTag] POST error:",err);
                })
            } else {
                // Otherwise, create a new one
                await scriptTag({
                    method: "PUT",
                    userId: this.user.data.id,
                    environment: this.environment,
                    scriptTagId: existingScriptTags.data.script_tags[0].id,
                    data: {
                        "scriptTagId": existingScriptTags.data.script_tags[0].id,
                        "script_tag": {
                            "event": "onload",
                            "src": `https://cdn.getattribute.com/attribute.js?userId=${this.user.data.id}`
                        }
                    }
                }).then((response) => {
                    console.log("[scriptTag] PUT response:",response);
                    return response;
                }).catch((err) => {
                    console.log("[scriptTag] PUT error:",err);
                })
            }

        },
        highlighter(code) {
          // js highlight example
          return Prism.highlight(code, Prism.languages.css, "CSS");
        }
    },
    watch: {
        isSaved: {
          handler: async function (value) {
            this.$emit("isSaved",value);

            if (this.survey.active && value) {
                // console.log("[isSaved] survey is active && saved, push script and template to Shopify.");
                await this.updateOrCreateScriptTag();   
            }
          }
        },
        surveyOptions: {
            handler: function() {
                this.isSaved = false;
            },
            deep: true
        },
        survey: {
            handler: function() {
                this.updateCustomCss();
                this.isSaved = false;
            },
            deep: true
        }
    }
};
</script>