<template>
    <div class="content-wrapper">
        <div class="header">
            <div class="primary-heading margin-bottom-20">Integrate your data</div>
            <div class="text-sm">Send your data to the platform of your choice using one of our simple integrations.</div>
        </div>
        <div class="body"  :class="{ hidden: !isLoaded }">
            <div id="integrations" class="margin-bottom-40">

                <div class="integration-wrapper background-white border-1 border-radius-8 margin-bottom-20 max-width-750" v-for="integration in integrations" v-bind:key="integration.handle" :class="checkIntegrationRequirements(integration)">
                    <div class="flex flex-v-center" v-bind:class="[ checkIntegrationStatus(integration) ]">
                        <div class="logo padding-20 margin-right-15">
                            <img v-bind:src="integration.icon"  class="full-height"/>
                        </div>
                        <div class="description margin-right-15">
                            <div class="flex flex-v-center margin-bottom-5">
                                <div class="heading margin-right-10">{{ integration.name }}</div>
                                <div class="status pill" :class="[ checkIntegrationStatus(integration) ]">{{ checkIntegrationStatus(integration) }}</div>
                            </div>
                            <div>{{ integration.description }}</div>
                        </div>
                        <router-link :to="{ name: 'Integration', params: { handle: integration.handle, name: integration.name }}" class="button primary margin-left-20 margin-right-20" :class="[{ outline: integration.status === 'active' }, checkIntegrationRequirements(integration) ]">
                            <span v-if="checkIntegrationRequirements(integration) === 'disabled'">Disabled</span>
                            <span v-else-if="integration.status === 'active'">Edit</span>
                            <span v-else>Connect</span>
                        </router-link>
                    </div>
                    <!-- If not available, prompt to upgrade plan -->
                    <div v-if="checkIntegrationRequirements(integration) === 'disabled'" class="warning info-box margin-15 flex flex-v-center">
                        <span class="icon icon-info-icon"></span>
                        <div>Please <a href="/plan">upgrade your plan</a> to use this integration.</div>
                    </div>
                </div>
            </div>
            <div class="text-sm">
                Or view and download your data in your <router-link :to="{ name: 'Database' }">Attribute Database</router-link>.
            </div>
        </div>
    </div>

</template>

<style lang="scss" scoped>
.logo {
    width:  80px;
    height:  80px;
}
.status {
    text-transform: capitalize;
}
.integration-wrapper {
    &.disabled {

    }
}
.info-box {
    padding: 15px 10px;
    .icon {
        margin: 0 10px !important;
    }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { db } from "@/firebase";
import NProgress from "nprogress";

export default {
    name: "Integrations",
    data: function() {
        return {
            isLoaded: false,
            userData: {},
            onboardingSteps: [],
            integrations: require ('../assets/data-defaultIntegrations.json') 
        }
    },
    computed: {
        ...mapGetters({
          user: "user"
        }),
    },
    created: async function() {
        NProgress.start();
        this.userData = this.user.data;
        console.log("this.userData",this.userData);
        if (this.userData.integrations) {
            await this.getIntegrationStatus();    
        } else {
            await this.saveUserData();
        }
        this.isLoaded = true;
        NProgress.done();
    },
    methods: {
        ...mapMutations({
            setUserData: "SET_USER_DATA"
        }),
        checkIntegrationRequirements: function(integration) {
            
            var userPlan = this.user.data.billing && 
                           this.user.data.billing.selectedPlan && 
                           this.user.data.billing.selectedPlan.active ? this.user.data.billing.selectedPlan : null;
            var planRequirement = integration.planRequirement;

            // console.log("[checkIntegrationRequirements] userPlan:",userPlan);
            // console.log("[checkIntegrationRequirements] planRequirement:",planRequirement);
            if (integration.handle === "shopify") {
                return ""
            } else if (userPlan) {
                if (planRequirement === "ultimate" && (userPlan.id === "basic" || userPlan.id === "professional")) {
                    return "disabled";
                } else if (planRequirement === "professional" && userPlan.id === "basic") {
                    return "disabled";
                } else {
                    return "";
                }
            } else {
                // no plan active, disabled access to integrations
                return "disabled";
            }
            
        },
        checkIntegrationStatus: function(integration) {
            return integration.status === "active" && this.checkIntegrationRequirements(integration) !== "disabled" ? "active" : "inactive";
        },
        updateOnboardingStep: function(stepId, value) {

            this.onboardingSteps = this.user.data.onboardingSteps;
            var stepIndex = this.onboardingSteps.findIndex(x => x.id === stepId);

            if (stepIndex > -1) {
                this.onboardingSteps[stepIndex].complete = value;

                this.setUserData({
                    key: "onboardingSteps",
                    value: this.onboardingSteps
                });
            }
            

        },
        saveUserData: async function() {
            console.log("[saveUserData] integrations:",this.integrations);
            var vm = this;
            var user = db.collection("users").doc(vm.user.data.id);
            var data = { onboardingSteps: this.onboardingSteps };

            return user.update(data)
            .then(function() {
              // vm.shopifyStoreName = vm.activeUser.auth.shopify.id; 
              console.log("Successfully updated user:",data);
              vm.isSaved = true;
            })
            .catch(function(error) {
              console.error("Error updating user:",error);
            })
        },
        getIntegrationStatus: function() {
            // Get Integration status
            this.integrations = this.integrations.map(x => {
                var integrationIndex = this.userData.integrations.findIndex(y => x.name === y.name);
                if (integrationIndex > -1) {
                    x.status = this.userData.integrations[integrationIndex].status;
                }
                return x;
            })

            for (var i in this.integrations) {
                var integration = this.integrations[i];
                if (integration.status === "active" && integration.name !== "Shopify") {
                    this.updateOnboardingStep("integrations",true);
                    break;
                } else {
                    this.updateOnboardingStep("integrations",false);
                }
            }

            this.saveUserData();
        }
    }
};
</script>