<template>
    <div class="content-wrapper">
        <div class="title">
            Plans
        </div>
        <div class="header">
            <div class="flex flex-v-center margin-bottom-20">
                <div class="icon icon-arrow-left-icon text-lg margin-right-10 clickable" @click="$router.push({ name: 'Account' })"></div>
                <div class="primary-heading">Plans</div>
            </div>
            <div class="text-sm">Choose a plan to activate Attribute on your checkout.</div>
        </div>
        <div v-if="!isLoading" class="body">

            <!-- Check if Shopify is Active -->
            <div v-if="shopifyIntegration.status === 'active'">
                <!-- Plans -->
                <div class="plans feature-table card-wrapper flex">
                    <div class="width-300 min-width-300 flex flex-col-nowrap flex-align-end">
                        <div class="spacer flex flex-align-end full-width">
                            <h4>Features</h4>
                        </div>
                        <div class="heading-column">
                            <div class="color-headings table-cell">Survey Entries per month</div>
                            <div v-for="(feature, index) in plans[0].features" class="color-headings table-cell" :key="feature.name" :class="{ 'background-white': !isOdd(index)}">{{ feature.name }}</div>
                        </div>
                    </div>
                    <div class="plan width-200 flex flex-col-nowrap" v-for="plan in plans" v-bind:key="plan.id" :class="{ 'recommended': plan.recommended && (selectedPlan !== null && selectedPlan.id !== 'ultimate')  }">
                        <h3>{{ plan.name }}</h3>
                        <div>{{ plan.description }}</div>
                        <div class="spacer"></div>
                        <div class="margin-top-20 flex flex-align-end">
                            <h2 class="margin-0">${{ plan.price.toFixed(2) }}</h2>
                            <div style="margin-bottom: 3px;">/{{ plan.frequency }}</div>
                        </div>
                        <div class="actions margin-top-20 margin-bottom-20">
                            <button v-if="isSelectedPlan(plan)" class="inactive button primary full-width">Selected</button>
                            <input v-else type="button" class="button primary full-width" value="Select" v-on:click="selectPlan(plan)">
                        </div>
                        <div class="features data-column">
                            <div class="text-sm text-align-center color-headings table-cell">{{ numberWithCommas(plan.maxEntries) }}</div>
                            <div v-for="(feature, index) in plan.features" class="text-lg text-align-center color-headings table-cell" :class="[ 'icon', { 'icon-check-icon': feature.available }, { 'icon-x-icon': feature.available == false }, { 'background-white': !isOdd(index)} ]" :key="`${plan.name} - ${feature.name}`"></div>
                        </div>
                    </div>
                </div>

                <!-- Cancel Plan -->
                <div class="actions">
                    <div v-if="selectedPlan" class="text-link color-primary margin-top-40 danger" v-on:click="showCancelPlanModal()">Cancel your subscription</div>
                </div>
            </div>
            <div v-else>
                <div class="info-box warning clickable" >
                    <span class="icon icon-info-icon"></span>
                    <div>
                        <div class="text-md text-weight-med heading">
                            Shopify Integration Inactive
                        </div>
                        <div>
                            Please <router-link :to="'/install/shopify'">connect your Shopify store</router-link>  to choose a plan.
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <Modal v-if="isModalVisible && modal === 'cancelPlan'" @close="closeModal">

            <template v-slot:header>
                <div class="heading text-md text-weight-bold">Cancel Plan</div>
            </template>

            <template v-slot:body>
                <div class="text-sm width-300">
                    <p>Are you sure you want to cancel your plan?</p>
                    <p>This will deactivate Attribute on your checkout immediately, and you'll no longer receive any entries into your database.</p>
                </div>
            </template>

            <template v-slot:footer>
                <div class="actions">
                    <button type="button" class="button primary full-width" v-on:click="cancelPlan()">
                        <span v-if="!loadingPlan">Confirm Cancellation</span>
                        <span v-else>Loading...</span>
                    </button>
                </div>
            </template>
        </Modal>

        <!-- MODAL: Select Plan -->

        <Modal v-else-if="isModalVisible && selectedPlan" @close="closeModal">

            <template v-slot:header>
                <div class="flex flex-justify-space-between full-width">
                    <div class="heading text-md text-weight-bold margin-right-40">{{ selectedPlan.name }} Plan</div>
                    <div class="flex flex-align-end">
                        <div class="color-headings text-weight-med text-md">${{ selectedPlan.price.toFixed(2) }}</div>
                        <div class="text-sm" style="margin-bottom: 3px;">/{{ selectedPlan.frequency }}</div>
                    </div>
                </div>
            </template>

            <template v-slot:body>
                <div class="order-line flex">
                    <div class="text-md">{{ numberWithCommas(selectedPlan.maxEntries) }} Survey Entries per {{ selectedPlan.frequency }}</div>
                </div>
            </template>

            <template v-slot:footer>
                <div class="actions">
                    <button type="button" class="button primary full-width" v-on:click="confirmPlan()">
                        <span v-if="!loadingPlan">Confirm Plan in Shopify</span>
                        <span v-else>Loading...</span>
                    </button>
                </div>
            </template>
        </Modal>
    </div>
</template>

<style scoped lang="scss">


</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { db, functions } from "@/firebase";
import Modal from "@/components/Modal.vue";

const shopifyBilling = functions.httpsCallable("shopifyBilling");

export default {
    name: "Plan",
    components: {
        Modal
    },
    data: function() {
        return {
            modal: null,
            isModalVisible: false,
            isLoading: true,
            loadingPlan: false,
            selectedPlan: undefined,
            shopifyIntegration: undefined,
            planUsage: {},
            defaultPlans: [],
            plans: require("../assets/data-plans.json")
        }
    },
    computed: {
        ...mapGetters({
          // map `this.user` to `this.$store.getters.user`
          user: "user"
        })
    },
    created: async function() {
        
        this.defaultPlans = JSON.parse(JSON.stringify(this.plans));
        this.userData = (await db.collection("users").doc(this.user.data.id).get()).data();
        this.selectedPlan = this.userData.billing ? this.userData.billing?.selectedPlan : null;

        console.log("[created] selectedPlan:",this.selectedPlan);
        console.log("this.environment",this.environment)

        if (this.selectedPlan) {
            // this.getPlanUsage();
        }
        if (window.location.href.indexOf("charge_id") > -1) {
            this.appSubscription = this.getSavedData("shopify_app_subscription");
            console.log("[created] this.appSubscription:",this.appSubscription);
            await this.saveNewPlan();
        }

        if (process.env.NODE_ENV === "development") {
            // this.pushTestPlan();
        }

        this.shopifyIntegration = this.user.data.integrations.filter(x => x.handle === "shopify")[0];

        this.isLoading = false;
        
    },
    methods: {
        ...mapMutations({
            setUserData: "SET_USER_DATA"
        }),
        isOdd: function(num) { 
            return num % 2;
        },
        pushTestPlan: function() {
            this.plans.push({
                "id": "test",
                "name": "Test",
                "description": "For testing only",
                "price": 0,
                "maxEntries": 5000,
                "frequency": "month",
                "active": false,
                "features": [
                    { "name": "Klaviyo integration", "available": true },
                    { "name": "Custom API Access", "available" : true }
                ]
            });
        },
        updateOnboardingStep: function(stepId, value) {

            var onboardingSteps = this.user.data.onboardingSteps;
            var stepIndex = onboardingSteps.findIndex(x => x.id === stepId);
            onboardingSteps[stepIndex].complete = value;

            this.setUserData({
                key: "onboardingSteps",
                value: onboardingSteps
            });

        },
        setModalContent: function(header, body, footer) {
            this.modalContent = {
                header: header,
                body: body,
                footer: footer
            };
        },
        showModal: function() {
            this.isModalVisible = true;
        },
        closeModal: function() {
            this.isModalVisible = false;
            this.modal = null;
            this.isLoading = false;
            this.selectedPlan = this.userData.billing?.selectedPlan;
        },
        showCancelPlanModal: function() {
            this.modal = "cancelPlan";
            this.showModal();
        },
        saveUserData: function() {

            var vm = this;
            var user = db.collection("users").doc(this.user.data.id);
            var appSubscription = this.appSubscription !== undefined ? this.appSubscription : this.userData.billing.appSubscription;

            // Update Onboarding Step now that we've chosen a plan
            vm.updateOnboardingStep('plan',true);
            
            // console.log("selectedPlan:",this.selectedPlan);
            // console.log("billing:",this.userData.billing);
            // console.log("this.appSubscription:",appSubscription);

            this.setUserData({
                key: "billing",
                value: {
                    selectedPlan: this.selectedPlan,
                    appSubscription: appSubscription
                }
            });

            return user.update({ 
                onboardingSteps: vm.user.data.onboardingSteps,
                billing: {
                    selectedPlan: vm.selectedPlan,
                    appSubscription: appSubscription
                }
            })
            .then(function() {
              // vm.shopifyStoreName = vm.activeUser.auth.shopify.id; 
              // console.log("Successfully updated user:",vm.userData);
              vm.isSaved = true;
            })
            .catch(function(error) {
              console.error("Error updating user:",error);
            })
        },
        isSelectedPlan: function(plan) {
            if (this.selectedPlan) {
                return this.selectedPlan !== null && this.selectedPlan.id === plan.id && this.selectedPlan.active; 
            } else {
                return false;
            }
            // return false;
        },
        selectPlan: function(plan) {
            // console.log("select plan:",plan)
            this.selectedPlan = plan;
            this.showModal();
        },
        confirmPlan: async function() {

            this.loadingPlan = true;

            var shopifySubscription = await shopifyBilling({
                method: "appSubscriptionCreate",
                userId: this.user.data.id,
                plan: this.selectedPlan,
                returnUrl: `${window.location.href}`,
                test: this.environment === "development"
            })

            console.log("[confirmPlan] userData:",this.user.data);
            console.log("shopifySubscription:",shopifySubscription);
            
            if (shopifySubscription.data) {
                var appSubscription = shopifySubscription.data.appSubscriptionCreate.appSubscription;
                var confirmationUrl = shopifySubscription.data.appSubscriptionCreate.confirmationUrl;

                appSubscription.chargeId = appSubscription.id.match(/\d*$/gm)[0];

                this.setSavedData("shopify_app_subscription",appSubscription);

                console.log("appSubscription:",appSubscription);
                console.log("confirmationUrl:",confirmationUrl);

                window.location.assign(confirmationUrl);
            } else {
                console.log("shopifySubscription:",shopifySubscription);
            }
            

            
        },
        saveNewPlan: async function() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            // If URL has a charge id, reset the plan
            if (urlParams.has('charge_id')) {
                var chargeId = urlParams.get('charge_id');
                var appSubscription = { id: `gid://shopify/AppSubscription/${chargeId}` };
                var vm = this;

                console.log("[PLAN] App Subscription:",appSubscription)

                // If we have a charge id, then get the plan
                var plan = (await this.getPlanUsage(appSubscription)).data.node;
                console.log("[PLAN] Usage:",plan);
                this.appSubscription = {
                    id: appSubscription.id,
                    name: plan.name,
                    chargeId: chargeId,
                    lineItems: plan.lineItems,
                }

                console.log("[PLAN] App Subscription",this.appSubscription);

                this.selectedPlan = this.plans.filter(x => x.name === vm.appSubscription.name )[0];
                this.selectedPlan.active = true;
                this.$router.replace("/plan"); // Remove the charge id from the URL
                console.log("[PLAN] selectedPlan:",this.selectedPlan);

                this.saveUserData();    
            } else if (this.user.data.billing && this.user.data.billing.selectedPlan) {
                this.selectedPlan = this.user.data.billing.selectedPlan;
            } else {
                this.selectedPlan = null;
            }
            
        },
        getPlanUsage: async function(appSubscription) {

            var activeSubscription = await shopifyBilling({
                method: "getPlanUsage",
                userId: this.user.data.id,
                plan: this.selectedPlan,
                appSubscription: appSubscription || this.user.data.billing.appSubscription
            })

            console.log("[PLAN] activeSubscription:",activeSubscription)

            return activeSubscription ? activeSubscription : null;
        },
        cancelPlan: async function() {

            this.loadingPlan = true;

            // Cancel Subscription
            var cancelSubscription = await shopifyBilling({
                method: "cancelAppSubscription",
                userId: this.user.data.id,
                appSubscriptionId: this.user.data.billing.appSubscription.id
            })
            if (this.environment === "development") { console.log("[PLAN] Cancelled:",cancelSubscription)}

            // Cancel Plan
            this.appSubscription = null;
            this.selectedPlan = null;
            this.plans = this.defaultPlans;

            await this.saveUserData();
            this.loadingPlan = false;
            this.closeModal();

        }
    }
};
</script>