<template>
    <nav>
        <!-- Primary Nav -->
        <router-link :to="{ name: item.name }" v-for="item in navItems" v-bind:key="item.name" class="nav-item text-xs text-weight-reg" v-bind:class="{ hasChildren: item.children }"> 
            <div v-if="$route.matched.some(({ name }) => name === item.name)" class="active-indicator"></div>
            <span class="icon" :class="[ item.icon ]"></span>
            <span class="label">{{ item.label }}</span>
            <div class="children" v-if="item.children">
                <router-link :to="{ path: child1.path }" class="nav-item-child" :class="{ 'has-children': child1.children }" v-for="child1 in item.children" v-bind:key="`child-${child1.path}`">
                    <span>{{ child1.label }}</span>
                    <div class="children" v-if="child1.children">
                        <router-link :to="{ path: child2.path }" class="nav-item-child" v-for="child2 in child1.children" v-bind:key="`child-${child2.path}`">{{ child2.label }}</router-link>
                    </div>
                </router-link>
                
            </div>
        </router-link>

        <!-- Plan Button -->
        <div v-if="this.user.data && !this.userHasPlan()" class="padding-20" style="margin-top: auto;">
            <button class="button primary padding-10 full-width" v-on:click="$router.push({ name: 'Plan' })">Choose a plan</button>
        </div>

        <!-- Plan Usage -->
        <div v-if="this.userHasPlan()" class="padding-20" style="margin-top: auto;">
            <div class="text-weight-bold">Plan usage</div>
            <div class="progress-bar">
                <span class="progress" v-bind:class="{ full: planUsage() >= 100 }" v-bind:style="`width: ${progress()}`"></span>
            </div>
            <div class="progress-caption">
                <span class="usage heading-text">{{ user.data.entryCount }}</span>
                <span class="separator heading-text">/</span>
                <span class="maxUsage heading-text">{{ user.data.billing.selectedPlan.maxEntries }}</span>
                <span class="margin-left-5">entries used this month</span>
            </div>
            <button v-if="selectedPlan.id !== 'ultimate'" class="button primary outline padding-10 full-width margin-top-10" v-on:click="$router.push({ name: 'Plan' })">
                <span v-if="this.userHasPlan">Upgrade plan</span>
                <span v-else>Choose a plan</span>
            </button>

        </div>

    </nav>
</template>

<script>
// import firebase from "@firebase/app";
import { mapGetters } from "vuex";

export default {
    name: "Navigation",
    data: function() {
        return {
            currentUser: {},
            selectedPlan: {},
            navItems: [
                { name: "Welcome", label: "Dashboard", icon: "icon-layout-icon" },
                { name: "Survey", label: "Survey", icon: "icon-edit-3-icon" },
                { name: "Emails", label: "Emails", icon: "icon-mail-icon", 
                    children: [
                        { path: "request", label: "Request" },
                        { path: "queue", label: "Request Queue" },
                        // { path: "followup", label: "Follow-up" },
                        { path: "settings", label: "Settings" }
                    ]
                },
                { name: "Reports", label: "Reports", icon: "icon-bar-chart-2-icon",
                    children: [
                        { 
                            path: "overview", 
                            label: "Overview" 
                        },
                        { 
                            path: "/reports/notifications", 
                            label: "Notifications",
                            children: [
                                {
                                    path: "digest",
                                    label: "Digest"
                                }
                            ] 
                        }
                    ] 
                },
                // { name: "Notifications", label: "Notifications", icon: "icon-bell-icon",
                //     children: [
                //         { path: "digest", label: "Digest" }
                //     ]
                // },
                { name: "Integrations", label: "Integrations", icon: "icon-codesandbox-icon" },
                { name: "Installation", label: "Installation", icon: "icon-download-icon" },
                { name: "Database", label: "Database", icon: "icon-database-icon" }
                // { 
                //     name: "Emails", 
                //     label: "Email", 
                //     icon: require("@/assets/icon-email.svg"),
                //     children: [
                //         // {
                //         //     path: "/emails/queue",
                //         //     label: "Queue"
                //         // },
                //         {
                //             path: "/emails/configure",
                //             label: "Configure"
                //         }
                //     ] 
                // },
            ] 
        }
    },
    props: {
        signedIn: Boolean
    },
    computed: {
        ...mapGetters({
          // map `this.user` to `this.$store.getters.user`
          user: "user"
        }),
    },
    created: async function() {
    },
    updated: function() {
        
    },
    methods: {
        userHasPlan: function() {
            
            if (this.user.data) {
                if (this.user.data.billing !== null && this.user.data.billing.selectedPlan !== null) {
                    this.user.data.onboardingSteps.map(x => { x.id === "plan" ? x.complete : false });
                    this.selectedPlan = this.user.data.billing.selectedPlan;
                    // console.log("this.selectedPlan:",this.selectedPlan);
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }


            
        },
        planUsage: function() {
            return (this.user.data.entryCount/this.user.data.billing.selectedPlan.maxEntries)*100;
        },
        progress: function() {
            
            if (this.planUsage() >= 100) {
                return "100%"
            } else {
                return this.planUsage()+"%";
            }
        }
    }
};
</script>