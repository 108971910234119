<template>
    <table class="module bar">
        <thead>
            <tr>
                <th class="module-title color-headings">{{ module.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="metrics">
                    <table>
                        <tr class="metric" v-for="(metric, index) in metrics" :key="`metric-${index}`">
                            <td class="text-sm description">
                                {{ metric.description }}
                            </td>
                            <td class="value-bar">
                                <div class="bar" :style="{ width: getWidth(metric) + '%' }">
                                    <div class="value-text">
                                        <span v-if="metric.data.type == 'percentage'">{{ formatNumber(metric.data.value,
                                                '0.0%')
                                        }}</span>
                                        <span v-else-if="metric.data.type == 'currency'">{{
                                                formatNumber(metric.data.value, '$0.0a')
                                        }}</span>
                                        <span v-else>{{ formatNumber(metric.data.value, '0,0[.]00') }}</span>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "bar",
    props: {
        module: Object,
        userData: Object,
        userMetrics: Object,
        limit: Number
    },
    data: function () {
        return {
            metrics: []
        }
    },
    computed: {

    },
    created: async function () {
        await this.getMetricData();
    },
    methods: {
        getWidth: function (metric) {
            var values = this.metrics.map(x => x.data.value);
            var maxValue = this.getMaxOfArray(values);
            return (metric.data.value / maxValue) * 100;
        },
        getMetricData: function () {

            // console.log("this.module.x:",this.module.x)

            this.metrics = this.userMetrics.entryMetrics[this.module.y].map(item => {
                item["description"] = item[this.module.y.substring(0, this.module.y.length - 1)];
                item.data["type"] = this.module.x.type;
                item.data["value"] = item.data[this.module.x.id];
                return item;
            });

            // console.log("this.module:",this.module);

            // Limit the list and sort
            this.metrics = this.sortList(this.metrics.slice(0, this.limit));

        },
        sortList: function (list) {
            var metric = this.module.x.id;
            var sortedList = list.sort((a, b) => b.data[metric] - a.data[metric])
            return sortedList;
        }
    },
    watch: {
        'module.x': {
            handler: function () {
                this.getMetricData();
            }
        }
    }
}
</script>