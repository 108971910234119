<template>
	<div :class="`${imageClass}`">
		<img v-if="color === 'white'" src="@/assets/animation-loader-white.svg" :class="`full-width ${imageClass}`"/>
		<img v-else src="@/assets/animation-loader.svg" :class="`full-width ${imageClass}`"/>
	</div>
</template>

<script>
export default {
	name: "Loading",
	props: {
		routeName: String,
		imageClass: String,
		color: String
	},
	computed: {
	},
	created: function() {
		// location.reload();
		console.log("routeName:",this.routeName);
		this.$router.push({ "name": this.routeName });
	}
};
</script>