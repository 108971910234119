<template>
    <table class="module pie" cellspacing="10px">
        <thead>
            <tr>
                <th class="module-title color-headings" colspan="2">{{ module.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr><td>
                <div class="metrics">
                    <div class="metric" 
                        v-for="(metric, index) in metrics" 
                        :key="`metric-${index}`"
                        :style="{ 
                            '--p': getWidth(metric)+'%', 
                            '--f': getFromPoint(index) 
                        }" 
                        >
                        <span class="before"></span>
                        <span class="after"></span>
                    </div>
                </div>
                <div class="legend">
                    <table cellspacing="0">
                        <tr class="label" v-for="(metric, index) in metrics" :key="`label-${index}`">
                            <td class="description">{{ metric.description }}</td>
                            <td class="value">
                                <span v-if="metric.data.type == 'percentage'">{{ formatNumber(metric.data.value, '0.0%') }}</span>
                                <span v-else-if="metric.data.type == 'currency'">{{ formatNumber(metric.data.value, '$0.0a') }}</span>
                                <span v-else>{{ formatNumber(metric.data.value, '0,0[.]00')}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </td></tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "bar",
    props: {
        module: Object,
        userData: Object,
        userMetrics: Object,
        limit: Number
    },
    data: function() {
        return {
            metrics: []
        }
    }, 
    created: async function() {
        await this.getMetricData();
    },  
    methods: {
        getWidth: function (metric) {
            var metricTotal = 0;
            this.metrics.forEach((item) => { metricTotal += item.data.value });
            return (metric.data.value/metricTotal)*100;
        },
        getFromPoint: function (index) {

            var point = 0;
            for (var i = 0; i <= this.metrics.length; i++) {
                if (i < index) {
                    point += this.getWidth(this.metrics[i])*360/100;
                }
            }
            
            return `${point}deg`;
            
        },
        getMetricData: function() {

            this.metrics = this.userMetrics.entryMetrics[this.module.y].map(item => { 
                item["description"] = item[this.module.y.substring(0, this.module.y.length - 1)];
                item.data["type"] = this.module.x.type; 
                item.data["value"] = item.data[this.module.x.id];
                return item; 
            }).slice(0, this.limit);

        }
    },
    watch: {
        'module.x': {
            handler: function () {
                this.getMetricData();
            }
        }
    }
}
</script>