<template>
    <div class="module scorecard">
        <div class="metrics">
            <div class="metric" v-for="(metric, index) in metricData" :key="`metric-${index}`">
                <div class="text-lg score">
                    <span v-if="metric.type == 'percentage'">{{ formatNumber(metric.value, '0.0%') }}</span>
                    <span v-else-if="metric.type == 'currency'">{{ formatNumber(metric.value, '$0.0a') }}</span>
                    <span v-else>{{ formatNumber(metric.value, '0,0[.]00')}}</span>
                </div>
                <div class="text-xs description">{{ metric.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { db, functions } from "@/firebase";

const shopify = functions.httpsCallable("shopify");

export default {
    name: "Scorecard",
    props: {
        module: Object,
        userData: Object,
        userMetrics: Object,
        startDate: String,
        limit: Number
    },
    data: function() {
        return {
            metricData: []
        }
    },
    computed: {
        
    },
    created: async function() {
        await this.getMetricData();
    },
    methods: {
        getCustomerCount: async function(startDate) {
            return await shopify({
                method: "GET",
                userId: this.userData.id,
                path: `/customers/count.json?created_at_min=${startDate}`
            }).then((response) => {
                return response.data.count;
            }).catch((err) => {
                console.log("[getCustomerCount] shopify error:",err)
            })
        },  
        getMetricData: async function() {

            var metrics = [];
            var entryCount = this.userMetrics.entryCount;
            var totalRevenue = this.userMetrics.entryMetrics.totalRevenue.toFixed(2);
            var customerCount = await this.getCustomerCount(this.startDate);

            console.log("[getMetricData] customerCount:",customerCount)

            if (this.module.metrics) {
                this.module.metrics.forEach((metric) => {
                    
                    if (metric === "Entries") {
                        metrics.push({
                            "type": "number",
                            "value": entryCount,
                            "description": metric
                        })
                    }
                    if (metric === "Response Rate") {
                        metrics.push({
                            "type": "percentage",
                            "value": entryCount/customerCount,
                            "description": metric
                        })
                    }
                    if (metric === "Revenue") {
                        metrics.push({
                            "type": "currency",
                            "value": totalRevenue,
                            "description": metric
                        })
                    }
                    if (metric === "Average Order Value") {
                        metrics.push({
                            "type": "currency",
                            "value": totalRevenue/entryCount,
                            "description": metric
                        })
                    }
                })
                

                // console.log("[getMetricData] metrics:",metrics);

                this.metricData = metrics;
            }

        }
    },
    watch: {
        'module.metrics': async function(value) {
            // console.log("changed module metrics",value)
            await this.getMetricData();
        }
    }
}
</script>